import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import call from "../../../service";
import { FileInput } from "../../../utils/FileInput";
import {
  convertImageToPdf,
  decryptData,
  encryptData,
  exportersNavConfig,
  isEmpty,
} from "../../../utils/myFunctions";
import { InputWithSelect, NewInput } from "../../../utils/newInput";
import toastDisplay from "../../../utils/toastNotification";
import FinanceInvoiceModal from "../../InvoiceDiscounting/contract/components/financeinvoiceModal";
import { useHistory } from "react-router";
import { NewTable } from "../../../utils/newTable";
import MultipleSelect from "../../../utils/MultipleSelect";
import Switch from "react-switch";


const customizeUserAcess = {
  "Buyer Management": "buyerManagement",
  "LC Apply For Limit": "applyForLimit,LcDiscounting",
  "LC Quotes": "LcQuote,LcSeequotes",
  "LC Contract": "LcContract,LcSignContract",
  "LC Finance": "LcApplyforFinance,LcFinancebtn,LcApprovedFinance",
  "LC Ammendment": "LcAmendment,amendmentDetails",
  "Invoice Apply For Limit": "applyLimit",
  "Invoice Quotes": "Quotes,seequotes,DocsRequested",
  "Invoice Contract": "invoicecontract,signContract",
  "Invoice Finance": "apply-finance,apply-invoice",
  "Invoice Approved Finance": "approvedFinance,viewDetails",
};



const UserAcessFinancer = {
  "Supplier List": "supplierList,supplierDetails",
  "LC Quotes": "finLCQuotes,finLCQuotesDetails",
  "LC Request Letter": "lcRequestLetter,viewRequestLetter",
  "LC Finance": "lcFinApplication,viewLCFinApplication",
  "Invoice Quotes": "finInvoiceQuotes,finQuotesDetails,docsRequested",
  "Invoice Send Termsheet": "termSheet,sendTermSheet",
  "Invoice Sign Termsheet": "signTermSheet,viewSignTermSheet",
  "Invoice Finance": "financeApplication,viewFinanceApplication",
  "Invoice Disbursement": "disbursementV2",
  "Chat Room": "ChatRoomV2",
  Reports: "Reportv2",
  Notifications: "AllNotifications",
  Profile: "viewprofile",
};

const SubUserModal = ({

  userTokenDetails,
  isEditable,
  userId,

}) => {
  const [data, setData] = useState({
    contact_phonecode: 91,
    contact_personTitle: "Mr",
  });

  let history = useHistory();
  const formdata = new FormData();
  const [errors, setErrors] = useState({});
  const [accessObj, setAccessObj] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [securePw, toggleSecurePw] = useState(true);
  const [selectedCards, setSelectedCards] = useState({});
  const [addNew, setAddNew] = useState(false);
  const [countrys, setCountrys] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [UserAcess, setUserAccess] = useState({
    "Network Management":
      "buyerManagement,nwManagementExporter,nwManagementLogisticPartner",
    "International Financial Services Manager":
      "applyLimitV2,Quotes,invoicecontract,apply-finance,invoice-agreement,approvedFinance",
    "Domestic Financial Services Manager":
      "applylimitCGTMSE,cgtmseQuote,applylimitSCF,scfQuote,approvedLimitSCF,applyfinDID,invoiceFinance",
    "Bank Services Manager": "wcApplyLimit,wcQuote,bgGetConfirmation,bgQuote,bgConfirmed",
    "Accounting Analyst": "Reportv2,tallyReportsDashboard",
    "Contract Administrator": "contractManagementList,contractManagementAdd",
    "Operations Manager":
      "ShipmentBookingMarketPlace,ShipmentBookingApply,ShipmentBookingQuotes,ShipmentBookingTracking",
    "Insurance Manager": "CreditInsurance,insuranceQuote",
    "Trade Finance Specialist":
      "edocsEinvoice,edocsEwaybill,edocsPOGenerator,edocsLC,edocsBL",
    "Chartered Accountant":
      "tallyMastersAccountingGroup,tallyMastersAccountingLedger,tallyMastersAccountingVoucherType,tallyMastersStockGroup,tallyMastersStockCategory,tallyMastersStockGodown,manageCommodities,tallyTxnsVoucher,tallyReportsDashboard",
    // "Chat Room": "ChatRoomV2",
    Support: "ChatRoomV2,tradeGpt",

    Profile: "viewprofile",
    Wallet: "wallet,payment-gateway",

    "LC Manager": "LcDiscountingV2,LcQuote,LcContract,LcApprovedFinance,LcApplyforFinance"
  });


  const [UserAcessFin, setUserAccessFin] = useState({
    "Supply Chain Specialist": "supplierList,supplierDetails",
    "International Financial Services Manager": "finLCQuotes,lcFinApplication,finInvoiceQuotes,disbursementV2,financeApplication,finQuotesDetails,viewFinanceApplication",
    "Domestic Financial Services Manager": "cgtmseFinancierQuote,bgFinancierQuote,cgtmseFinancierSendQuote,bgFinancierSendQuote,scfFinancierQuote,scfFinancierSendQuote,approvedLimitSCF,scfSignTermsheet",
    "Insurance Manager": "insuranceFinancierQuote",
    "Trade Finance Specialist": "edocsLC",
    "Support": "ChatRoomV2",

    "Accounting Analyst": "Reportv2",
    // Notifications: "AllNotifications",
    Profile: "viewprofile",
  })

  const [UserAcessAdmin, setUserAccessAdmin] = useState({

    "Task Manager":
      "taskManager,enquiry,callList,corporate,financier",
    "International Financial Services Manager":
      "lcLimit,invoiceLimit,invoiceFinance,invoiceApprovedFinance",
    "Domestic Financial Services Manager":
      " otherFinAdminwcQuote,otherFinAdmincgtmseQuote,otherFinAdminbgQuote,otherFinAdminscfQuote",

    "User Management": "usersonboard",
    "CRM Manager": "leads,crmdata",
    "Finance Specialist": "generateInvoiceFinancier,generateInvoiceCP",
    "Admin Payments": "adminPayments",
    "Accounting Analyst": "AdminReports,tradeDiscovery",
    "MasterData Analyst": "masterdata",
    "User credit Administrator": "buyercreditcheck,suppliercreditcheck",

    "Contract Administrator": "contractManagementList,contractManagementAdd",
    "Operations Manager":
      "ShipmentBookingQuotes",

    Support: "ChatRoomV2,tradeGpt",

    Profile: "viewprofile",


  }
  )


  const [routeObj, setrouteObj] = useState([
    {
      id: "1",
      name: "Network Management",
    },
    {
      id: "2",
      name: "International Financial Services Manager",
    },
    {
      id: "3",
      name: "Domestic Financial Services Manager",
    },
    {
      id: "4",
      name: "Accounting Analyst",
    },
    {
      id: "5",
      name: "Contract Administrator",
    },
    {
      id: "6",
      name: "Operations Manager",
    },
    {
      id: "7",
      name: "Insurance Manager",
    },
    {
      id: "8",
      name: "Trade Finance Specialist",
    },
    {
      id: "9",
      name: "Chartered Accountant",
    },
    {
      id: "10",
      name: "Support",
    },
    {
      id: "11",
      name: "Profile",
    },
    {
      id: "12",
      name: "Wallet",
    },
    {
      id: "13",
      name: "LC Manager",
    },
    {
      id: "14",
      name: "Bank Services Manager"
    }
  ]);



  const [routeObjFin, setrouteObjFin] = useState([
    {
      id: "1",
      name: "Supply Chain Specialist",
    },
    {
      id: "2",
      name: "International Financial Services Manager",
    },
    {
      id: "3",
      name: "Domestic Financial Services Manager",
    },

    {
      id: "4",
      name: "Insurance Manager",
    },
    {
      id: "5",
      name: "Trade Finance Specialist",
    },


    {
      id: "6",
      name: "Accounting Analyst",
    },


    {
      id: "7",
      name: "Profile",
    },

    {
      id: "8",
      name: "Support",
    },

  ]);


  const [routeObjAdmin, setrouteObjAdmin] = useState([
    {
      id: "1",
      name: "User Management",
    },
    {
      id: "2",
      name: "International Financial Services Manager",
    },
    {
      id: "3",
      name: "Domestic Financial Services Manager",
    },
    {
      id: "4",
      name: "Accounting Analyst",
    },
    {
      id: "5",
      name: "Contract Administrator",
    },
    {
      id: "6",
      name: "Operations Manager",
    },
    {
      id: "7",
      name: "Admin Payments",
    },

    {
      id: "8",
      name: "Finance Specialist",
    },

    {
      id: "9",
      name: "CRM Manager",
    },
    {
      id: "10",
      name: "Support",
    },
    {
      id: "11",
      name: "Profile",
    },
    {
      id: "12",
      name: "MasterData Analyst",
    },
    {
      id: "13",
      name: "User credit Administrator",
    },
    {
      id: "14",
      name: "Task Manager",
    },

  ]);



  const [viewAccess, setviewaccess] = useState([
    {
      item: "buyerManagement",
      name: "Buyer Management",
      action: ["create", "view"],
      intro: "Create and view buyers",
      storeid: "2",
      selected: []
    },
    {
      item: "nwManagementExporter",
      name: "Exporter Management",
      action: ["create", "view"],
      intro: "Create and view exporters",
      storeid: "2", selected: []
    },
    {
      item: "nwManagementLogisticPartner",
      name: "Channel Partner Management",
      storeid: "2",
      action: ["create", "view"],
      intro: "Create and view Channel Partners", selected: []
    },


    {
      item: "LcDiscountingV2",
      name: "LC Discounting",
      action: ["create", "view"],
      intro: "Apply for Letter of credit discount",
      storeid: "3",
      selected: []
    },
    {
      item: "LcQuote",
      name: "LC Quote",
      action: ["create", "view"],
      intro: "Create Letter of Credit Quote",
      storeid: "3",
      selected: []
    },
    {
      item: "applyLimitV2",
      name: "Apply Limit for Export Factoring",
      action: ["create", "view"],
      intro: "Create Letter of Credit Quote",
      storeid: "4",
      selected: []
    },
    {
      item: "Quotes",
      name: "Apply Quote for Export Factoring",
      action: ["create", "view"],
      intro: "Create Quote for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "invoicecontract",
      name: "Apply Contract for Export Factoring",
      action: ["create", "view"],
      intro: "Create Contract for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "apply-finance",
      name: "Apply Finance for Export Factoring",
      action: ["view"],
      intro: "Apply Contract for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "invoice-agreement",
      name: "Apply invoice Agreement for Export Factoring",
      action: ["view"],
      intro: "Apply invoice Agreement for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "approvedFinance",
      name: "Apply Finance approval for Export Factoring",
      action: ["view"],
      intro: "Approved Finance for Export Factoring",
      storeid: "4",
      selected: []
    },

    {
      item: "LcContract",
      name: "LC Contract",
      action: ["create", "view"],
      intro: "Letter of Credit Contract",
      storeid: "3",
      selected: []
    },

    {
      item: "LcApplyforFinance",
      name: "LC Apply For Finance",
      action: ["create"],
      intro: "Apply for Finance",
      storeid: "3",
      selected: []
    },
    {
      item: "LcApprovedFinance",
      name: "LC Approve For Finance",
      action: ["create"],
      intro: "Approve for Finance",
      storeid: "3",
      selected: []
    },

    // {
    //   item: "gstBasedInvoiceFinanceDiscovery",
    //   name: "GST Finance Discovery",
    //   action: ["view"],
    //   intro: "View GST Finance Offers",
    //   storeid: "5", selected: []

    // },


    {
      item: "wcApplyLimit",
      name: "Apply Limit For working Capital",
      action: ["create"],
      intro: "Apply limit for working capital",
      storeid: "5", selected: []

    },
    {
      item: "wcQuote",
      name: "Apply Quote for Working Capital",
      action: ["create"],
      intro: "Create Quote",
      storeid: "5",
      selected: []
    },
    {
      item: "applylimitCGTMSE",
      name: "Apply Limit for CGTMSE",
      action: ["create"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "cgtmseQuote",
      name: "View Applied Limits for CGTMSE",
      action: ["create"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "bgQuote",
      name: "Bank Gurantee Quote",
      action: ["view"],
      intro: "Get Bank Gurantee Quote",
      storeid: "5",
      selected: []
    },
    {
      item: "bgConfirmed",
      name: "View Bank Gurantee confirmed",
      action: ["view"],
      intro: "View Bank Gurantee Confirmed",
      storeid: "5",
      selected: []
    },
    {
      item: "bgGetConfirmation",
      name: "Bank Gurantee Confirmation",
      action: ["create"],
      intro: "Get Bank Gurantee Confirmation",
      storeid: "5",
      selected: []
    },
    {
      item: "applylimitSCF",
      name: "Apply limit for SCF",
      action: ["view"],
      intro: "Apply Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "scfQuote",
      name: "Apply Quote for SCF",
      action: ["view"],
      intro: "Apply Quote for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "applyfinDID",
      name: "Apply Finance for Domestic Invoice Discounting",
      action: ["view"],
      intro: "Apply Finance for Domestic Invoice Discounting",
      storeid: "5", selected: []
    },
    {
      item: "approvedLimitSCF",
      name: "Approve Limit for SCF",
      action: ["view"],
      intro: "Approve Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },
    {
      item: "invoiceFinance",
      name: "Invoice Finance",
      action: ["view"],
      intro: "Get invoice finance",
      storeid: "5", selected: []
    },
    {
      item: "Reportv2",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "10",
      selected: []
    },
    {
      item: "tallyReportsDashboard",
      name: "Tally Report Analysis",
      action: ["view"],
      intro: "View Report analysis for Tally",
      storeid: "10",
      selected: []
    },
    {
      item: "contractManagementList",
      name: "Contract Management",
      action: ["view"],
      intro: "View contract list",
      storeid: "11", selected: []
    },
    {
      item: "contractManagementAdd",
      name: "Create Contract",
      action: ["create"],
      intro: "Create contract",
      storeid: "11", selected: []
    },

    {
      item: "ShipmentBookingMarketPlace",
      name: "Shipment Booking",
      action: ["view"],
      intro: "View Shipment Bookings",
      storeid: "15", selected: []
    },
    {
      item: "ShipmentBookingTracking",
      name: "Shipment Tracking",
      action: ["view"],
      intro: "View Shipment Tracking",
      storeid: "15", selected: []
    },
    {
      item: "ShipmentBookingApply",
      name: "Apply for Shipment Booking",
      action: ["create"],
      intro: "Apply for Shipment Booking",
      storeid: "15", selected: []
    },
    {
      item: "ShipmentBookingQuotes",
      name: "Booking Quotes",
      action: ["view"],
      intro: "View shipment booking quotes",
      storeid: "15", selected: []
    },
    {
      item: "CreditInsurance",
      name: "Insurance Credit",
      action: ["view"],
      intro: "View Insurance Credit",
      storeid: "13",
      selected: []

    },
    {
      item: "insuranceQuote",
      name: "Insurance Quotes",
      action: ["view"],
      intro: "View insurance quotes",
      storeid: "13",
      selected: []
    },
    {
      item: "edocsEinvoice",
      name: "Edocs Invoice",
      action: ["view", "create"],
      intro: "View and create edocs invoice",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsEwaybill",
      name: "Edocs Eway Bill",
      action: ["view"],
      intro: "View Edocs Way bills",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsPOGenerator",
      name: "Edocs PO Generator",
      action: ["view", "create"],
      intro: "create and view edocs PO",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsLC",
      name: "Edocs Letter of Credit",
      action: ["view", "create"],
      intro: "create and view Edocs letter of credit",
      storeid: "14",
      selected: []
    },
    {
      item: "edocsBL",
      name: "Edocs BL",
      action: ["view", "create"],
      intro: "View and create edocs",
      storeid: "14",
      selected: []
    },
    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "8",
      selected: []
    },
    {
      item: "tradeGpt",
      name: "Trade Gpt",
      action: ["view", "create"],
      intro: "Use Trade GPT to query",
      storeid: "16",
      selected: []
    },


    {
      item: "wallet",
      name: "Wallet",
      action: ["view", "create"],
      intro: "View wallet ",
      storeid: "7",
      selected: []
    },
    {
      item: "payment-gateway",
      name: "Payments",
      action: ["view", "create"],
      intro: "View and create payment gateway",
      storeid: "5",
      selected: []
    },


    {
      item: "tallyMastersAccountingGroup",
      name: "Tally Master Accounting Group",
      action: ["view", "create"],
      intro: "Create and View tally accounting group",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyMastersAccountingLedger",
      name: "Tally Master Accounting Ledger",
      action: ["view", "create"],
      intro: "Create and View tally Ledger ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyMastersAccountingVoucherType",
      name: "Tally Master Accounting Voucher",
      action: ["view", "create"],
      intro: "Create and View tally master accounting vouchers ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyMastersStockGroup",
      name: "Tally Master Accouning stock",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyMastersStockCategory",
      name: "Tally Master Accouning stock category",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks categories ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyMastersStockGodown,",
      name: "Tally Master Accouning stock Godown",
      action: ["view", "create"],
      intro: "Create and View tally master accounting stocks and godowns ",
      storeid: "16",
      selected: []

    },
    {
      item: "manageCommodities",
      name: "Tally Master commodities",
      action: ["view", "create"],
      intro: "Create and View tally master commodities ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyTxnsVoucher,",
      name: "Tally Master Voucher",
      action: ["view", "create"],
      intro: "Create and View tally master voucher ",
      storeid: "16",
      selected: []
    },
    {
      item: "tallyReportsDashboard",
      name: "Tally Master dashboard",
      action: ["view"],
      intro: "Create and View tally master dashboard ",
      storeid: "16",
      selected: []
    },
  ]);


  const [viewAccessFin, setviewaccessFin] = useState([


    {
      item: "supplierList",
      name: "Suppliers ",
      action: ["create", "view"],
      intro: "View Supplier List",
      storeid: "2",
      selected: []
    },
    {
      item: "supplierDetails",
      name: "Supplier Details",
      action: ["create", "view"],
      intro: "View Details of selected suppliers",
      storeid: "2",
      selected: []
    },
    {
      item: "finLCQuotes",
      name: "Limit Application ",
      action: ["create", "view"],
      intro: "Apply for Quotes",
      storeid: "3",
      selected: []
    },
    {
      item: "lcFinApplication",
      name: "Finance Application",
      action: ["create", "view"],
      intro: "Create Quote for Finance Application",
      storeid: "3",
      selected: []
    },
    {
      item: "finInvoiceQuotes",
      name: "Apply for Invoice Quotes",
      action: ["create", "view"],
      intro: "Create Invoice Contract",
      storeid: "4",
      selected: []
    },
    {
      item: "financeApplication",
      name: "Apply Finance for Export Factoring",
      action: ["create", "view"],
      intro: "Apply Finance for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "disbursementV2",
      name: "Disbursement for Export Factoring",
      action: ["create", "view"],
      intro: "Apply disbursement for Export Factoring",
      storeid: "4",
      selected: []
    },
    {
      item: "finQuotesDetails",
      name: "Apply Finance Quote for Export Factoring",
      action: ["create"],
      intro: "View detail Finance Quote for Export Factoring",
      storeid: "4",
      selected: []
    },

    {
      item: "viewFinanceApplication",
      name: "Finance Application",
      action: ["create"],
      intro: "View Fiannce Application",
      storeid: "4",
      selected: []
    },

    {
      item: "cgtmseFinancierQuote",
      name: "Apply Quote for CGTMSE",
      action: ["create", "view"],
      intro: "Apply Quote for Finance",
      storeid: "5",
      selected: []
    },
    {
      item: "bgFinancierQuote",
      name: "Apply Quote for Bank GUrantee",
      action: ["create", "view"],
      intro: "Apply Quote for Bank Gurantee",
      storeid: "5",
      selected: []
    },




    {
      item: "bgConfirmedFinancier",
      name: "Bank Guarantee Financier",
      action: ["view"],
      intro: "Bank Guarantee for Financier",
      storeid: "5", selected: []

    },
    {
      item: "scfFinancierQuote",
      name: "Apply Finance Quote for Supply Chain Finance",
      action: ["create"],
      intro: "Apply Finance Quote for SCF",
      storeid: "5",
      selected: []
    },


    {
      item: "approvedLimitSCF",
      name: "Approve Limit for SCF",
      action: ["view"],
      intro: "Approve Limit for Supply Chain Finance",
      storeid: "5", selected: []
    },

    {
      item: "Reportv2",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "12",
      selected: []
    },

    {
      item: "insuranceFinancierQuote",
      name: "Insurance Quote",
      action: ["view"],
      intro: "View Insurance Quote",
      storeid: "7",
      selected: []

    },



    {
      item: "edocsLC",
      name: "Edocs Letter of Credit",
      action: ["view", "create"],
      intro: "create and view Edocs letter of credit",
      storeid: "10",
      selected: []
    },

    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "11",
      selected: []
    },




  ]);



  const [viewAccessAdmin, setviewaccessAdmin] = useState([
    {
      item: "usersonboard",
      name: "User Management",
      action: ["create", "view"],
      intro: "Create and view buyers",
      storeid: "6",
      selected: []
    },



    {
      item: "taskManager",
      name: "Task Manager",
      action: ["view"],
      intro: "Manages users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "enquiry",
      name: "Task Manager Enquiry",
      action: ["view"],
      intro: "Keeps Track of user enquiries",
      storeid: "2",
      selected: []
    },
    {
      item: "callList",
      name: "Task Manager Call List",
      action: ["view"],
      intro: "Keeps track of call history of users onboard",
      storeid: "2",
      selected: []
    },

    {
      item: "corporate",
      name: "Task Manager Corporate",
      action: ["view"],
      intro: "Lists out the details of directors along with their company information",
      storeid: "2",
      selected: []
    },


    {
      item: "financier",
      name: "Financiers",
      action: ["view"],
      intro: "Lists of Banks providing financier services",
      storeid: "2",
      selected: []
    },




    {
      item: "tradeDiscovery",
      name: "Trade Discovery",
      action: ["view"],
      intro: "Allows to search and view trader information ",
      storeid: "3", selected: []

    },
    {
      item: "lcLimit",
      name: "Letter of credit ",
      action: ["create", "view"],
      intro: "View and Create limit application",
      storeid: "4",
      selected: []
    },
    {
      item: "invoiceLimit",
      name: "Apply Limit for Export Factoring",
      action: ["view", "create"],
      tro: "Apply Limit for Credit Guarantee Fund Trust for Micro and Small Enterprises",
      storeid: "5", selected: []
    },
    {
      item: "invoiceFinance",
      name: "Apply limit Finance ",
      action: ["view"],
      intro: "Apply Limit Finance for Export Factoring",
      storeid: "5",
      selected: []
    },
    {
      item: "invoiceApprovedFinance",
      name: "Finance Invoice Approved ",
      action: ["view"],
      intro: "Approved Finance Invoice ",
      storeid: "5", selected: []
    },
    {
      item: "leads",
      name: "Leads Assigned to traders",
      action: ["view"],
      intro: "View traders with their task and leads assigned to them",
      storeid: "7", selected: []
    },
    {
      item: "crmdata",
      name: "CRM Traders Data",
      action: ["view"],
      intro: "View the CRM data ",
      storeid: "7", selected: []
    },
    {
      item: "adminPayments",
      name: "Admin Payments",
      action: ["view"],
      intro: "Monitors the payments across the platform",
      storeid: "8", selected: []
    },
    {
      item: "generateInvoiceFinancier",
      name: "Generate Invoice",
      action: ["view", "create"],
      intro: "Generate Invoice for Financier",
      storeid: "9", selected: []
    },
    {
      item: "generateInvoiceCP",
      name: "Generate Invoice for Channel Partner",
      action: ["view", "create"],
      intro: "Generate Invoice for Channel Partner",
      storeid: "9", selected: []
    },

    {
      item: "AdminReports",
      name: "Report Analysis",
      action: ["view"],
      intro: "View Report analysis",
      storeid: "11",
      selected: []
    },
    {
      item: "masterdata",
      name: "Master Data Analysis",
      action: ["view"],
      intro: "View Master Data analysis",
      storeid: "12",
      selected: []
    },
    {
      item: "buyercreditcheck",
      name: "Check Buyer Credit",
      action: ["create"],
      intro: "View Buyers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "suppliercreditcheck",
      name: "Check Supplier Credit",
      action: ["create"],
      intro: "View Suppliers Credit",
      storeid: "13",
      selected: []
    },
    {
      item: "otherFinAdminwcQuote",
      name: "Working Capital Quote",
      action: ["view", "create"],
      intro: "Apply for Working Capital Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdmincgtmseQuote",
      name: "CGTMSE Finance Quote",
      action: ["view", "create"],
      intro: "Apply for CGTMSE Quote",
      storeid: "15",
      selected: []
    }, {
      item: "otherFinAdminbgQuote",
      name: "Bank Gurantee  Quote",
      action: ["view", "create"],
      intro: "Apply for Bank Gurantee Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "otherFinAdminscfQuote",
      name: "Supply Chain Finance  Quote",
      action: ["view", "create"],
      intro: "Apply for Supply Chain Finance Quote",
      storeid: "15",
      selected: []
    },
    {
      item: "contractManagementList",
      name: "Contract Management",
      action: ["view"],
      intro: "View contract list",
      storeid: "16", selected: []
    },




    {
      item: "ShipmentBookingQuotes",
      name: "Booking Quotes",
      action: ["view"],
      intro: "View shipment booking quotes",
      storeid: "17", selected: []
    },



    {
      item: "ChatRoomV2",
      name: "Chat Room",
      action: ["view"],
      intro: "View Chat Room",
      storeid: "10",
      selected: []
    },


  ]);

  const [rules, setRules] = useState([
    { id: 1, name: 'Limit Order Creation', emailEnabled: false },
    { id: 2, name: 'Purchase Order Cancellation', emailEnabled: false },
    { id: 3, name: 'Order Confirmation Creation', emailEnabled: false },
    { id: 4, name: 'Order Confirmation Cancellation', emailEnabled: false },
    { id: 5, name: 'Sales Quotation Creation', emailEnabled: false },
    { id: 6, name: 'Sales Quotation Cancellation', emailEnabled: false },
  ]);


  // const handleToggle = (index) => {
  //   setRules(prevRules => {
  //     console.log('Before:', prevRules);
  //     const updatedRules = prevRules.map((rule, i) =>
  //       i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
  //     );
  //     console.log('After:', updatedRules);
  //     return updatedRules;
  //   });
  // };

  // const handleToggle = (index) => {
  //   setRules(prevRules => {
  //     // Toggle the emailEnabled property for the selected rule
  //     const updatedRules = prevRules.map((rule, i) =>
  //       i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
  //     );

  //     // Update the accessObj state to include the updated rules
  //     setAccessObj(prevAccessObj => {
  //       // Create a copy of the accessObj array
  //       const updatedAccessObj = [...prevAccessObj];

  //       // Check if there is at least one element in the accessObj array
  //       if (updatedAccessObj.length > 0) {
  //         // Update the first element with the new rules
  //         updatedAccessObj[0] = {
  //           ...updatedAccessObj[0],
  //           rules: updatedRules // Add the updated rules here
  //         };
  //       }

  //       return updatedAccessObj;
  //     });

  //     return updatedRules;
  //   });
  // };

  const handleToggle = (index) => {
    setRules(prevRules => {
      // Toggle the emailEnabled property for the selected rule
      const updatedRules = prevRules.map((rule, i) =>
        i === index ? { ...rule, emailEnabled: !rule.emailEnabled } : rule
      );

      // Update the accessObj state to include the updated rules
      setAccessObj(prevAccessObj => {
        // Create a copy of the accessObj array
        const updatedAccessObj = [...prevAccessObj];

        // Check if there is at least one element in the accessObj array
        if (updatedAccessObj.length > 0) {
          // Extract the names of rules where emailEnabled is true
          const enabledNames = updatedRules
            .filter(rule => rule.emailEnabled)
            .map(rule => rule.name); // Assuming each rule has a 'name' property

          // Update the first element with the new rules and communicationSettings
          updatedAccessObj[0] = {
            ...updatedAccessObj[0],

            communicationSettings: enabledNames // Add or update communicationSettings
          };
        }

        return updatedAccessObj;
      });

      return updatedRules;
    });
  };


  console.log(routeObj, "this is new routeobj--->>>>>");
  console.log(data, "this is data")
  useEffect(() => {
    console.log("formdataaaaaaaa", formdata);
    if (isEditable) {
      setData({
        contact_name: formdata.contact_person,
        contact_personTitle: formdata.name_title,
        contact_number: formdata.contact_number,
        contact_phonecode: formdata.phone_code,
        email_id: formdata.email_id,
        addresss: formdata.user_address,
        panno: formdata.aadhar_no,
        aadhaarno: formdata.aadhar_no,
        password: decryptData(formdata.password),
      });
      setAccessObj(JSON.parse(formdata.UserPermissions));
    }
  }, []);

  console.log(accessObj, "accessobj--->>>>");
  const handleChangeUser = async (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: "" });
  };
  const [selectedModes, setSelectedModes] = useState({});

  useEffect(() => {
    call("GET", "getallCountry")
      .then((result) => {
        console.log("running getallCountry api-->", result);
        setCountrys(result);
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
      });
  }, []);

  const handleFileUser = (event, isImage) => {
    event.persist();
    if (!event.target.files.length) {
      return null;
    } else {
      let file_type = event.target.files[0]["type"].toLowerCase();
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg")
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]:
            "Files with pdf, png & jpeg extension are allowed",
        });
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0];
        let fileDataUrl = e.target.result;

        if (!file_type.includes("pdf") && !isImage) {
          let response = await convertImageToPdf(
            fileDataUrl,
            event.target.files[0]["name"]
          );
          console.log("pdfconversionresp", response);
          fileObj = response["file"];
          fileDataUrl = response["dataUrl"];
          toastDisplay("File converted into pdf format", "success");
        }
        fileObj["filebase64"] = fileDataUrl;
        setData({ ...data, [event.target.name]: fileObj });
        setErrors({ ...errors, [event.target.name]: "" });
      };
    }
  };
  const createSubUser = async () => {
    setShowLoader(true);
    try {
      let reqObj = {
        email: data.email_id,
        encryptedPassword: encryptData(data.password),
        type_id:
          userTokenDetails?.type_id === 19
            ? 21
            : userTokenDetails?.type_id === 8
              ? 22
              : 0,
        parent_id: userTokenDetails?.user_id,
        techType: "",
        user_address: data.addresss,
        nameTitle: data.contact_personTitle,
        contactName: data.contact_name,
        contactNumber: data.contact_number,
        PhoneCode: data.contact_phonecode,
        pan_no: data.panno,
        aadhar_no: data.aadhaarno,
        UserPermissions: JSON.stringify(accessObj),
      };
      console.log("reqobj", reqObj);
      const result = await call("POST", "createSubUser", reqObj);
      setShowLoader(false);
      // setSubUserModal(false);
      toastDisplay("User Added Succesfully", "success");
    } catch (e) {
      setShowLoader(false);
      toastDisplay("Failed to add the User", "error");
    }
  };
  const handleValidations = () => {
    let errors = {};
    if (!data.contact_name) {
      errors.contact_name = "Contact Name Cannot be empty";
    }
    if (!data.contact_personTitle) {
      errors.contact_personTitle = "Contact Name Title Cannot be empty";
    }
    if (!data.contact_number) {
      errors.contact_number = "Contact Number Cannot be empty";
    }
    if (!data.contact_phonecode) {
      errors.contact_phonecode = "Contact Number Code cannot be empty";
    }

    if (!data.password) {
      errors.password = "Password cannot be empty";
    }

    if (isEmpty(accessObj)) {
      errors.accesspermission = "You need to select at least one module";
    }
    if (isEmpty(errors)) {
      createSubUser();
    } else {
      setErrors(errors);
    }
  };
  console.log(routeObj, "this is routeobjjj---..................")
  // useEffect(() => {
  //   if (userTokenDetails.type_id === 19) {


  //     call('POST', "getallCustomRoles", { userId }).then((res) => {
  //       console.log(res, "this is res--->>>>");

  //       if (res.length) {
  //         // Get the current last id
  //         const lastId = routeObj.length ? parseInt(routeObj[routeObj.length - 1].id) : 0;

  //         // Create new objects from the response
  //         const newRouteObjs = res.map((item, index) => ({
  //           id: (lastId + index + 1).toString(),
  //           name: item.role,
  //         }));
  //         console.log(newRouteObjs, "this is newrouteobjsss--->>>")
  //         // Update the routeObj state with the new objects
  //         setrouteObj(prevState => [...prevState, ...newRouteObjs]);

  //         // Initialize a new UserAccess object
  //         const newUserAccess = { ...UserAcess };

  //         // Loop through the response and extract role and rolepermission
  //         res.forEach(item => {
  //           console.log(item, "this is item--->>>>.....")
  //           const role = item.role;
  //           const obj = {}

  //           const permissions = JSON.parse(item.permission);
  //           console.log(permissions, "this is permissionss-->>>>..........")
  //           const rolePermissions = permissions?.permissions?.map(p => p.item).join(',');
  //           console.log(rolePermissions, "this is role permissions")
  //         });
  //         console.log(newUserAccess, "this is newuser access --->>>////")
  //         // Update the UserAccess state
  //         setUserAccess(newUserAccess);


  //       }
  //     });
  //   } else if (userTokenDetails?.type_id === 8) {
  //     call('POST', "getallCustomRoles", { userId }).then((res) => {
  //       console.log(res, "this is res--->>>>");

  //       if (res.length) {
  //         // Get the current last id
  //         const lastId = routeObjFin.length ? parseInt(routeObjFin[routeObjFin.length - 1].id) : 0;

  //         // Create new objects from the response
  //         const newRouteObjs = res.map((item, index) => ({
  //           id: (lastId + index + 1).toString(),
  //           name: item.role,
  //         }));
  //         console.log(newRouteObjs, "this is newrouteobjsss--->>>")
  //         // Update the routeObj state with the new objects
  //         setrouteObjFin(prevState => [...prevState, ...newRouteObjs]);

  //         // Initialize a new UserAccess object
  //         const newUserAccess = { ...UserAcessFin };

  //         // Loop through the response and extract role and rolepermission
  //         res.forEach(item => {
  //           console.log(item, "this is item--->>>>.....")
  //           const role = item.role;
  //           const obj = {}

  //           const permissions = JSON.parse(item.permission);
  //           console.log(permissions, "this is permissionss-->>>>..........")
  //           const rolePermissions = permissions?.permissions?.map(p => p.item).join(',');
  //           console.log(rolePermissions, "this is role permissions")
  //         });
  //         console.log(newUserAccess, "this is newuser access --->>>////")
  //         // Update the UserAccess state
  //         setUserAccessFin(newUserAccess);


  //       }
  //     });
  //   } else if (userTokenDetails?.type_id === 1) {
  //     call('POST', "getallCustomRoles", { userId }).then((res) => {
  //       console.log(res, "this is res--->>>>");

  //       if (res.length) {
  //         // Get the current last id
  //         const lastId = routeObjAdmin.length ? parseInt(routeObjAdmin[routeObjAdmin.length - 1].id) : 0;

  //         // Create new objects from the response
  //         const newRouteObjs = res.map((item, index) => ({
  //           id: (lastId + index + 1).toString(),
  //           name: item.role,
  //         }));
  //         console.log(newRouteObjs, "this is newrouteobjsss--->>>")
  //         // Update the routeObj state with the new objects
  //         setrouteObjAdmin(prevState => [...prevState, ...newRouteObjs]);

  //         // Initialize a new UserAccess object
  //         const newUserAccess = { ...UserAcessAdmin };

  //         // Loop through the response and extract role and rolepermission
  //         res.forEach(item => {
  //           console.log(item, "this is item--->>>>.....")
  //           const role = item.role;
  //           const obj = {}

  //           const permissions = JSON.parse(item.permission);
  //           console.log(permissions, "this is permissionss-->>>>..........")
  //           const rolePermissions = permissions?.permissions?.map(p => p.item).join(',');
  //           console.log(rolePermissions, "this is role permissions")
  //         });
  //         console.log(newUserAccess, "this is newuser access --->>>////")
  //         // Update the UserAccess state
  //         setUserAccessFin(newUserAccess);


  //       }
  //     });
  //   }
  // }, []);


  console.log(routeObj, "this is new routeobj--->>>>>");



  console.log(accessObj, "accessobj--->>>>");


  useEffect(() => {
    call("GET", "getallCountry")
      .then((result) => {
        console.log("running getallCountry api-->", result);
        setCountrys(result);
      })
      .catch((e) => {
        // console.log('error in getBuyersDetail', e);
      });
  }, []);


  const selectedModesRef = useRef({});
  const [arr, setarr] = useState([]);
  const [anyUnchecked, setAnyUnchecked] = useState(false);
  const [storedItems, setStoredItems] = useState({});
  const [latermodes, setlatermodes] = useState({})
  useEffect(() => {
    if (userTokenDetails.type_id === 19) {


      const initialSelectedModes = arr.reduce((acc, item, index) => {
        const actions = item.action; // Assuming 'action' is an array like ['create'] or ['view']
        console.log(item, "this is actions----->>>>>>>>.");

        // Determine which actions to include
        const selectedActions = {};
        if (actions.includes('create')) {

          selectedActions.create = true;
          // item.selected.push("create");
        }
        if (actions.includes('view')) {

          selectedActions.view = true;
        }

        // Find corresponding viewAccess item and update its 'selected' key
        const viewAccessItem = viewAccess.find(v => v.name === item.name);
        if (viewAccessItem) {
          if (actions.includes('create') && !viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected.push('create');
          }
          if (actions.includes('view') && !viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected.push('view');
          }
        }
        console.log(viewAccessItem, "this is viewccess item--///////")
        // Add the 'name' property
        acc[index] = {
          ...selectedActions,
          name: item.name,
        };

        return acc;
      }, {});

      setSelectedModes(initialSelectedModes);
      setlatermodes(initialSelectedModes);
    } else if (userTokenDetails?.type_id === 8) {
      const initialSelectedModes = arr.reduce((acc, item, index) => {
        const actions = item.action; // Assuming 'action' is an array like ['create'] or ['view']
        console.log(item, "this is actions----->>>>>>>>.");

        // Determine which actions to include
        const selectedActions = {};
        if (actions.includes('create')) {
          selectedActions.create = true;
          item.selected.push("create");
        }
        if (actions.includes('view')) {
          selectedActions.view = true;
        }

        // Find corresponding viewAccess item and update its 'selected' key
        const viewAccessItem = viewAccessFin.find(v => v.name === item.name);
        if (viewAccessItem) {
          if (actions.includes('create') && !viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected.push('create');
          }
          if (actions.includes('view') && !viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected.push('view');
          }
        }
        console.log(viewAccessItem, "this is viewccess item--///////")
        // Add the 'name' property
        acc[index] = {
          ...selectedActions,
          name: item.name,
        };

        return acc;
      }, {});

      setSelectedModes(initialSelectedModes);
      setlatermodes(initialSelectedModes);
    } else if (userTokenDetails?.type_id === 1) {
      const initialSelectedModes = arr.reduce((acc, item, index) => {
        const actions = item.action;
        console.log(item, "this is actions----->>>>>>>>.");


        const selectedActions = {};
        if (actions.includes('create')) {
          selectedActions.create = true;
          // item.selected.push("create");
        }
        if (actions.includes('view')) {
          selectedActions.view = true;
        }


        const viewAccessItem = viewAccessAdmin.find(v => v.name === item.name);
        if (viewAccessItem) {
          if (actions.includes('create') && !viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected.push('create');
          }
          if (actions.includes('view') && !viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected.push('view');
          }
        }
        console.log(viewAccessItem, "this is viewccess item--///////")
        // Add the 'name' property
        acc[index] = {
          ...selectedActions,
          name: item.name,
        };

        return acc;
      }, {});

      setSelectedModes(initialSelectedModes);
      setlatermodes(initialSelectedModes);
    }
  }, [arr, viewAccess, viewAccessFin, viewAccessAdmin]);


  console.log(selectedModes, "this is the selected modes---->>>>>>>>>>>>>>>>>>.")



  console.log(viewAccess, "this is viewaccesssss---.......")

  useEffect(() => {
    selectedModesRef.current = selectedModes;
  }, [selectedModes]);

  console.log(selectedModes, "this is selectedmodes obj")


  const handleCheckboxClick = (index, type) => {
    if (userTokenDetails.type_id === 19) {
      setSelectedModes((prevSelectedModes) => {
        const currentSelectedModes = prevSelectedModes;
        const item = currentSelectedModes[index] || {};

        const updatedItem = {
          ...item,
          [type]: !item[type],
        };

        const newState = {
          ...currentSelectedModes,
          [index]: updatedItem,
        };

        const viewAccessItem = viewAccess.find(v => v.name === newState[index].name);
        if (viewAccessItem) {
          if (newState[index].create === false && viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected = viewAccessItem.selected.filter(action => action !== 'create');
          } else if (newState[index]['create'] === true && !viewAccessItem.selected.includes('create')) {
            viewAccessItem.selected.push('create');
          }

          if (newState[index].view === false && viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected = viewAccessItem.selected.filter(action => action !== 'view');
          } else if (newState[index]['view'] === true && !viewAccessItem.selected.includes('view')) {
            viewAccessItem.selected.push('view');
          }
        }

        const isAnyUnchecked = Object.values(newState).some(
          (modes) => !modes.create || !modes.view
        );

        setAnyUnchecked(isAnyUnchecked);

        return newState;
      });
    } else if (userTokenDetails?.type_id === 8) {
      // Retrieve the current state directly
      const currentSelectedModes = selectedModes;
      console.log("this is current selecetd modes-->>>", currentSelectedModes)
      // Initialize the item if it doesn't exist
      const item = currentSelectedModes[index] || {};

      console.log("Previous item state:", item);

      // Toggle the checkbox status for the specific item
      const updatedItem = {
        ...item,
        [type]: !item[type],
      };

      const newState = {
        ...currentSelectedModes,
        [index]: updatedItem,
      };

      console.log("New item state:", newState[index]);
      const viewAccessItem = viewAccessFin.find(v => v.name === newState[index].name);
      if (viewAccessItem) {
        if (newState[index].create === false && viewAccessItem.selected.includes('create')) {
          const val = viewAccessItem.selected.filter(action => action !== 'create')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['create'] === true && !viewAccessItem.selected.includes('create')) {
          viewAccessItem.selected.push('create');
        }

        // Handle removal for 'view' similarly
        if (newState[index].view === false && viewAccessItem.selected.includes('view')) {
          const val = viewAccessItem.selected.filter(action => action !== 'view')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['view'] === true && !viewAccessItem.selected.includes('view')) {
          viewAccessItem.selected.push('view');
        }
      }
      console.log(viewAccessItem, "this is viewccess item--///////")

      // Log the entire updated state
      console.log("Updated state in checkbox click:", newState);

      // Determine if any checkboxes are unchecked
      const isAnyUnchecked = Object.values(newState).some(
        (modes) => !modes.create || !modes.view
      );

      console.log("Is any unchecked:", isAnyUnchecked);

      // Update the states
      setSelectedModes(newState);
      setAnyUnchecked(isAnyUnchecked);
    } else if (userTokenDetails?.type_id === 1) {
      // Retrieve the current state directly
      const currentSelectedModes = selectedModes;
      console.log("this is current selecetd modes-->>>", currentSelectedModes)
      // Initialize the item if it doesn't exist
      const item = currentSelectedModes[index] || {};

      console.log("Previous item state:", item);

      // Toggle the checkbox status for the specific item
      const updatedItem = {
        ...item,
        [type]: !item[type],
      };

      const newState = {
        ...currentSelectedModes,
        [index]: updatedItem,
      };

      console.log("New item state:", newState[index]);
      const viewAccessItem = viewAccessAdmin.find(v => v.name === newState[index].name);
      console.log(viewAccessItem, "this is view access item---->>>>>")
      if (viewAccessItem) {
        if (newState[index].create === false && viewAccessItem.selected.includes('create')) {
          const val = viewAccessItem.selected.filter(action => action !== 'create')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['create'] === true && !viewAccessItem.selected.includes('create')) {
          viewAccessItem.selected.push('create');
        }

        // Handle removal for 'view' similarly
        if (newState[index].view === false && viewAccessItem.selected.includes('view')) {
          console.log("hii to check false view")
          const val = viewAccessItem.selected.filter(action => action !== 'view')
          console.log(val, " this is val ----->>>>>>")
          viewAccessItem.selected = val
        } else if (newState[index]['view'] === true && !viewAccessItem.selected.includes('view')) {
          viewAccessItem.selected.push('view');
        }
      }
      console.log(viewAccessItem, "this is viewccess item--///////")

      // Log the entire updated state
      console.log("Updated state in checkbox click:", newState);

      // Determine if any checkboxes are unchecked
      const isAnyUnchecked = Object.values(newState).some(
        (modes) => !modes.create || !modes.view
      );

      console.log("Is any unchecked:", isAnyUnchecked);

      // Update the states
      setSelectedModes(newState);
      setAnyUnchecked(isAnyUnchecked);
    }

  };

  // Adding an effect to log state changes
  useEffect(() => {
    console.log("State changed:", selectedModes);
  }, [selectedModes]);





  console.log(storedItems, "this is stored items-->>>>>>");


  const [selectedvalues, setSelectedvalues] = useState([]);
  const [roleValues, setRoleValues] = useState([]);
  const [assign, boolassign] = useState(false)
  const [editedCard, setEditedCard] = useState("Custom Role")

  const handleEditChange = (e) => {
    setEditedCard(e.target.value);
  };
  // Function to find matched entry by name

  useEffect(() => {
    if (userTokenDetails.type_id === 19) {


      if (selectedvalues.length > 0) {
        let newArr = [];
        selectedvalues.forEach((ele) => {
          if (UserAcess.hasOwnProperty(ele.name)) {
            newArr = [...newArr, ...UserAcess[ele.name].split(",")];
          }
        });
        const filteredViewAccess = viewAccess.filter((item) =>
          newArr.includes(item.item)
        );

        setarr(filteredViewAccess);
        console.log(selectedModes, 'this is in useffect-->>>>>')
      } else {
        setarr([]);
      }
    } else if (userTokenDetails?.type_id === 8) {
      if (selectedvalues.length > 0) {
        let newArr = [];
        selectedvalues.forEach((ele) => {
          if (UserAcessFin.hasOwnProperty(ele.name)) {
            newArr = [...newArr, ...UserAcessFin[ele.name].split(",")];
          }
        });
        const filteredViewAccess = viewAccessFin.filter((item) =>
          newArr.includes(item.item)
        );

        setarr(filteredViewAccess);
        console.log(selectedModes, 'this is in useffect-->>>>>')
      } else {
        setarr([]);
      }
    } else if (userTokenDetails?.type_id === 1) {
      if (selectedvalues.length > 0) {
        let newArr = [];
        selectedvalues.forEach((ele) => {
          if (UserAcessAdmin.hasOwnProperty(ele.name)) {
            newArr = [...newArr, ...UserAcessAdmin[ele.name].split(",")];
          }
        });
        const filteredViewAccess = viewAccessAdmin.filter((item) =>
          newArr.includes(item.item)
        );

        setarr(filteredViewAccess);
        console.log(selectedModes, 'this is in useffect-->>>>>')
      } else {
        setarr([]);
      }
    }
  }, [selectedvalues, UserAcess, UserAcessFin, UserAcessAdmin]);

  const handleMultiSelectchange = (e, name, val, singleSelect) => {
    // Update data state
    if (singleSelect) {
      setData({
        ...data,
        [name]: e?.[0]?.[val] ? e.reverse()?.[0]?.[val] : null,
      });
    } else {
      setData({
        ...data,
        [name]: Array.isArray(e) ? e.map((x) => x[val]) : [],
      });
    }

    if (userTokenDetails.type_id === 19) {



      // Get the selected roles
      const selectedRoles = e
        .map((item) => routeObj.find((ele) => ele.id === item[val]?.toString()))
        .filter(Boolean);


      // Log selected roles
      console.log(selectedRoles, "these are selected roles--.....");
      setSelectedvalues(selectedRoles);

      const updatedAccessObj = [...accessObj];


      const allSelectedPermissions = [];
      let finalobj = {};
      selectedRoles.forEach((selectedRole) => {
        console.log(selectedRole, "this is selected role in multiselect----?????????")

        if (selectedRole) {

          // finalobj.roleName = selectedRole.name;

          if (UserAcess[selectedRole.name]) {

            const arrPermit = UserAcess[selectedRole.name].split(',')

            const permissions = [];
            const sideId = new Set();

            arrPermit.forEach((element) => {
              const matchedItem = viewAccess.find((accessItem) => accessItem.item === element);
              // if (matchedItem) {
              //   const obj = {

              //   }
              //   obj.name = matchedItem.item
              //   obj.subPermission = matchedItem.selected

              //   console.log(matchedItem, "this is matched item--->>>>>")

              //   sideId.add(matchedItem.storeid);  // Add storeid to the Set



              //   permissions.push(matchedItem);


              // }
              if (matchedItem) {
                // Remove the unwanted properties
                const { action, intro, name, storeid, ...cleanedItem } = matchedItem;

                // Add storeid to the Set
                sideId.add(matchedItem.storeid);

                // Push the cleaned item to the permissions array
                permissions.push(cleanedItem);
              }

            });
            const uniquesideId = Array.from(sideId)
            finalobj.sideBarId = uniquesideId
            finalobj.permissions = permissions

            console.log(sideId, "this is unique side ids--->>>>", permissions, "hey this is permissions---////")
            console.log(finalobj, "this is payload--->>>>>")

          }
        }
        setAccessObj([...accessObj, finalobj])
      });
      console.log(updatedAccessObj, "updated accessobj---/////");
    } else if (userTokenDetails.type_id === 8) {
      const selectedRoles = e
        .map((item) => routeObjFin.find((ele) => ele.id === item[val]?.toString()))
        .filter(Boolean);


      // Log selected roles
      console.log(selectedRoles, "these are selected roles--.....");
      setSelectedvalues(selectedRoles);

      const updatedAccessObj = [...accessObj];


      const allSelectedPermissions = [];
      let finalobj = {};
      selectedRoles.forEach((selectedRole) => {
        console.log(selectedRole, "this is selected role in multiselect----?????????")

        if (selectedRole) {

          finalobj.roleName = selectedRole.name;

          if (UserAcessFin[selectedRole.name]) {

            const arrPermit = UserAcessFin[selectedRole.name].split(',')

            const permissions = [];
            const sideId = new Set();

            arrPermit.forEach((element) => {
              const matchedItem = viewAccessFin.find((accessItem) => accessItem.item === element);
              // if (matchedItem) {
              //     console.log(matchedItem, "this is matched item--->>>>>")

              //   sideId.add(matchedItem.storeid);  // Add storeid to the Set
              //   permissions.push(matchedItem);


              // }
              if (matchedItem) {
                // Remove the unwanted properties
                const { action, intro, name, storeid, ...cleanedItem } = matchedItem;

                // Add storeid to the Set
                sideId.add(matchedItem.storeid);

                // Push the cleaned item to the permissions array
                permissions.push(cleanedItem);
              }
            });
            const uniquesideId = Array.from(sideId)
            finalobj.sideBarId = uniquesideId
            finalobj.permissions = permissions

            console.log(sideId, "this is unique side ids--->>>>", permissions, "hey this is permissions---////")
            console.log(finalobj, "this is payload--->>>>>")

          }
        }
        setAccessObj([...accessObj, finalobj])
      });
      console.log(updatedAccessObj, "updated accessobj---/////");
    } else if (userTokenDetails.type_id === 1) {
      const selectedRoles = e
        .map((item) => routeObjAdmin.find((ele) => ele.id === item[val]?.toString()))
        .filter(Boolean);


      // Log selected roles
      console.log(selectedRoles, "these are selected roles--.....");
      setSelectedvalues(selectedRoles);

      const updatedAccessObj = [...accessObj];


      const allSelectedPermissions = [];
      let finalobj = {};
      selectedRoles.forEach((selectedRole) => {
        console.log(selectedRole, "this is selected role in multiselect----?????????")

        if (selectedRole) {

          finalobj.roleName = selectedRole.name;

          if (UserAcessAdmin[selectedRole.name]) {

            const arrPermit = UserAcessAdmin[selectedRole.name].split(',')

            const permissions = [];
            const sideId = new Set();

            arrPermit.forEach((element) => {
              const matchedItem = viewAccessAdmin.find((accessItem) => accessItem.item === element);
              // if (matchedItem) {
              //   console.log(matchedItem, "this is matched item--->>>>>")

              //   sideId.add(matchedItem.storeid);
              //   permissions.push(matchedItem);


              // }

              console.log(matchedItem, "this ismatched item--...")
              if (matchedItem) {
                // Remove the unwanted properties
                const { action, intro, name, storeid, ...cleanedItem } = matchedItem;

                // Add storeid to the Set
                sideId.add(matchedItem.storeid);

                // Push the cleaned item to the permissions array
                permissions.push(cleanedItem);
              }
            });
            const uniquesideId = Array.from(sideId)
            finalobj.sideBarId = uniquesideId
            finalobj.permissions = permissions

            console.log(sideId, "this is unique side ids--->>>>", permissions, "hey this is permissions---////")
            console.log(finalobj, "this is payload--->>>>>")

          }
        }
        setAccessObj([...accessObj, finalobj])
      });
      console.log(updatedAccessObj, "updated accessobj---/////");
    }

  };

  console.log(data.role_name, "this data roleeee---->>>>>>");



  const handleassign = () => {
    boolassign(!assign)
  };
  console.log(assign, "this is assiggn---->>>>")
  const handleSkip = (
  ) => {
    toastDisplay("Click on Add New User", "success")
  };

  const handleCreateandAssign = () => {
    call('POST', 'rolesandorganizations', { role: editedCard, permissions: accessObj, userId }).then(() => toastDisplay("New Subuser created and Assigned", "success"))
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <div>
        <div className=" mr-0 my-0">
          <div className=" pb-4">
            <div className="border-0">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <label className="font-size-16 font-wt-600 text-color-value mx-3">
                  Add New User
                </label>
              </div>
            </div>

            <div className="modal-body px-4">
              <div className="row">
                <div className="col-md-6 py-2">
                  <InputWithSelect
                    isAstrix={false}
                    type={"text"}
                    label={"Name"}
                    isDisabled={isEditable}
                    selectData={[{ name: "Mr" }, { name: "Miss" }]}
                    selectName={"contact_personTitle"}
                    selectValue={data.contact_personTitle}
                    optionLabel={"name"}
                    optionValue={"name"}
                    name={"contact_name"}
                    value={data.contact_name}
                    error={errors.contact_name}
                    onChange={handleChangeUser}
                  />
                </div>
                <div className="col-md-6 py-2">
                  <InputWithSelect
                    isAstrix={false}
                    type={"text"}
                    label={"Contact No."}
                    isDisabled={isEditable}
                    selectData={countrys}
                    selectName={"contact_phonecode"}
                    selectValue={data.contact_phonecode}
                    optionLabel={"phonecode"}
                    optionValue={"phonecode"}
                    name={"contact_number"}
                    value={data.contact_number}
                    error={errors.contact_number}
                    onChange={handleChangeUser}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 py-2">
                  <NewInput
                    isAstrix={true}
                    type={"text"}
                    label={"Email ID"}
                    isDisabled={isEditable}
                    name={"email_id"}
                    value={data.email_id}
                    error={errors.email_id}
                    onChange={handleChangeUser}
                  />
                </div>
                <div className="col-md-6 py-2">
                  <div className="form-group w-100 position-relative">
                    <i
                      onClick={() => toggleSecurePw(!securePw)}
                      className={`fas ${!securePw ? "fa-eye" : "fa-eye-slash"
                        } input-icon`}
                      id="togglePassword"
                    ></i>
                    <input
                      type={securePw ? "password" : "text"}
                      className={
                        "form-control" +
                        (!errors.password ? "" : " border-danger")
                      }
                      placeholder="Enter Password"
                      name="password"
                      value={data.password}
                      disabled={isEditable}
                      onChange={handleChangeUser}
                    />
                    {errors.password && (
                      <div className="text-danger mt-2 font-size-12">
                        <i
                          className="fa fas fa-exclamation-circle mr-1"
                          aria-hidden="true"
                        ></i>
                        <b>{errors.password}</b>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 py-2">
                  <NewInput
                    type={"text"}
                    label={"Address"}
                    isDisabled={isEditable}
                    name={"address"}
                    value={data.address}
                    error={errors.address}
                    onChange={handleChangeUser}
                  />
                </div>
                <div className="col-md-6 py-2">
                  <NewInput
                    type={"text"}
                    label={"PAN No."}
                    isDisabled={isEditable}
                    name={"panno"}
                    value={data.panno}
                    error={errors.panno}
                    onChange={handleChangeUser}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 py-2">
                  <NewInput
                    type={"text"}
                    label={"Aadhaar No."}
                    isDisabled={isEditable}
                    name={"aadhaarno"}
                    value={data.aadhaarno}
                    error={errors.aadhaarno}
                    onChange={handleChangeUser}
                  />
                </div>
              </div>

              <label className="font-size-15 font-wt-500 my-4">
                <u>Attach Document</u>
              </label>
              <div>
                <label className="pl-2 font-size-14 font-wt-300">
                  Aadhar card
                </label>
                <div className="col">
                  <FileInput
                    name={"User_Aadhaar_Document"}
                    value={data?.User_Aadhaar_Document}
                    error={errors.User_Aadhaar_Document}
                    onChange={handleFileUser}
                    isEditable={!isEditable}
                    userTokenDetails={userTokenDetails}
                    onUploadCancel={() =>
                      setData({ ...data, User_Aadhaar_Document: null })
                    }
                  />
                </div>
                <label className="pl-2 font-size-14 font-wt-300">
                  PAN card
                </label>
                <div className="col">
                  <FileInput
                    name={"User_PAN_Document"}
                    value={data?.User_PAN_Document}
                    error={errors.User_PAN_Document}
                    onChange={handleFileUser}
                    isEditable={!isEditable}
                    userTokenDetails={userTokenDetails}
                    onUploadCancel={() =>
                      setData({ ...data, User_PAN_Document: null })
                    }
                  />
                </div>
              </div>
              <label className="font-size-15 font-wt-500 my-4">
                <u>Assign Role</u>
              </label>

              <div className="d-flex flex-column justify-content-start align-items-start">
                <div
                  style={{
                    width: "50%",
                    maxWidth: "400px",
                    marginBottom: "15px",
                  }}
                >
                  <MultipleSelect
                    Label={"Select Role"}
                    optiondata={userTokenDetails.type_id === 19 ? routeObj : userTokenDetails.type_id === 8 ? routeObjFin : userTokenDetails.type_id === 1 ? routeObjAdmin : []}
                    Id={`role_name`}
                    name={"role_name"}
                    labelKey={"name"}
                    valKey={"id"}
                    value={data["role_name"] ? data["role_name"] : []}
                    onChange={async (e) => {
                      handleMultiSelectchange(e, "role_name", "id");
                    }}
                  />

                </div>
              </div>

              {anyUnchecked && (
                <div style={{ marginTop: "1rem" }}>
                  {assign && (
                    <>
                      <div className="d-flex flex-column card-body cursor-pointer">
                        <label style={{ marginBottom: '1rem' }}>Create and Assign New Role</label>
                        <div className="d-flex align-items-center" style={{ width: "50%" }}>
                          <div style={{ position: "relative", width: "60%" }}>
                            <input
                              type="text"
                              value={editedCard}
                              onChange={handleEditChange}
                              style={{
                                width: "100%",
                                fontSize: "1rem",
                                border: "1px solid lightgray",
                                textAlign: "center",
                                outline: "none",
                                paddingRight: "25px", // Add padding to make space for the image
                                height: "2rem",
                                borderRadius: "0.5rem"
                              }}
                              placeholder="Custom Role"
                            />
                            <img
                              src={"/assets/images/edit.png"}
                              height={20}
                              width={30}
                              className="cursor"
                              alt="cb"
                              style={{
                                position: "absolute",
                                right: "10px", // Adjust the position of the image inside the input box
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            />
                          </div>
                          <button
                            style={{
                              marginLeft: '1rem',
                              height: "2rem",
                              fontSize: "1rem",
                              border: "none",
                              borderRadius: "0.5rem",
                              backgroundColor: "#5CB8D3",
                              color: "white",
                              cursor: "pointer",
                              paddingInline: "1rem"
                            }}
                            onClick={handleCreateandAssign}
                          >
                            Create and Assign
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}



              {arr.length > 0 && (
                <div style={{ border: "1px solid lightgrey", width: "60rem" }}>
                  <label
                    className="mt-2 mb-4 d-flex justify-content-center  cursor-pointer"
                    style={{
                      color: "#5CB8D3",
                      fontSize: "1rem",
                    }}
                  >
                    Permissions included in the selected Roles are listed below
                  </label>
                  {arr.map((ele, index) => (
                    <div
                      key={index}
                      className="d-flex "
                      style={{ padding: "1rem" }}
                    >
                      <div>
                        <div style={{ width: "20rem" }} >{ele.name}</div>
                        {/* <div>{ele.intro}</div> */}
                      </div>
                      <div className="col-8 text-center">
                        <div className="d-flex justify-content-around align-items-center">
                          <div
                            className="checkbox-container"
                            onClick={() => handleCheckboxClick(index, "create")}
                          >
                            {ele.action.includes("create") && (
                              <img
                                src={
                                  selectedModes[index]?.create
                                    ? "/assets/images/checked-green.png"
                                    : "/assets/images/unchecked-box.png"
                                }
                                height={20}
                                width={20}
                                className="checkbox-image cursor"
                                alt="checkbox"
                              />
                            )}
                            {ele.action.includes("create") ? "Create" : ""}
                          </div>
                          <div
                            className="checkbox-container"
                            onClick={() => handleCheckboxClick(index, "view")}
                          >
                            {ele.action.includes("view") && (
                              <img
                                src={
                                  selectedModes[index]?.view
                                    ? "/assets/images/checked-green.png"
                                    : "/assets/images/unchecked-box.png"
                                }
                                height={20}
                                width={20}
                                className="checkbox-image cursor"
                                alt="checkbox"
                              />
                            )}
                            {ele.action.includes("view") ? "View" : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {anyUnchecked && (
                    <div style={{ marginTop: "1rem" }}>

                      <div className="d-flex justify-content-center" style={{ gap: "1rem", marginBottom: "2rem" }}>
                        <button
                          className={`new-btn py-2 px-2 text-white cursor`}
                          style={{ width: "150px" }}
                          onClick={handleassign}
                        >
                          Assign New Role
                        </button>
                        <button
                          className={`new-btn py-2 px-2 text-white cursor`}
                          style={{ width: "150px" }}
                          onClick={handleSkip}
                        >
                          Skip
                        </button>
                      </div>
                    </div>

                  )}
                </div>
              )}
              {errors.accesspermission && (
                <div class="text-danger mt-2 font-size-12">
                  <i
                    class="fa fas fa-exclamation-circle mr-1"
                    aria-hidden="true"
                  ></i>
                  <b>{errors.accesspermission}</b>
                </div>
              )}


              <div style={{ padding: '1rem', textDecoration: "underline", fontWeight: "500" }}>Communication Settings</div>
              <div>
                <div className="row">
                  <div style={{ border: "1px solid lightgrey", borderRadius: "1rem" }}>
                    <table className="styled-table">
                      <thead>
                        <tr
                          style={{
                            height: "3.2rem",
                            alignItems: "baseline",
                            borderBottom: "1px solid lightgrey"
                          }}
                          className="position-relative font-wt-600 text-color-value font-size-13 pl-2"
                        >
                          <th style={{ width: "100%" }}></th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rules.map((entry, index) => (
                          <tr key={index} style={{ borderBottom: "1px solid lightgrey" }}>
                            <td >{entry.name}</td>
                            <td>
                              <Switch
                                onChange={() => handleToggle(index)}
                                checked={entry.emailEnabled}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor="#4caf50"
                                offColor="#ccc"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>



                    </table>
                  </div>
                </div>
              </div>

              {!isEditable && (
                <div className="d-flex gap-4">
                  <button
                    className={`mt-4 new-btn w-20 py-2 px-2 text-white cursor`}
                    onClick={handleValidations}
                  >
                    Add New User
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubUserModal;
