import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import DropdownSearch from '../tallyReports/Cards/dropdownWithSearch';

const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const InventoryUnits = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [units, setUnits] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [parent, setParent] = useState([])

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  // const uqcOptions = [
  //   " Not Applicable",
  //   "BAG-BAGS",
  //   "BAL-BALE",
  //   "BDL-BUNDLES",
  //   "BKL-BUCKLES",
  //   "BOU-BILLION OF UNITS",
  //   "BOX-BOX",
  //   "BTL-BOTTLES",
  //   "BUN-BUNCHES",
  //   "CAN-CANS",
  //   "CBM-CUBIC METERS",
  //   "CCM-CUBIC CENTIMETERS",
  //   "CMS-CENTIMETERS",
  //   "CTN-CARTONS",
  //   "DOZ-DOZENS",
  //   "DRM-DRUMS",
  //   "GGK-GREAT GROSS",
  //   "GMS-GRAMMES",
  //   "GRS-GROSS",
  //   "GYD-GROSS YARDS",
  //   "KGS-KILOGRAMS",
  //   "KLR-KILOLITRE",
  //   "KME-KILOMETRE",
  //   "LTR-LITRES",
  //   "MLT-MILLILITRE",
  //   "MTR-METERS",
  //   "MTS-METRIC TON",
  //   "NOS-NUMBERS",
  //   "OTH-OTHERS",
  //   "PAC-PACKS",
  //   "PCS-PIECES",
  //   "PRS-PAIRS",
  //   "QTL-QUINTAL",
  //   "ROL-ROLLS",
  //   "SET-SETS",
  //   "SQF-SQUARE FEET",
  //   "SQM-SQUARE METERS",
  //   "SQY-SQUARE YARDS",
  //   "TBS-TABLETS",
  //   "TGM-TEN GROSS",
  //   "THD-THOUSANDS",
  //   "TON-TONNES",
  //   "TUB-TUBES",
  //   "UGS-US GALLONS",
  //   "UNT-UNITS",
  //   "Uqc Custom 1",
  //   "YDS-YARDS",
  // ];




  // console.log(
  //   userTokenDetails.UserAccessPermission,
  //   JSON.parse(userTokenDetails.UserAccessPermission),
  //   "this is exportermanagement--->>>>>"
  // );
  const uqcOptions = [
    { name: " Not Applicable" },
    { name: "BAG-BAGS" },
    { name: "BAL-BALE" },
    { name: "BDL-BUNDLES" },
    { name: "BKL-BUCKLES" },
    { name: "BOU-BILLION OF UNITS" },
    { name: "BOX-BOX" },
    { name: "BTL-BOTTLES" },
    { name: "BUN-BUNCHES" },
    { name: "CAN-CANS" },
    { name: "CBM-CUBIC METERS" },
    { name: "CCM-CUBIC CENTIMETERS" },
    { name: "CMS-CENTIMETERS" },
    { name: "CTN-CARTONS" },
    { name: "DOZ-DOZENS" },
    { name: "DRM-DRUMS" },
    { name: "GGK-GREAT GROSS" },
    { name: "GMS-GRAMMES" },
    { name: "GRS-GROSS" },
    { name: "GYD-GROSS YARDS" },
    { name: "KGS-KILOGRAMS" },
    { name: "KLR-KILOLITRE" },
    { name: "KME-KILOMETRE" },
    { name: "LTR-LITRES" },
    { name: "MLT-MILLILITRE" },
    { name: "MTR-METERS" },
    { name: "MTS-METRIC TON" },
    { name: "NOS-NUMBERS" },
    { name: "OTH-OTHERS" },
    { name: "PAC-PACKS" },
    { name: "PCS-PIECES" },
    { name: "PRS-PAIRS" },
    { name: "QTL-QUINTAL" },
    { name: "ROL-ROLLS" },
    { name: "SET-SETS" },
    { name: "SQF-SQUARE FEET" },
    { name: "SQM-SQUARE METERS" },
    { name: "SQY-SQUARE YARDS" },
    { name: "TBS-TABLETS" },
    { name: "TGM-TEN GROSS" },
    { name: "THD-THOUSANDS" },
    { name: "TON-TONNES" },
    { name: "TUB-TUBES" },
    { name: "UGS-US GALLONS" },
    { name: "UNT-UNITS" },

    { name: "YDS-YARDS" },
  ];

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  // console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };
  useEffect(() => { console.log("form data here", data) }, [data])
  const subPermissions = getSubPermissions("tallyMastersStockUnits");
  // console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    // console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  // console.log(subPermissions, "this is buyer management--->>>>>");
  // console.log(booladd, "this is bool add--->>>>");


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getInventoryStockUnits', objectAPI).then((result) => {
      // console.log('running getInventoryGodowns api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setUnits(result.units)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getInventoryGodowns', e);
      setshowLoader(false)
    });
    SetCache("inventoryStockGroupsFilterData", filterData)
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getInventoryStockUnits', { userId }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        res.data[index]["name"] = res.data[index]["details"]["name"]
      }
      setParent([{ name: 'Primary' }, { name: 'Main Location' }, ...res.data])
    })
  }, [refresh])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      // console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation() {
    let validateFields = data.typeOfUnit === "Simple" ? ['uqc', "decimalPlaces", "formalName", "symbol", `typeOfUnit`] : ["firstUnit", "secoundUnit", "typeOfUnit", "conversionOf"]
    let err = {}
    for (let index = 0; index < validateFields.length; index++) {
      const element = validateFields[index];
      console.log(`Checking field ${element}:`, data[element]);
      if (!data[element]) {
        err[element] = 'Mandatory field'
      }
    }
    console.log("err", err)
    if (!Object.keys(err).length) {
      setshowLoader(true)
      call('POST', 'saveInventoryMastersUnits', {
        ...data, userId, ...(data.typeOfUnit === "Compound" && {
          symbol: `${data.firstUnit} of ${data.conversionOf} ${data.secoundUnit}`
        })
      }).then(res => {
        toastDisplay(data.id ? "Unit Updated" : "Unit Created", "success", () => {
          setshowLoader(false)
          toggleCreateGroupView({ show: false })
          setData({})
          setRefresh(refresh + 1)
        })
      })
      setshowLoader(false)
    }
    setErrors({ ...err })
  }

  const handleCreateNew = () => {

    if (booladd || userPermissions == null) {
      toggleCreateGroupView({ show: true })
    } else {
      // console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }
  // const filteredUnits = data.firstUnit
  //   ? units.filter((ele) => ele !== data.firstUnit)
  //   : units;
  // console.log("foletered unit", filteredUnits)
  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyMastersStockUnits" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Tally > Inventory Master > Units${createGroupView.show ? `${createGroupView.viewUpdate ? ' > View/Update' : ' > Create New'}` : ''}`}
              userTokenDetails={userTokenDetails} />

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton>
                  <div className="d-flex gap-4">
                    <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={handleCreateNew}>Create New</button>
                  </div>
                </Filter>
              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      toggleCreateGroupView({ show: false })
                      setData({})
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="form-details bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1">
                      <FormProgressBar
                        tabs={[{ name: "Unit Creation" }]}
                        activeTab={tab}
                        label={"name"}
                        separationWidth={'1rem'}
                      />
                    </div>
                    <div className="row d-flex px-4 py-5">
                      {/* <label>Enter Details :</label> */}
                      {/* Type Field */}
                      <div className="col-md-3">
                        <MultipleSelect
                          singleSelection
                          Label={'Select Type'}
                          Id={`typeOfUnit`}
                          optiondata={[{ name: "Simple" }, { name: "Compound" }]}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `typeOfUnit`, "name"));
                          }}
                          value={data[`typeOfUnit`] ? [data[`typeOfUnit`]] : []}
                          name={`typeOfUnit`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`typeOfUnit`]}
                        />
                      </div>
                      {data.typeOfUnit === "Simple" ? <>
                        {/* Symbol Field */}
                        <div className="col-md-3">

                          <NewInput
                            type={"text"}
                            label={"Symbol"}
                            name={"symbol"}
                            value={data.symbol}
                            error={errors.symbol}
                            onChange={handleChange}
                          />
                        </div>

                        {/* Formal Name Field */}
                        <div className="col-md-3">

                          <NewInput
                            type={"text"}
                            label={"Formal Name"}
                            name={"formalName"}
                            value={data.formalName}
                            error={errors.formalName}
                            onChange={handleChange}
                          />
                        </div>



                        <div className="col-md-3">
                          <MultipleSelect
                            singleSelection
                            Label={'Select UQC'}
                            Id={`uqc`}
                            optiondata={uqcOptions}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `uqc`, "name"));
                            }}
                            value={data[`uqc`] ? [data[`uqc`]] : []}
                            name={`uqc`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`uqc`]}
                            addCustomValue={true}
                          />

                        </div>
                        {/* Unit Quantity Code (UQC) Field */}
                        <div className="col-md-3">
                          <NewInput
                            type={"number"}
                            label={"Number of Decimal Places"}
                            name={"decimalPlaces"}
                            value={data.decimalPlaces}
                            error={errors.decimalPlaces}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <DropdownSearch
                        placeholder={'Unit Quantity Code (UQC)'}
                        customStyles={{
                          widthOfInput: "250px"
                        }}
                        defaultItem={uqcOptions[0]}
                        items={uqcOptions}
                        onSelect={(selectedItem) => setData((prev) => ({ ...prev, uqc: selectedItem }))}
                        value={data[`uqc`] ? [data[`uqc`]] : []}
                        name={`uqc`}
                        labelKey={"name"}
                        valKey={"name"}
                        error={errors[`uqc`]}
                      /> */}

                      </> :
                        <>
                          <div className="col-md-3">
                            <MultipleSelect
                              singleSelection
                              Label={'Select First Unit'}
                              Id={`firstUnit`}
                              optiondata={units}
                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `firstUnit`, "name"));
                              }}
                              value={data[`firstUnit`] ? [data[`firstUnit`]] : []}
                              name={`firstUnit`}
                              labelKey={"name"}
                              valKey={"name"}
                              error={errors[`firstUnit`]}
                            />
                          </div>
                          <div className="col-md-3">

                            <NewInput
                              type={"number"}
                              label={"Conversion Of"}
                              name={"conversionOf"}
                              value={data.conversionOf}
                              error={errors.conversionOf}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-md-3">
                            <MultipleSelect
                              singleSelection
                              Label={'Select Secound Unit'}
                              Id={`secoundUnit`}
                              optiondata={data.firstUnit ? units.filter(ele => ele.name !== data['firstUnit']) : units}

                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `secoundUnit`, "name"));
                              }}
                              value={data[`secoundUnit`] ? [data[`secoundUnit`]] : []}
                              name={`secoundUnit`}
                              labelKey={"name"}
                              valKey={"name"}
                              error={errors[`secoundUnit`]}
                            />
                          </div>
                        </>}




                      {/* Submit Button */}
                      <div className='d-flex justify-content-center'>
                        <button
                          type="button"
                          onClick={() => handleValidation()}
                          className={`new-btn w-15 py-2 px-2 text-white mt-5`}
                        >
                          {createGroupView.viewUpdate ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={[
                      { subColumns: 'Date' },
                      { subColumns: 'Type Of Unit' },
                      { subColumns: 'Formal Name' },
                      { subColumns: 'Symbol' },
                      { subColumns: 'UQC' },
                      // { subColumns: 'Parent' },
                      // { subColumns: 'HSN/SAC' },
                      // { subColumns: 'GST Rate (%)' },
                      { subColumns: 'Action' }
                    ]} >
                      {dbData.map((item, j) => {
                        return (
                          <tr>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{moment(item.createdAt).format("DD/MM/YYYY")}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.typeOfUnit || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.formalName || `${item.details.firstUnit} of ${item.details.conversionOf} ${item.details.secoundUnit}` || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.symbol || "-"}</label></td>
                            <td ><label className="font-size-13 font-wt-400 text-break" >{item.details?.uqc || "-"}</label></td>
                            {/* {/* <td ><label className="font-size-13 font-wt-500 text-break" >{item.details?.hsnSac || "-"}</label></td> */}
                            <td
                              // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', display: showGridCol(filterData, wcQuotesColumn[6]["subColumns"]) }}
                              className='position-relative'>
                              <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                onClick={() => setAction({ show: true, index: j })}
                                aria-hidden="true"></i>
                              {action.show && action.index === j ? (
                                <Action
                                  id={j}
                                  onDismiss={() => setAction({ show: false, index: j })}
                                  options={[
                                    {
                                      name: "View/Update", onClick: () => {
                                        setData({ ...item, ...item.details })
                                        toggleCreateGroupView({ show: true, viewUpdate: true })
                                      }
                                    }, {
                                      name: "Delete", onClick: async () => {
                                        setshowLoader(true)
                                        await call("POST", 'deleteInventoryUnits', { id: item.id })
                                        setshowLoader(false)
                                        toastDisplay('Unit deleted', "success", () => {
                                          setRefresh(refresh + 1)
                                        })
                                      }
                                    }
                                  ]} />
                              ) : null}
                            </td>
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(InventoryUnits)