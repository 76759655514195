import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import call from '../../service';
import { FileInput } from '../../utils/FileInput';
import avatarUrl from '../../utils/makeAvatarUrl';
import MultipleSelect from '../../utils/MultipleSelect';
import { astrix, ClearCache, convertImageToPdf, dataURItoBlob, GetCache, getDocDetails, handleFileCommonFunction, most_used_currencies, multiSelectEventHandler, SetCache, toolTip } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect, NewTextArea } from '../../utils/newInput';
import { NewTable } from '../../utils/newTable';
import { StarRating } from '../../utils/starRating';
import toastDisplay from '../../utils/toastNotification';
import Header from '../partial/header';
import HeaderV2 from '../partial/headerV2';
import Sidebar, { traderTypeIds } from '../partial/sidebar';
import SideBarV2 from '../partial/sideBarV2';
import { PopupMessage } from '../popupMessage';
import config from '../../config.json';
import moment from 'moment';
import TutorialPopup, { TutorialVideoPopup } from '../tutorialPopup';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2';
import Filter from '../InvoiceDiscounting/components/Filter';
import NewTablev2, { showGridCol } from '../../utils/newTablev2';
import Pagination from '../InvoiceDiscounting/contract/components/pagination';
import { bgQuotesColumn, wcQuotesColumn } from '../Dashboard/TableJson';
import { Action } from '../myCounterPartComp/action';
import { FormProgressBar } from '../CommonComponent/FormProgressBar';
import FinanceInvoiceModal from '../InvoiceDiscounting/contract/components/financeinvoiceModal';
import { TRBackendServerIP, platformBackendUrl } from '../../urlConstants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const finHighlights = [
  { name: 'Selected', color: '#5CB8D3' },
  { name: 'Quote received', color: '#2ECC71' },
  { name: 'Rejected', color: '#FE4141' }
]

const AccountingLedgers = ({ userTokenDetails, navToggleState }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')
  let addNew = queryParams.get('addNew')

  const [dbData, setDbData] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [tableData, setTableData] = useState([])

  const [showAddBuyerForm, toggleAddBuyerForm] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [showLoader, setshowLoader] = useState(false)
  const [showMsgPopup, toggleMsgPopup] = useState(false)

  const [dunsData, setDunsData] = useState([])
  const [hsnCodes, setHsnCodes] = useState([])
  const [countrys, setCountrys] = useState([])
  const [currencyData, setcurrencyData] = useState(most_used_currencies);
  const [financierData, setFinancierData] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [lcTutorial, toggleLCTutorial] = useState(localStorage.getItem("lcTutorialStep") == 1)
  const [tutorialStage, setTutorialStage] = useState(0)
  const [showTutorialVideo, toggleTutorialVideo] = useState({ show: false, link: null })
  const [filterData, setFilterData] = useState(GetCache("accountingGroupsFilterData"))
  const [createGroupView, toggleCreateGroupView] = useState({ show: addNew ? true : false })
  const [ledgerUploadPopup, setLedgerUploadPopup] = useState({ show: false })

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [action, setAction] = useState({ show: false, index: null })
  const [parent, setParent] = useState([])
  const [states, setStates] = useState([])


  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [tablecol, setTablecol] = useState([
    { subColumns: 'Date', isChecked: true },
    { subColumns: 'Name', isChecked: true },
    { subColumns: 'Alias', isChecked: true },
    { subColumns: 'Parent', isChecked: true },
    { subColumns: 'Ledger Type', isChecked: true },
    { subColumns: 'Tax Type', isChecked: true },
    { subColumns: 'Action', isChecked: true }
  ])



  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  console.log(userPermissions, "Parsed user permissions");

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("tallyMastersAccountingLedger");
  console.log(subPermissions, "this is subpermission--//////");

  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");
    console.log(hasAddPermission, "this is add permission--->>>>>");

    // Update state only if there is a change
    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);

  console.log(subPermissions, "this is buyer management--->>>>>");
  console.log(booladd, "this is bool add--->>>>");



  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      currentPage: page,
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "status" || element.accordianId === "financiersFilter" || element.accordianId === "timeLeft") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
        else if (element.type === "minMaxDate") {
          objectAPI[element.accordianId] = element["value"]
        }
      }
    }
    call('POST', 'getAccountingLedgers', objectAPI).then((result) => {
      console.log('running getAccountingLedgers api-->', result);
      setDbData(result.data);
      setCount(result.countData);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getAccountingLedgers', e);
      setshowLoader(false)
    });
    SetCache("accountingGroupsFilterData", filterData)
    call('POST', 'getWebtelStatesMaster').then(res => {
      setStates(res)
    })
    call('get', 'getallCountry').then((result) => {
      setCountrys(result)
    }).catch((e) => {
      console.log('error in getallCountry', e);
    })
  }, [refresh, page, filterData])

  useEffect(() => {
    call('POST', 'getAccountingMastersParentGroups', { userId }).then(res => {
      setParent(res)
    })
  }, [])

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('wcApplicationId', selectedChat.wcApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const handleChange = async (event) => {
    event.persist()
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  async function handleValidation(tabIndex) {
    if (tabIndex != undefined) {
      if (tabIndex < tab) {
        return setTab(tabIndex)
      }
      else if (tabIndex == tab) {
        return null
      }
    }

    let err = {}
    if (tab / 1 == 0) {
      let validateFields = ["name", "parent"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = 'Mandatory field'
        }
      }
    }
    if (tab / 1 == 1 && data.gstApplicability === "\u0004 Applicable") {
      let validateFields = ["description", "HSNAppFrom", "hsnSac", "GSTAppFrom", "GSTRate", "calculationMethod", "typeOfSupply", "taxType"]
      for (let index = 0; index < validateFields.length; index++) {
        const element = validateFields[index];
        if (!data[element]) {
          err[element] = 'Mandatory field'
        }
      }
    }
    if (data.name && data.alias && data.name === data.alias) {
      err.alias = 'Name and Alias should not be the same';
    }

    if (!Object.keys(err).length) {
      if (tab / 1 == 4) {
        setshowLoader(true)
        call('POST', 'saveAccountingMastersLedger', {
          ...data, userId
        },
        ).then(res => {
          toastDisplay(data.id ? "Ledger Updated" : "Ledger Created", "success", () => {
            if (serarchParam || addNew) {
              window.opener.postMessage('RefreshData', window.location.origin);
              window.close()
            }
            setshowLoader(false)
            toggleCreateGroupView({ show: false })
            setData({})
            setTab(0)
            setRefresh(refresh + 1)
          })
        })
      }
      else {
        setTab(tab + 1)
      }
    }
    setErrors({ ...err })
  }

  async function processJSONFileObj() {
    setshowLoader(true)
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        let json = JSON.parse(e.target.result);
        json = json.Ledger
        if (json.length) {
          await call('POST', 'saveAccountingMastersLedger', { userId, tallyData: json })
          toastDisplay('Data uploaded successfully', "success", () => {
            setLedgerUploadPopup({ show: false })
            setRefresh(refresh + 1)
          })
        }
        else {
          toastDisplay('Data not found', "error")
        }
        setshowLoader(false)
      } catch (error) {
        console.error('ErrorInprocessJSONFileObj', error);
        toastDisplay('Something went wrong', "error")
        setshowLoader(false)
      }
    };
    reader.readAsText(data.tallyLedgerJsonFile);
  }

  useEffect(() => {
    console.log("data here", data)
  }, [data])
  const handleCreateNew = () => {

    if (booladd || userPermissions == null) {
      toggleCreateGroupView({ show: true })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }


  const ColumnSelector = ({ tablecol, setTablecol }) => {
    const [showModal, setShowModal] = useState(false);
    const [updatedData, setUpdatedData] = useState([...tablecol]);

    // Sync `updatedData` with `tablecol` only when modal is opened for the first time or `tablecol` changes
    useEffect(() => {
      if (showModal) {
        setUpdatedData([...tablecol]);
      }
    }, [showModal]);

    const handleToggleModal = () => {
      setShowModal(!showModal);
    };

    const handleCheck = (index) => {
      // Toggle the checked state of the column
      setUpdatedData((prevData) =>
        prevData.map((col, i) =>
          i === index ? { ...col, isChecked: !col.isChecked } : col
        )
      );
    };

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(updatedData);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setUpdatedData(items); // Update reordered columns
    };

    const handleSave = () => {
      // Persist `updatedData` to `tablecol`
      setTablecol([...updatedData]);
      setShowModal(false); // Close modal
    };

    return (
      <>
        <button
          type="button"
          className="btn column-btn font-size-14 font-wt-300"
          onClick={handleToggleModal}
          style={{
            border: "1px solid lightgrey",
            backgroundColor: "white",
            borderRadius: "10px",
            color: "gray",
            width: "13rem",
            paddingTop: "9px",
            paddingBottom: "9px"
          }}
        >
          Customize Columns
        </button>

        {showModal && (
          <div className="modal fade show" style={{ display: "block" }} role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content" style={{ width: "110%" }}>
                <div className="modal-header">
                  <h5 className="modal-title">Select Columns</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleToggleModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="modal-body">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {updatedData.map((ele, index) => (
                            <Draggable key={ele.subColumns} draggableId={ele.subColumns} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    className="mb-1"
                                    style={{
                                      border: "2px solid #84d4ef",
                                      padding: "8px",
                                      borderRadius: "0.5rem",
                                      backgroundColor: snapshot.isDragging
                                        ? "#d25de9"
                                        : "white",
                                      color: snapshot.isDragging ? "white" : "black",
                                      transition: "background-color 0.3s ease",
                                    }}
                                  >
                                    <img
                                      onClick={() => handleCheck(index)}
                                      className="cursor ml-8"
                                      src={`assets/images/${ele.isChecked ? "checked-green" : "empty-check"
                                        }.png`}
                                      style={{
                                        backgroundColor: "white",
                                        cursor: "pointer",
                                      }}
                                      alt={ele.isChecked ? "Checked" : "Unchecked"}
                                    />
                                    <label style={{ marginLeft: "0.5rem" }}>{ele.subColumns}</label>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="new-btn w-15 py-2 px-4 text-white cursor mr-1"
                    onClick={handleSave}
                    style={{ backgroundColor: "#15c2f4" }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleToggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };



  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="tallyMastersAccountingLedger" userTokenDetails={userTokenDetails} />
          <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={`Tally > Accounting Master > Ledger${createGroupView.show ? `${createGroupView.viewUpdate ? ' > View/Update' : ' > Create New'}` : ''}`}
              userTokenDetails={userTokenDetails} />
            {ledgerUploadPopup.show && (
              <FinanceInvoiceModal
                limitinvoice={ledgerUploadPopup.show}
                setLimitinvoice={() => { setLedgerUploadPopup({ show: false }) }}
                closeSuccess={() => { setLedgerUploadPopup({ show: false }) }}
              >
                <label className="text-center font-size-16 font-wt-600 w-100">Import From Tally</label>
                <label className="text-center font-size-13 font-wt-600 w-100 mb-4">Please upload the ledger JSON file exported using the tally TCP file.</label>
                <div className="d-flex justify-content-center">
                  <div className='col-10'>
                    <FileInput isEditable={true} name={"tallyLedgerJsonFile"} value={data.tallyLedgerJsonFile}
                      onChange={e => handleFileCommonFunction(e, data, errors, setData, setErrors, setshowLoader, ["json"])}
                      onUploadCancel={() => setData({ ...data, [`tallyLedgerJsonFile`]: null })}
                    />
                    {errors[`tallyLedgerJsonFile`] ? <div class="text-danger mt-2 font-size-12">
                      <i class="fa fas fa-exclamation-circle mr-1" aria-hidden="true"></i>
                      <b>{errors[`tallyLedgerJsonFile`]}</b></div> : ''}
                    <div className='d-flex justify-content-center mt-4'>
                      <button disabled={!data.tallyLedgerJsonFile?.name} className={`new-btn w-60 py-2 px-2 text-white cursor`}
                        onClick={processJSONFileObj}
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </FinanceInvoiceModal>
            )}

            <div className='mt-4' style={{}} >
              {createGroupView.show ? null : <div className='filter-div position-relative'>
                <Filter
                  filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                  showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}
                  isAdditionalButton>
                  <div className="d-flex gap-3">
                    <ColumnSelector tablecol={tablecol} setTablecol={setTablecol} />
                    {/* <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={() => setLedgerUploadPopup({ show: true })}>Import From Tally</button>
                    </div> */}
                    <div>
                      <button className={`new-btn  py-2 px-2 text-white cursor`} onClick={handleCreateNew}>Create New</button>
                    </div></div>
                </Filter>
              </div>}

              {createGroupView.show ?
                <>
                  <i
                    onClick={() => {
                      toggleCreateGroupView({ show: false })
                      setData({})
                      setTab(0)
                    }}
                    class="fas fa-2x fa-arrow-left mx-1 mb-4 icon-color cursor"></i>
                  <div className="bg-white pb-2">
                    <div className="d-flex justify-content-center pt-5 mb-1" >
                      <FormProgressBar
                        tabs={
                          [{ name: "Basic Details" }, { name: "Statutory Details" }, { name: "Mailing Details" }
                            , { name: "Banking Details" }, { name: "Tax Registration Details" }
                          ]} activeTab={tab} label={"name"} handleClick={(i, index) => { handleValidation(index) }}
                        separationWidth={'3rem'}
                      />
                    </div>
                    {tab / 1 == 0 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput isAstrix={true}
                          type={"text"} label={"Name"}
                          name={"name"} value={data.name} error={errors.name}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput type={"text"} label={"Alias"}
                          name={"alias"} value={data.alias} error={errors.alias}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <MultipleSelect singleSelection
                          Label={'Under'}
                          Id={`parent`}
                          isAstrix
                          optiondata={parent}
                          onChange={(e) => {
                            handleChange(multiSelectEventHandler(e, `parent`, "name"))
                          }}
                          value={data[`parent`] ? [data[`parent`]] : []}
                          name={`parent`}
                          labelKey={"name"}
                          valKey={"name"}
                          error={errors[`parent`]}
                        />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={[{ name: 'Not Applicable' }, { name: 'Discount' }, { name: 'Invoice Rounding' }]} label={"Type of Ledger"}
                          name={"type"} value={data.type} error={errors.type} optionLabel={"name"} optionValue={"name"}
                          onChange={handleChange} />
                      </div>
                    </div> : null}
                    {tab / 1 == 1 ? <div className="row d-flex px-4 py-5">
                      <label className='mb-3'>GST Rate & Related Details</label>
                      <div className="col-md-3">
                        <NewSelect
                          selectData={[
                            { name: '\u0004 Applicable' },
                            { name: '\u0004 Not Applicable' }
                          ]}
                          label="GST Applicability"
                          name="gstApplicability"
                          value={data.gstApplicability}
                          error={errors.gstApplicability}
                          optionLabel="name"
                          optionValue="name"
                          onChange={handleChange}
                        />
                      </div>
                      {data.gstApplicability === "\u0004 Applicable" ? <>
                        <div className="col-md-3">
                          <NewSelect selectData={[{ name: 'Exempt' }, { name: 'Nil Rated' }, { name: 'Non-GST' }, { name: 'Taxable' }]}
                            label={"Taxability Types"} name={"taxType"} value={data.taxType} error={errors.taxType} optionLabel={"name"} optionValue={"name"}
                            onChange={handleChange} isAstrix={true}
                          />

                        </div>
                        <div className="col-md-3">
                          <NewSelect selectData={[{ name: 'Goods' }, { name: 'Capital Goods' }, { name: 'Services' }]}
                            label={"Type Of Supply"} name={"typeOfSupply"} value={data.typeOfSupply} error={errors.typeOfSupply} optionLabel={"name"} optionValue={"name"}
                            onChange={handleChange} isAstrix={true}
                          />
                        </div>

                        <div className="col-md-3">
                          <NewSelect selectData={[{ name: 'Based on Quantity' }, { name: 'Based on Value' }]}
                            label={"Method of Calculation"} name={"calculationMethod"} value={data.calculationMethod} error={errors.calculationMethod} optionLabel={"name"} optionValue={"name"}
                            onChange={handleChange} isAstrix={true}
                          />
                        </div>
                        <div className="col-md-3">
                          <NewInput type={"number"}
                            isAstrix={true} value={data.GSTRate}
                            label={"GST Rate %"} name={"GSTRate"} selected={data.GSTRate} error={errors.GSTRate}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-3">
                          <NewInput type={"date"}
                            isAstrix={true}
                            value={data.GSTAppFrom}
                            label={"GST Applicable From"} name={"GSTAppFrom"} selected={data.GSTAppFrom} error={errors.GSTAppFrom}
                            onChange={handleChange} />
                        </div>

                        {/* <div className="col-md-3">
                          <NewSelect
                            selectData={[
                              { name: 'As per Company/Group' },
                              { name: 'Specify Details Here' },
                              { name: 'Use GST Classification' }
                            ]}
                            label="HSN/SAC Details"
                            name="hsnSacDetails"
                            value={data.hsnSacDetails}
                            error={errors.hsnSacDetails}
                            optionLabel="name"
                            optionValue="name"
                            onChange={handleChange}
                          />
                        </div> */}
                        {/* <div className="col-md-3">
                          <NewSelect
                            selectData={[
                              { name: 'As per Company/Group' },
                              { name: 'Specify Details Here' },
                              { name: 'Use GST Classification' }
                            ]}
                            label="GST Rate Details"
                            name="gstRateDetails"
                            value={data.gstRateDetails}
                            error={errors.gstRateDetails}
                            optionLabel="name"
                            optionValue="name"
                            onChange={handleChange}
                          />
                        </div> */}
                        {/* <div className="col-md-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Source of Details"
                            name="sourceOfDetails"
                            value={data.sourceOfDetails}
                            onChange={handleChange}
                          />
                        </div> */}
                        <label className='mb-3'>HSN/SAC & Related Details</label>

                        <div className="col-md-3">
                          <NewInput
                            isAstrix={true}

                            label={"HSN/SAC"} name={"hsnSac"} value={data.hsnSac} error={errors.hsnSac}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-3">
                          <NewInput type={"date"}
                            isAstrix={true}
                            value={data.HSNAppFrom}

                            label={"HSN Applicable From"} name={"HSNAppFrom"} selected={data.HSNAppFrom} error={errors.HSNAppFrom}
                            onChange={handleChange} />
                        </div>
                        <div className="col-md-3">
                          <NewInput
                            isAstrix={true}

                            label={"Description"} name={"description"} value={data.description} error={errors.description}
                            onChange={handleChange} />
                        </div>



                      </> : <></>}
                    </div> : null}
                    {tab / 1 == 2 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"Name"} name={"mailingName"} value={data.mailingName} error={errors.mailingName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Email Id"} name={"mailingEmailId"} value={data.mailingEmailId} error={errors.mailingEmailId}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Contact Number"} name={"mailingContactNo"} value={data.mailingContactNo} error={errors.mailingContactNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewTextArea
                          label={"Address"} name={"mailingAddress"} value={data.mailingAddress} error={errors.mailingAddress}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={states} optionLabel={"StateName"} optionValue={"StateName"}
                          label={"State"} name={"mailingState"} value={data.mailingState} error={errors.mailingState}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewSelect selectData={countrys} optionLabel={"name"} optionValue={"name"}
                          label={"Country"} name={"mailingCountry"} value={data.mailingCountry} error={errors.mailingCountry}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Pincode"} name={"mailingPinCode"} value={data.mailingPinCode} error={errors.mailingPinCode}
                          onChange={handleChange} />
                      </div>
                    </div> : null}
                    {tab / 1 == 3 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"Account Holder Name"} name={"bankACHolderName"} value={data.bankACHolderName} error={errors.bankACHolderName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Account No"} name={"bankACNo"} value={data.bankACNo} error={errors.bankACNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"IFSC Code"} name={"bankIFSC"} value={data.bankIFSC} error={errors.bankIFSC}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Swift Code"} name={"swiftCode"} value={data.swiftCode} error={errors.swiftCode}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Bank Name"} name={"bankName"} value={data.bankName} error={errors.bankName}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"Branch"} name={"bankBranchName"} value={data.bankBranchName} error={errors.bankBranchName}
                          onChange={handleChange} />
                      </div>
                      {data.parent?.includes("Bank Acc") ? <>
                        <div>
                          <label className='font-size-15 font-wt-600 my-3'>This bank account is connected to my RazorpayX account</label>
                          <img onClick={() => {
                            setData({ ...data, razorpayXConnected: !data.razorpayXConnected })
                          }} className='cursor mx-3' src={data.razorpayXConnected ? 'assets/images/checked-green.png' : 'assets/images/empty-check.png'} />
                        </div>
                        {data.razorpayXConnected ? <>
                          <div className="col-md-3">
                            <NewInput
                              label={"Razorpay Key Id"} name={"razorpayKeyId"} value={data.razorpayKeyId} error={errors.razorpayKeyId}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4">
                            <NewInput
                              label={"Razorpay Key Secret"} name={"razorpayKeySecret"} value={data.razorpayKeySecret} error={errors.razorpayKeySecret}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            {data.razorpayKeyId && data.razorpayKeySecret ?
                              <label className='font-size-15 font-wt-600 text-color-value'>{`Whitelist Tradereboot IP on RazorpayX - `}
                                <label className='font-size-15 font-wt-600 text-color-label'>{TRBackendServerIP}</label></label> : null}
                          </div>
                          <div className="col-md-3">
                            {data.razorpayKeyId && data.razorpayKeySecret ?
                              <label className='font-size-15 font-wt-600 text-color-value'>{`Add below URL in webhook on RazorpayX - `}
                                <label className='font-size-15 font-wt-600 text-color-label'>{`${platformBackendUrl}/razorpayx`}</label></label> : null}
                          </div></> : null}
                        <div>
                          <label className='font-size-15 font-wt-600 my-3'>This bank account is connected to my OpenMoney account</label>
                          <img onClick={() => {
                            setData({ ...data, openMoneyConnected: !data.openMoneyConnected })
                          }} className='cursor mx-3' src={data.openMoneyConnected ? 'assets/images/checked-green.png' : 'assets/images/empty-check.png'} />
                        </div>
                        {data.openMoneyConnected ? <>
                          <div className="col-md-3">
                            <NewInput
                              label={"OpenMoney Key Id"} name={"openMoneyKeyId"} value={data.openMoneyKeyId} error={errors.openMoneyKeyId}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-4">
                            <NewInput
                              label={"OpenMoney Key Secret"} name={"openMoneyKeySecret"} value={data.openMoneyKeySecret} error={errors.openMoneyKeySecret}
                              onChange={handleChange} />
                          </div>
                          <div className="col-md-3"></div>
                          <div className="col-md-3">
                            {data.openMoneyKeyId && data.openMoneyKeySecret ?
                              <label className='font-size-15 font-wt-600 text-color-value'>{`Add below URL in webhook on OpenMoney - `}
                                <label className='font-size-15 font-wt-600 text-color-label'>{`${platformBackendUrl}/openmoney`}</label></label> : null}
                          </div>
                        </> : null}
                      </> : null}
                    </div> : null}
                    {tab / 1 == 4 ? <div className="row d-flex px-4 py-5">
                      <div className="col-md-3">
                        <NewInput
                          label={"PAN/IT No"} name={"panITNo"} value={data.panITNo} error={errors.panITNo}
                          onChange={handleChange} />
                      </div>
                      <div className="col-md-3">
                        <NewInput
                          label={"GSTIN/UIN"} name={"GSTINUIN"} value={data.GSTINUIN} error={errors.GSTINUIN}
                          onChange={handleChange} />
                      </div>
                    </div> : null}

                    <div className='d-flex justify-content-center'>
                      <button type="button"
                        onClick={() => {
                          handleValidation(undefined)
                        }}
                        className={` new-btn w-15 py-2 px-2 text-white mb-5`}>
                        {tab / 1 == 4 ? (createGroupView.viewUpdate ? "Update" : "Submit") : "Next"}
                      </button>
                    </div>

                  </div></> :
                <>
                  <div>
                    <NewTablev2 filterData={filterData} columns={tablecol.filter(col => col.isChecked)} >
                      {dbData.map((item, j) => {
                        return (
                          <tr key={j}>
                            {tablecol.map((col) => {
                              if (col.isChecked) {
                                return (
                                  <td key={col.subColumns}>
                                    {col.subColumns === "Date" && (<label className="font-size-13 font-wt-400 text-break" >{moment(item.createdAt).format("DD/MM/YYYY")}</label>)}
                                    {col.subColumns === "Name" && (<label className="font-size-13 font-wt-400 text-break" >{item.details?.name}</label>)}
                                    {col.subColumns === "Alias" && (<label className="font-size-13 font-wt-400 text-break" >{item.details?.alias || "-"}</label>)}
                                    {col.subColumns === "Parent" && (<label className="font-size-13 font-wt-400 text-break" >{item.details?.parent}</label>)}
                                    {col.subColumns === "Ledger Type" && (<label className="font-size-13 font-wt-400 text-break" >{item.details?.type || "-"}</label>)}
                                    {col.subColumns === "Tax Type" && (<label className="font-size-13 font-wt-400 text-break" >{item.details?.taxType || "-"}</label>)}
                                    {col.subColumns === "Action" && (<label
                                      // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', display: showGridCol(filterData, wcQuotesColumn[6]["subColumns"]) }}
                                      className='position-relative'>
                                      <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                        onClick={() => setAction({ show: true, index: j })}
                                        aria-hidden="true"></i>
                                      {action.show && action.index === j ? (
                                        <Action
                                          id={j}
                                          onDismiss={() => setAction({ show: false, index: j })}
                                          options={[
                                            {
                                              name: "View/Update", onClick: () => {
                                                setData({ ...item.details, id: item.id })
                                                toggleCreateGroupView({ show: true, viewUpdate: true })
                                              }
                                            }, {
                                              name: "Delete", onClick: async () => {
                                                setshowLoader(true)
                                                await call("POST", 'deleteAccountingLedger', { id: item.id })
                                                setshowLoader(false)
                                                toastDisplay('Ledger deleted', "success", () => {
                                                  setRefresh(refresh + 1)
                                                })
                                              }
                                            }
                                          ]} />
                                      ) : null}
                                    </label>)}
                                  </td>
                                )
                              }
                              return null
                            })}
                          </tr>
                        )
                      })}
                    </NewTablev2>
                  </div>
                  <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />

                </>}

            </div>
          </main>
        </div >
      </div >

    </>)

}

const mapStateToProps = state => {

  return {
    navToggleState: state.navToggleState,
    // channelPartnerAccountList: state.channelPartnerAccountList,
    // channelPartnerDisbursedInvoice: state.channelPartnerDisbursedInvoice,
    // channelPartnerRaisedInvoice: state.channelPartnerRaisedInvoice
  }
}

export default connect(
  mapStateToProps,
  null
)(AccountingLedgers)