import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import Header from '../partial/header';
import LaytimeSheetTrail from '../laytimeSheets/laytimeSheetTrail';
import ContractDoc from '../contractDocument/contractDoc';
import { setContractDetails, setContractDocList, setViewTrack, setInvoiceDetail } from '../../store/actions/action';
// import Invoice from '../invoice/invoice';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import { formatDate_Application } from '../../utils/dateFormaters';
import InvestigationReportForm from '../investigationReport/investigationReportForm'
import InsuranceDetailAccord from '../insurance/insuranceDetailAccord'
import { QRCode } from 'react-qrcode-logo';
import LCPaymentProcess from '../bank/bankLCProcess/lcProcess'
import DaDpPaymentProcess from '../bank/bankDaDpProcess/dadpProcess'
import TTPaymentProcess from '../bank/ttPaymentProcess/ttProcess'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import BillOfLading from '../logistics/billOfLading.js'
import avatarUrl from '../../utils/makeAvatarUrl';
import Tracking from '../contractDetails/tracking.js';
import ContractTrail from '../contractDetails/contractTrail.js';
import ShipmentDetailAccord from '../contractDetails/shipmentDetailAccord.js';
import BlockchainTrail from '../contractDetails/blockchainTrail.js';
import QmsWindow from '../contractDetails/qms.js';
import ContractLogs from '../contractDetails/contractLogs.js';
import HeaderV2 from '../partial/headerV2.js';
import SideBarV2 from '../partial/sideBarV2.js';
import { useHistory } from "react-router";
import WorkOrderContractDocuments from './WorkOrderContractDocuments.js';
import ExtraDetailsForLimitApplication from '../InvoiceDiscounting/extraDetailsForLimitApplication.js';
import ChatBoxPopUp2 from '../chatRoom/components/ChatBoxPopUp2.js';
import { getDocDetails } from '../../utils/myFunctions.js';
import BankReimburseForm from '../bank/ttPaymentProcess/bankReimburseForm.js';
import { Accordion, Card, Button } from 'react-bootstrap'
import TradeCreditInsuarance from '../InsuaranceNew/TradeCreditInsuarance.js';
import toastDisplay from '../../utils/toastNotification.js';
import ShipmentBookingMarketPlace from '../ShipmentBooking/ShipmentBookingMarketPlace.js';
import ApplyForBooking from '../ShipmentBooking/ApplyForBooking';
import { useRef } from 'react';
import ApplyLimitComponentV2 from '../InvoiceDiscounting/applyLimitComponentV2.js';

// import { userPlanQuotaFetch } from '../../utils/myFunctions.js';
import Oc from "./oc";
import Invoice from "./invoice";
import ApplyLimitSCF from '../SupplyChainFinance/ApplyLimitSCF.js';
import LcdiscountingcardV2 from '../lcV2/applyforLimit/components/lcdiscountingcardV2.js';
import ELC from '../Edocs/ELC.js';
import Quotes from '../lcV2/qoutes/quotes.js';

const creditInsMenu = [
  {
    Icon: "assets/images/whole_turnover.svg",
    Heading: "Whole Turnover",
    Link: "/tciWholeTO",
    value: "whole_turnover"
  },
  {
    Icon: "assets/images/key_accounts.svg",
    Heading: "Key accounts",
    Link: "/tciKeyAccounts",
    value: "key_accounts"
  },
  {
    Icon: "assets/images/single_buyer.svg",
    Heading: "Single Buyer",
    Link: "/tciSingleBuyer",
    value: "single_buyer"
  },
  {
    Icon: "assets/images/transactional.svg",
    Heading: "Transactional",
    Link: "/tciTransactional",
    value: "transactional"
  }
];
const WorkOrderContractDetaills = ({ userTokenDetails, contractDocList, setContractDocList, setViewTrack, ViewTrack, setInvoiceDetail, InvoiceDetail, clientType, navToggleState, dispatch }) => {

  const queryParams = new URLSearchParams(window.location.search)
  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  let cDetailsState = {
    cId: queryParams.get("contractNo")
  }
  const cId = cDetailsState.cId
  const [dbData, setDbData] = useState({})
  const [planData, setplanData] = useState({})
  const [showLoader, setshowLoader] = useState(false);
  const [showQms, setShowQms] = useState(false);
  const [tab, setTab] = useState(0);
  const [tabName, setTabName] = useState("Summary");
  const [contractClientType, setContractClientType] = useState(null)
  const [contractDoc, toggleContractDoc] = useState(false)
  const [chatList, setChatList] = useState([])
  const [message, setTextMsg] = useState('')
  const [toggle2, settoggle2] = useState(false)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [refresh, setRefresh] = useState(0)
  const [conNo, setconNo] = useState()
  const aclMap = userTokenDetails.aclMap ? userTokenDetails.aclMap : {}
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null

  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  // const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  let history = useHistory()
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => {

    //------------------------------------------------------------------
    let cType = (userTypeId === 3 || (userTypeId === 19 && clientType.type === 'buyer')) ? "buyer" : (userTypeId === 4 || (userTypeId === 19 && clientType.type === 'seller')) ? "seller" : ""
    //------------------------------------------------------------------

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)
    call('POST', 'getcontractdetails', { "contract_number": cId, "clientType": cType }).then(async (result) => {
      console.log("getcontractdetails ->", result)
      setDbData(result[0])
      setconNo(result[0].contract_number)
      setshowLoader(false)
      setContractClientType(result[0]["importer_id"] / 1 == userId / 1 ? "buyer" : 'seller')
    }).catch((error) => {
      console.log("error occur in getContracts ->", error)
    })

    call('get', `v1/contract/plan?contractNumber=${cId}`).then(async (result) => {
      // console.log("getcontractplan ->", result)
      setplanData(result.planDetails.features)
      setshowLoader(false)
    }).catch((error) => {
      console.log("error occur in getcontractplan ->", error)
    })

    //------------------------------------------------------------------

  }, [InvoiceDetail.info.refresh, cDetailsState.cId]);
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // Functions
  //---------------------------------------------------------------------------------------------------------------------

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('contractApplicationId', selectedChat.contractApplicationId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  //---------------------------------------------------------------------------------------------------------------------
  // Body Consoles
  console.log("getcontractplan planData->", planData)
  // console.log('contractDocList.contractId', contractDocList.contractId);
  // console.log('ViewTrack.modal', ViewTrack.modal);
  // console.log('InvoiceDetail.modal', InvoiceDetail.modal);
  //console.log('InvoiceDetail', InvoiceDetail);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  let bgQuoteDetails = JSON.parse(localStorage.getItem("bgQuoteDetails"))
  // useEffect(() => {
  //   if (tab === 4) {


  //     let objectAPI = {
  //       "userId": userId,
  //       "userEmail": userEmail,
  //       "userTypeId": userTypeId,
  //       "type": 'all',
  //     };

  //     call('POST', 'getTCIQuoteList', objectAPI).then((result) => {
  //       console.log('Running getTCIQuoteList API -->', result);

  //     }).catch((e) => {
  //       console.log('Error in getTCIQuoteList', e);
  //       setshowLoader(false);
  //     });
  //   }
  // }, [])

  const [inNo, setInNo] = useState(null)

  const allDeniedCount = useRef(0);
  const apporvedCount = useRef(0);
  let boolval = useRef(false);
  const [inAccept, setInAccept] = useState(false)
  const [inApproved, setInApproved] = useState(false)
  const [objFound, setObjFound] = useState([])
  useEffect(() => {
    console.log("dbData in useEffect:", dbData);

    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      "cno": conNo?.toString().trim()
    };
    console.log(objectAPI, "objectapi-----")
    call('POST', 'getTCIQuoteList', objectAPI).then((result) => {
      console.log('Running getTCIQuoteList API:', result);

      // Assuming the result contains an array of data
      const dataArray = Array.isArray(result?.data) ? result.data : [];
      setObjFound(dataArray)
      console.log(dataArray, "data array-->>>>>>>>>>>>")
      // Ensure conNo is properly formatted and compare with details.bulkcreate
      const conNoTrimmed = conNo?.toString().trim();

      // Find the object where details.bulkcreate matches the contract number

      if (dataArray.length > 0) {
        setInNo(dataArray[0].details.bulkcreate);
        console.log('Matched object:', dataArray);  // Found the matching object

        let selectedLenderName = dataArray[0].selectedLenderName ? dataArray[0].selectedLenderName.split(",") : []
        console.log(allDeniedCount / 1 == selectedLenderName?.length / 1, "ans this ")
        if (allDeniedCount / 1 == selectedLenderName?.length / 1) {
          setInAccept(false)
        } else {
          setInAccept(true)
        }
        // Check if financierQuotes exist
        if (dataArray[0].financierQuotes) {
          let financierQuotesArray;

          // Parse only if it's a valid JSON string
          try {
            financierQuotesArray = JSON.parse(dataArray[0].financierQuotes);
          } catch (error) {
            console.error('Error parsing financierQuotes:', error);
            financierQuotesArray = [];
          }

          // Iterate through the financier quotes
          financierQuotesArray.forEach(quote => {
            if (quote.financierAction === "deny") {
              allDeniedCount.current += 1;
            } else {
              apporvedCount.current += 1;
            }
          });
        }
        if (apporvedCount) {
          setInApproved(true)
        }
        // Update boolval based on financierQuotes and selectedFinancier
        boolval.current = !dataArray[0].financierQuotes || dataArray[0].selectedFinancier;
      } else {
        console.log('No matching object found.');  // No match
        setInNo(null)
      }

    }).catch((e) => {
      console.error('Error in getTCIQuoteList:', e);
      setshowLoader(false);
    });


  }, [conNo, inNo]);

  console.log(conNo, "contrcat no---->>>>>>>>>", boolval)

  console.log(dbData, "this is dbdata--->>>>", inNo, "this is in no----------")
  console.log(boolval, "this is boolvallll here ---????", apporvedCount, "this is approved count", allDeniedCount, "denied count---?")

  const [shipmentFound, setShipmentFound] = useState([])
  const [shipmentObj, setshipmentObj] = useState({})
  const [boolshipmentBooked, setShipmentBooked] = useState(false)
  const [shipmentapprove, setshipmentApprove] = useState(false)
  const [booldraftLC, setbooldraftLC] = useState(false)

  useEffect(() => {
    let objectAPI = {
      "userId": userId,
      // "userEmail": userEmail,
      // "userTypeId": userTypeId,
      // "type": 'all',
      "contractNo": cId
    }
    call('POST', 'getShipmentBookingQuoteList', objectAPI).then((res) => {
      console.log(res, "dats resss in shipment")
      setShipmentFound(res.data)
    })

    // let allDeniedCount = 0
    // let apporvedCount = 0
    // let selectedShipperName = shipmentFound[0].selectedShipperName ? shipmentFound[0].selectedShipperName.split(",") : []
    // if (shipmentFound[0].shipperQuotes) {
    //   for (let index = 0; index < JSON.parse(shipmentFound[0].shipperQuotes).length; index++) {
    //     const j = JSON.parse(shipmentFound[0].shipperQuotes)[index];
    //     if (j.shipperAction === "deny") {
    //       allDeniedCount += 1
    //     }
    //     else {
    //       apporvedCount += 1
    //     }
    //   }
    // }
    // if (allDeniedCount / 1 == selectedShipperName?.length / 1) {
    //   setshipmentApprove(false)
    // } else if (apporvedCount) {
    //   setshipmentApprove(true)
    // }
    // window.location = `/ShipmentBookingMarketPlace?applicationNo=${encodeURIComponent(shipmentFound[0].applicationNo)}`;
    if (shipmentFound && shipmentFound.length > 0 && shipmentFound[0] !== undefined) {
      localStorage.setItem("ShipmentQuoteDetails", JSON.stringify(shipmentFound[0]));
    } else {
      console.warn("No valid shipment data found in response.");
    }
  }, [])

  console.log(shipmentFound[0], "shipment found at the end---?????")
  console.log(cId, "this is contract no")

  const [boolLc, setboolLc] = useState([])

  useEffect(() => {
    let objectAPI = {
      userId: userId,
      userEmail: userEmail,
      userTypeId: userTypeId,
      contractNo: cId,
      onlyLC: true,

    };
    call("POST", "getEdocs", objectAPI)
      .then(async (result) => {
        console.log("running getEdocs api-->", result);
        setboolLc(result.data)
      })
  }, [])

  const [selectedDocType, setSelectedDocType] = useState(null);
  const [boolbulk, setboolBulk] = useState(true)
  const [boolinvoice, setinvoice] = useState(false)

  // const [supplierId, setSupplierId] = useState()
  // const fetchSuppliers = async () => {
  //   try {
  //     const res = await call('POST', 'getSuppliersInNetworkForBulk', { email: dbData.buyer_email, userId });
  //     console.log("Response from getSuppliersInNetworkForBulk:", res);

  //     if (res?.buyerData?.length > 0) {
  //       const supplierInfo = res.buyerData[0];
  //       console.log("Setting buyerContract with supplier data:", supplierInfo);
  //       setSupplierId(res.buyerData[0].id)

  //     } else {
  //       console.log("No supplier data found in network.");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching suppliers:", err);
  //   }
  // };
  // useEffect(() => {

  //   fetchSuppliers(); // Initial API call

  // }, [dbData.buyer_email]);

  const [boolscfpresent, setboolscfPresent] = useState(false);
  let approvedcountscf = useRef(0);
  let deniedcountscf = useRef(0);
  const [scfStatus, setscfStatus] = useState("Not Available");
  const [scftermsheet, setscftermsheet] = useState(false)
  const [scfdata, setscfdata] = useState({})

  useEffect(() => {
    // Reset counts
    approvedcountscf.current = 0;
    deniedcountscf.current = 0;

    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all',
      "bulkcreate": cId
    };

    call('POST', 'getSCFQuoteList', objectAPI)
      .then((result) => {
        console.log('Running getSCFQuoteList API:', result);





        if (result.data.length > 0) {
          setboolscfPresent(true);
          setscfdata(result.data[0]);

          const dateTime = result.data[0].termSheetSignedByFinancerOn;

          if (dateTime) {
            const dateObj = new Date(dateTime);

            if (!isNaN(dateObj.getTime())) { // Check if dateObj is valid
              const date = dateObj.toISOString().split("T")[0];
              const time = dateObj.toISOString().split("T")[1].split(".")[0];

              console.log("Date:", date); // Output: Date: YYYY-MM-DD
              console.log("Time:", time); // Output: Time: HH:MM:SS
            } else {
              console.log("Invalid date format in termSheetSignedByFinancerOn");
            }
          } else {
            console.log("termSheetSignedByFinancerOn is null or undefined");
          }

          const { financierQuotes, selectedLenderName, termSheet, termSheetLink, termSheetSignedByExporter } = result.data[0];

          if (termSheet || termSheetLink) {
            if (termSheetSignedByExporter) {
              setscftermsheet(true);
            }
          }

          if (financierQuotes) {
            const parsedQuotes = JSON.parse(financierQuotes);
            console.log("Parsed Financier Quotes:", parsedQuotes);

            parsedQuotes.forEach((quote) => {
              if (quote.financierAction === "deny") {
                deniedcountscf.current += 1;
              } else {
                approvedcountscf.current += 1;
              }
            });

            console.log("Approved count:", approvedcountscf.current);
            console.log("Denied count:", deniedcountscf.current);
          } else {
            console.log("No financier quotes found");
          }

          const selectedLenders = selectedLenderName ? selectedLenderName.split(",") : [];
          console.log("Selected Lender Names:", selectedLenders);
          console.log("Selected Lender Count:", selectedLenders.length);

          if (approvedcountscf.current > 0) {
            console.log("Setting SCF Status to Approved");
            setscfStatus("Approved");
          } else if (deniedcountscf.current === selectedLenders.length) {
            console.log("Setting SCF Status to Rejected");
            setscfStatus("Rejected");
          } else {
            console.log("Setting SCF Status to Inprogress");
            setscfStatus("Inprogress");
          }
        } else {
          console.log("No data in result");
          setscfStatus("No Quotes Available");
        }


        setshowLoader(false);
      })
      .catch((e) => {
        console.log('Error in getSCFQuoteList:', e);
        setshowLoader(false);
      });
  }, []);



  console.log(scfStatus, "This is SCF status", scfdata, "this is scfdata--->>>>");
  console.log(booldraftLC, "bool draft lc--->>>")

  return (
    <>
      {contractDoc ?
        <div className="container-fluid">
          <div className="row">
            <SideBarV2 state="contractManagement" userTokenDetails={userTokenDetails} />
            <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
              <HeaderV2
                title={userTypeId === 8 ? 'Workorder Contracts > Case Details' : 'Workorder Contracts > Contract Details'}
                userTokenDetails={userTokenDetails}
              />
              <div className='py-4 px-3 card mt-5'>
                <a style={{ top: '-2.8rem', zIndex: 10, left: 0 }} className="cursor position-absolute mb-3"
                  onClick={() => {
                    toggleContractDoc(false)
                    setContractDocList(null)
                  }}
                > <img className="" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                <ExtraDetailsForLimitApplication
                  showContractPO
                  showContractInvoice
                  showContractAgreement
                  showShipmentDocs
                  contractClientType={contractClientType} userTokenDetails={userTokenDetails}
                  hideHeader={true} hideSideBar={true}
                  hideSubmitDiv={true} hideBackArrow={true}
                  expandAll={true}
                  cardBorder0={true} cardPadding0={true}
                  showContinueButton={true} continueBtnLabel={"Save"}
                  loadDataInWriteModeFromCacheIfPresent={true}
                // continueBtnCSS={{ opacity: 0, height: 0 }}
                />
              </div>
            </main>
          </div >
        </div >
        // <WorkOrderContractDocuments contractClientType={contractClientType} userTokenDetails={userTokenDetails} planData={planData} /> 
        :
        (!contractDoc && ViewTrack.modal && !InvoiceDetail.modal) ? <Tracking userTokenDetails={userTokenDetails} /> :
          (!contractDoc && !ViewTrack.modal && InvoiceDetail.modal) ? <Invoice navToggleState={navToggleState} userTokenDetails={userTokenDetails} planData={planData} /> :
            <div className="container-fluid">
              <div className="row">
                <SideBarV2 state="workOrderContracts" userTokenDetails={userTokenDetails} />
                <main role="main" className={"ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
                  <HeaderV2
                    title={userTypeId === 8 ? 'Workorder Contracts > Case Details' : 'Workorder Contracts > Contract Details'}
                    userTokenDetails={userTokenDetails}
                  />
                  <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />


                  <div className="d-md-flex">
                    {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                    <ul className="contract-left position-relative">
                      <a style={{ top: '1.2rem' }} className="cursor position-absolute"
                        onClick={() => { history.go(-1) }}
                      ><img className="mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <li className='pl-4'>
                        <p className='font-size-15 font-wt-500 text-color-0C0C0C'>Contract #{dbData.contract_number ? dbData.contract_number + " : " + tabName : '--'}</p>
                        <p className='font-size-13 font-wt-400'>{dbData.created_at ? formatDate_Application(dbData.created_at) : '--'}</p>
                      </li>
                    </ul>
                    <div className="ml-auto pr-3">
                      <ul className="contract-top d-flex flex-md-row align-items-center">

                        {/* {(aclMap.contract_access && aclMap.contract_access.cont_viewDoc && aclMap.contract_access.cont_viewDoc.isExist) && */}
                        <li>
                          <button type="button" className="btn bg-1ea3ae text-white btn-sm " onClick={() => {
                            setContractDocList(dbData.contract_number);
                            toggleContractDoc(true)
                            setViewTrack(false, {});
                          }}>Contract Documents</button>
                        </li>
                        {/* } */}

                        {<>
                          <DropdownButton id="dropdownInvoice" title="Invoice" variant="" size="sm">
                            {(dbData.invoice_status === 0 && dbData.exporter_id === userId) ? <>
                              {/* {
                                <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                  setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Generate" })
                                }}>
                                  Generate Invoice
                                </Dropdown.Item>} */}
                              {
                                <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                  setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "Upload" })
                                }}>
                                  Upload Invoice
                                </Dropdown.Item>}


                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedDocType(selectedDocType === "Order Confirmation" ? null : "Order Confirmation");
                                }}
                              >
                                Order Confirmation
                              </Dropdown.Item>

                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedDocType(selectedDocType === "Invoice" ? null : "Invoice");
                                }}
                              >
                                Invoice
                              </Dropdown.Item>
                            </> :
                              (dbData.invoice_status === 1 && dbData.exporter_id === userId) ?
                                <>
                                  {
                                    <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                      setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "ReGenerate" })
                                    }}>
                                      Re-Generate Invoice
                                    </Dropdown.Item>}
                                </> : (dbData.invoice_status === 0 && dbData.importer_id === userId) ?
                                  <Dropdown.Item>NA</Dropdown.Item> : ""}
                            {dbData.invoice_status === 1 &&
                              <Dropdown.Item href="javascript:void(0)" onClick={() => {
                                setInvoiceDetail(true, { "contractNo": cId, "userType": userTypeId, "userId": userId, "invoiceType": "contract", "operation": "View" })
                              }}>
                                View Invoice
                              </Dropdown.Item>}
                          </DropdownButton>
                        </>}

                      </ul>
                    </div>
                  </div>
                  {!selectedDocType &&
                    <ul className="nav nav-tabs-custom align-items-end" id="myTab" role="tablist">
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 0 ? " formActiveTab show " : "")} onClick={() => {
                            setTab(0);
                            setTabName("Summary");
                          }}>Summary</a>
                      </li>
                      {
                        <li>
                          <a
                            style={{ width: '9.4rem' }}
                            className={"nav-link formTab px-4 cursor-pointer " + (tab === 1 ? " formActiveTab show" : "")} onClick={() => {
                              setTab(1);
                              setTabName("Bank Process");
                            }}>Payment Details</a>
                        </li>}

                      {/* {<li>
                      <a
                        style={{ width: '9.4rem' }}
                        className={"nav-link formTab px-4 cursor-pointer "} onClick={() => {

                          window.location = `/edocsLC?draftlc=${cId}`;

                        }}>Draft LC</a>
                    </li>} */}

                      {/* {
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 2 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(2);
                            setTabName("Bank Process");
                          }}>Finance</a>
                      </li>} */}

                      {
                        <li>
                          <a
                            style={{ width: '9.4rem' }}
                            className={"nav-link formTab px-4 cursor-pointer " + (tab === 3 ? " formActiveTab show" : "")} onClick={() => {
                              setTab(3);
                              setTabName("Logistics");
                            }}>Logistics</a>
                        </li>}
                      {
                        <li>
                          <a
                            style={{ width: '9.4rem' }}
                            className={"nav-link formTab px-4 cursor-pointer " + (tab === 4 ? " formActiveTab show" : "")} onClick={() => {
                              setTab(4);
                              setTabName("Insurance");
                            }}>Insurance</a>
                        </li>}

                      {
                        <li>
                          <a
                            style={{ width: '9.4rem' }}
                            className={"nav-link formTab px-4 cursor-pointer " + (tab === 5 ? " formActiveTab show" : "")} onClick={() => {
                              setTab(5);
                              setTabName("Finance");
                            }}>Finance</a>
                        </li>}

                      {/* {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 2 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(2);
                            setTabName("Investigation Process");
                          }}>Investigation</a>
                      </li>} */}
                      {/* {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 3 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(3);
                            setTabName("Insurance Process");
                          }}>Insurance </a>
                      </li>} */}
                      {/* {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) && (planData.shipmentTracking && planData.shipmentTracking.enable) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 4 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(4);
                            setTabName("Shipment Details");
                          }}>Shipment</a>
                      </li>} */}

                      {/* {(aclMap.contract_access && (aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist)) &&
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 5 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(5);
                            setTabName("Bill Of Lading");
                          }}>B/L Process</a>
                      </li>} */}

                      {/* {(aclMap.contract_access && aclMap.contract_access.cont_bcAudit && aclMap.contract_access.cont_bcAudit.isExist) && */}
                      {/* <li>
                      <a
                        style={{ width: '10rem' }}
                        className={"nav-link formTab px-4 cursor-pointer " + (tab === 6 ? " formActiveTab show" : "")} onClick={() => {
                          setTab(6);
                          setTabName("Blockchain Audit");
                        }}>Blockchain Audit</a>
                    </li> */}

                      {/* {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) && */}
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 7 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(7);
                            setTabName("Contract Logs");
                          }}>Process Cycle</a>
                      </li>
                      <li>
                        <a
                          style={{ width: '9.4rem' }}
                          className={"nav-link formTab px-4 cursor-pointer " + (tab === 8 ? " formActiveTab show" : "")} onClick={() => {
                            setTab(8);
                            setTabName("Change logs");
                          }}>Change logs</a>
                      </li>
                      {/* } */}
                    </ul>
                  }

                  {!selectedDocType && !booldraftLC && <div className="card mt-1 p-3"
                  >
                    {tab === 0 &&
                      <>
                        <div className="tab-pane active show">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row mt-2">
                                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                  {/* Importer Section */}
                                  <div className="form-group">
                                    <label className="font-size-14 font-wt-500 ml-20" style={{ marginLeft: "2rem" }}>Importer</label>
                                    <div className="chat-message pl-2">
                                      {/* Image Row */}
                                      <div className="image-row">
                                        <img src={avatarUrl(dbData.buyeravatar)} alt="Avatar" width="32" height="32" />
                                      </div>
                                      {/* Text Row */}
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-color-value font-size-14 font-wt-400">
                                          {dbData.buyername ? dbData.buyername : '--'}
                                        </span><br />
                                      </div>

                                    </div>
                                  </div>


                                  {/* <hr style={{ width: "60rem" }} /> */}

                                  <div className="d-md-flex align-content-around flex-wrap mb-3 contract-details" style={{ width: "80vw" }}>
                                    <div className="card-panel custom-align-panel-h" style={{ verticalAlign: "bottom", marginBottom: "-3rem" }}>
                                      <ul>
                                        <li>
                                          <h3 style={{ paddingLeft: "2rem" }}>{dbData.commodity_name ? dbData.commodity_name.toUpperCase() : ""}</h3>
                                        </li>
                                        {(userTypeId === 19 || userTypeId === 3 || userTypeId === 4) && <>
                                          <li>
                                            <h3 style={{ paddingLeft: "2rem" }}>{dbData.currency ? dbData.currency.split(':')[1] : ''} {dbData.price ? dbData.price : '--'}</h3>
                                          </li>
                                          <li>
                                            <h3 style={{ paddingLeft: "2rem" }}>{dbData.quantity ? dbData.quantity : ''} {dbData.commodityUnit}</h3>
                                          </li>
                                        </>}
                                        {/* <li>
                                          <h3 style={{ paddingLeft: "2rem" }}>{dbData.incoTerm ? dbData.incoTerm : ''} </h3>
                                        </li> */}
                                        <li>
                                          <h3 style={{ paddingLeft: "4rem" }}>
                                            {dbData.load_country ? <span className="shadow">
                                              <ReactCountryFlag
                                                countryCode={dbData.load_country.split(':')[0]}
                                                style={{ width: '25px', height: '25px' }} svg />
                                            </span> : ''}
                                          </h3>
                                        </li>
                                        <li>
                                          <h3 style={{ paddingLeft: "4rem" }}>{dbData.loading_port ? dbData.loading_port : '--'}</h3>
                                        </li>
                                        <li>
                                          <h3 style={{ paddingLeft: "4rem" }}>
                                            {dbData.unload_country ? <span className="shadow">
                                              <ReactCountryFlag
                                                countryCode={dbData.unload_country.split(':')[0]}
                                                style={{ width: '25px', height: '25px' }} svg />
                                            </span> : ''}
                                          </h3>
                                        </li>
                                        <li>
                                          <h3 style={{ paddingLeft: "4rem" }}>{dbData.unloading_port ? dbData.unloading_port : "-"}</h3>
                                        </li>
                                        <li>
                                          <h3 style={{ paddingLeft: "2rem" }}>{dbData.laycan_start ? formatDate_Application(dbData.laycan_start) : '--'}</h3>
                                        </li>
                                        <li>
                                          <h3 style={{ paddingLeft: "2rem" }}>{dbData.laycan_end ? formatDate_Application(dbData.laycan_end) : '--'}</h3>
                                        </li>
                                      </ul>
                                    </div>

                                    {/* Single hr spanning across */}
                                    <hr style={{ width: "65rem" }} />

                                    <div className="card-panel custom-align-panel" style={{ marginTop: "-2rem" }}>
                                      <ul>
                                        <li>
                                          <p>Commodity</p>
                                        </li>
                                        {(userTypeId === 19 || userTypeId === 3 || userTypeId === 4) && <>
                                          <li>
                                            <p style={{ paddingLeft: "2rem" }}>Price</p>
                                          </li>
                                          <li>
                                            <p style={{ paddingLeft: "2rem" }}>Quantity</p>
                                          </li>
                                        </>}
                                        {/* <li>
                                          <p style={{ paddingLeft: "2rem" }}>Inco Term</p>
                                        </li> */}
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Loading Country</p>
                                        </li>
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Loading Port</p>
                                        </li>
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Unloading Country</p>
                                        </li>
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Unloading Port</p>
                                        </li>
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Laycan Start</p>
                                        </li>
                                        <li>
                                          <p style={{ paddingLeft: "2rem" }}>Laycan End</p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>



                                  {/* Exporter Section */}
                                  <div className="form-group">
                                    <label className="font-size-14 font-wt-500" style={{ marginLeft: "2rem" }}>Exporter</label>
                                    <div className="chat-message pl-2" >
                                      <img src={avatarUrl(dbData.supplieravatar)} alt="Avatar" width="32" height="32" />
                                      <div className="chat-message-content clearfix pt-2">
                                        <span className="text-color-value font-size-14 font-wt-400">
                                          {dbData.suppliername ? dbData.suppliername : '--'}
                                        </span><br />
                                      </div>
                                      {/* <p className="date-format p-0 m-0">
                                        {dbData.supplierAddress ? dbData.supplierAddress : '--'}
                                      </p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          {/* <div className=" d-flex justify-content-end">
                            <div className="">
                              <ul>
                                <li>
                                  <QRCode value={dbData.address ? dbData.address : cId} enableCORS={true} fgColor={'#17a2b8'} qrStyle={'dots'} />

                                </li>
                              </ul>
                            </div>
                          </div> */}

                          <div className='card mt-1 p-3'>
                            <h5>Follow Next Steps</h5>

                            {/* Timeline Container */}
                            <div className="d-flex align-items" style={{ padding: "3rem" }}>

                              {/* Step 1 - Contract Created */}
                              {/* <div className="timeline-step completed">
                                <div className="timeline-icon">&#10003;</div>
                                <div className="timeline-content">
                                  <h6>Contract Created</h6>
                                  <small>Completed</small>
                                </div>
                              </div> */}

                              {/* Progress Line */}
                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}
                                  // style={{ width: "30px", height: "30px" }}
                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Contract Created</label>
                                {/* <label className="d-block font-size-13 m-0 p-0 font-wt-200 " >{new Date(value).toLocaleDateString('en-GB')}</label> */}



                              </div>
                              {/* Step 2 - Apply for Insurance */}


                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "100px" }}>
                              </div>

                              {/* <div className="timeline-step highlight">
                                <div className="timeline-icon special-icon">&#9888;</div> 
                                <div className="timeline-content">
                                  <h6>Apply for Insurance</h6>
                                  <small style={{ color: 'red', fontWeight: 'bold' }}>Important!</small>
                                  <p style={{ fontWeight: 'bold', color: '#ff5733' }}>Secure your contract now by applying for insurance!</p>
                                  <button className="btn btn-warning">Apply Now</button>
                                </div>
                              </div> */}

                              <div className="timeline-step highlight">
                                <div className="timeline-icon">
                                  <img src="assets/images/edocs.png" alt="LC" style={{ width: '40px', marginLeft: "1rem" }} />
                                </div>


                                {boolLc.length > 0 ? (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Draft LC Applied</label>
                                ) : <div className="timeline-content">
                                  <h6> Draft LC</h6>
                                  {/* <p style={{ fontWeight: 'bold', color: '#5DADE2' }}>Get extra peace of mind with our easy insurance process!</p> */}
                                  {/* <button className="btn btn-info" style={{ color: "white", background: "#eb810d", border: "none" }} onClick={() => window.location = `/edocsLC?draftlc=${cId}`}>Apply</button> */}
                                  <button className="btn btn-info" style={{ color: "white", background: "#eb810d", border: "none" }} onClick={() => setbooldraftLC((prev) => !prev)}>Apply</button>
                                </div>}

                              </div>
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "none" }}>
                              </div>
                              <div className="timeline-step highlight">
                                <div className="timeline-icon">
                                  <img src="assets/images/insurance.svg" alt="Insurance" style={{ width: '40px', marginLeft: "1rem" }} />
                                </div>

                                {objFound.length > 0 ? (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Insurance Applied</label>
                                ) : <div className="timeline-content">
                                  <h6> Insurance</h6>
                                  {/* <p style={{ fontWeight: 'bold', color: '#5DADE2' }}>Get extra peace of mind with our easy insurance process!</p> */}
                                  <button className="btn btn-info" style={{ color: "white", background: "#eb810d", border: "none" }} onClick={() => setTab(4)}>Apply</button>
                                </div>}
                              </div>

                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "none" }}>
                              </div>

                              <div className="timeline-step highlight">
                                <div className="timeline-icon">
                                  <img src="assets/images/logistics.svg" alt="Logistics" style={{ width: '40px', marginLeft: "1rem" }} />
                                </div>
                                {shipmentFound.length > 0 ? (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Shipment Applied</label>
                                ) : <div className="timeline-content">
                                  <h6> Logistics/Shipment</h6>
                                  {/* <p style={{ fontWeight: 'bold', color: '#5DADE2' }}>Get extra peace of mind with our easy insurance process!</p> */}
                                  <button className="btn btn-info" style={{ color: "white", background: "#eb810d", border: "none" }} onClick={() => setTab(3)}>Apply</button>
                                </div>}
                              </div>



                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "none" }}>
                              </div>
                              <div className="timeline-step highlight">
                                <div className="timeline-icon">
                                  <img src="assets/images/sidebarV2/invoice_menu.svg" alt="Logistics" style={{ width: '40px', marginLeft: "1rem" }} />
                                </div>
                                {boolscfpresent ? (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Finance Applied</label>
                                ) : <div className="timeline-content">
                                  <h6>Finance</h6>
                                  {/* <p style={{ fontWeight: 'bold', color: '#5DADE2' }}>Get extra peace of mind with our easy insurance process!</p> */}
                                  <button className="btn btn-info" style={{ color: "white", background: "#eb810d", border: "none" }} onClick={() => setTab(5)}>Apply</button>
                                </div>}


                              </div>

                            </div>


                          </div>

                        </div>




                      </>
                    }
                    {tab === 1 &&
                      <div className="tab-pane active show  col-md-12">
                        {/* {<>
                          {dbData.payment_mode / 1 === 1 ?
                            <LCPaymentProcess
                              userTokenDetails={userTokenDetails}
                              contractNo={cId}
                              contractData={dbData} />
                            : dbData.payment_mode / 1 === 2 ?
                              <DaDpPaymentProcess
                                userTokenDetails={userTokenDetails}
                                contractNo={cId}
                                contractData={dbData}
                                dadpType={"DP"} />
                              : dbData.payment_mode / 1 === 3 ?
                                <DaDpPaymentProcess
                                  userTokenDetails={userTokenDetails}
                                  contractNo={cId}
                                  contractData={dbData}
                                  dadpType={"DA"} />
                                : dbData.payment_mode / 1 === 4 ?
                                  <TTPaymentProcess
                                    userTokenDetails={userTokenDetails}
                                    contractNo={cId}
                                    contractData={dbData} />
                                  : "Current Payment Mode is Under Development"}
                        </>
                        } */}
                        <div className=''>
                          <BankReimburseForm
                            userTokenDetails={userTokenDetails}
                            contractData={{
                              reimburse_data: dbData.reimburse_data,
                              id: dbData.contract_id,
                              contractNo: dbData.contract_number,
                              exporter_id: dbData.exporter_id,
                              importer_id: dbData.importer_id,
                              buyer_bank: dbData.buyer_bank,
                              supplier_bank: dbData.supplier_bank,
                              paymentType: "LC",
                              currency: dbData?.currency?.split(':')?.[2]
                            }}
                            setshowLoader={setshowLoader}
                            refresh={refresh}
                            setrefresh={setRefresh}
                          />
                          {/* <Accordion>
                            <Card>
                              <Card.Header>
                                <div className="col-md-12 row">
                                  <div className="col-md-11 row">
                                    <h3 className="text-color1 mt-2 font-size-16 font-wt-500">Provide LC Reimbursement Details</h3>
                                  </div>
                                  <div className="col-md-1">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                      <span className="cursor-pointer ml-5"
                                        onClick={() => settoggle2(!toggle2)}
                                      >
                                        {!toggle2 ? <i class="fas fa-angle-double-down fa-2x" aria-hidden="true"></i> : <i class="fas fa-angle-double-up fa-2x" aria-hidden="true"></i>}
                                      </span>
                                    </Accordion.Toggle>
                                  </div>
                                </div>
                              </Card.Header>
                              <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                  <BankReimburseForm
                                    userTokenDetails={userTokenDetails}
                                    contractData={{
                                      reimburse_data: dbData.reimburse_data,
                                      id: dbData.contract_id,
                                      contractNo: dbData.contract_number,
                                      exporter_id: dbData.exporter_id,
                                      importer_id: dbData.importer_id,
                                      buyer_bank: dbData.buyer_bank,
                                      supplier_bank: dbData.supplier_bank,
                                      paymentType: "LC"
                                    }}
                                    setshowLoader={setshowLoader}
                                    refresh={refresh}
                                    setrefresh={setRefresh}
                                  />
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Accordion> */}
                        </div>
                      </div>
                    }

                    {tab === 3 &&
                      <div className="tab-pane active show  col-md-12">
                        <div className='card mt-1 p-3' style={{ marginBottom: "2rem" }}>
                          <div className='d-flex justify-content-center'><h6>Shipment/Logistic Timeline</h6></div>

                          {/* Timeline Container */}
                          <div className="d-flex align-items" style={{ padding: "3rem" }}>


                            <div >

                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                />
                              </div>
                              <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Quote Applied</label>



                            </div>


                            {!shipmentFound.length > 0 ? (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "100px" }}>
                              </div>
                            ) : (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                              </div>
                            )}



                            <div className="timeline-step highlight">
                              <div >
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                />
                              </div>
                              <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Quote Recieved</label>
                            </div>

                            {!shipmentFound.length > 0 ? (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                              </div>
                            ) : (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                              </div>
                            )}
                            <div >

                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                />
                              </div>
                              <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Quote Approved</label>



                            </div>
                            <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "100px" }}>
                            </div>

                            <div >

                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                />
                              </div>


                              {boolshipmentBooked ? (
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Shipment Booked</label>
                              ) :
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Shipment Rejected</label>
                              }


                            </div>

                            {!boolshipmentBooked ? (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                              </div>
                            ) : (
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                              </div>
                            )}
                            <div >

                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                />
                              </div>
                              <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Shipment Tracking</label>



                            </div>



                          </div>


                        </div>
                        {!shipmentFound.length > 0 ? <ApplyForBooking userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} contractNo={conNo} /> :
                          <ShipmentBookingMarketPlace contractapplicationNo={encodeURIComponent(shipmentFound[0].applicationNo)} userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} setShipmentBooked={setShipmentBooked} />}
                      </div>
                    }


                    {tab === 4 &&
                      <div>
                        <div className="p-4 card border-0 rounded-3 " >
                          <div className='card mt-1 p-3' style={{ marginBottom: "2rem" }}>
                            <div className='d-flex justify-content-center'><h6>Insurance Timeline</h6></div>

                            {/* Timeline Container */}
                            <div className="d-flex align-items" style={{ padding: "3rem" }}>


                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Insurance Applied</label>



                              </div>


                              {inNo === null ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}



                              <div className="timeline-step highlight">
                                <div >
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Insurance Recieved</label>
                              </div>

                              {inNo === null ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}
                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Insurance Accepted</label>



                              </div>
                              {inAccept ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}

                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>

                                {apporvedCount.current > 0 ? (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                    Insurance Approved
                                  </label>
                                ) : (
                                  <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                    Insurance Rejected
                                  </label>
                                )}



                              </div>


                            </div>


                          </div>
                          <div className="text-center">
                            <p className="mb-0 font-size-16 mt-3 font-wt-600" >Trade Credit Insurance</p>
                            <small className="text-secondary font-size-12 font-wt-400 ">Select Insurance type</small>
                          </div>
                          <div className='col-md-12 col-centered'>
                            <div className='row mt-4 mb-5 pb-5'>
                              {creditInsMenu.map((item, index) => {
                                return <>
                                  <div className='col-md-6 mt-4 text-center' onClick={() => {
                                    if (item.Link == "/") {
                                      toastDisplay("Coming Soon", "info")
                                    } else {
                                      // Append the contract number if it exists
                                      let contractParam = dbData.contract_number ? `&contractNo=${dbData.contract_number}` : '';

                                      if (userTokenDetails?.type_id == 19) {
                                        window.location = item.Link + `?exporterId=${userTokenDetails?.user_id}` + contractParam;
                                      }
                                    }
                                  }}>
                                    <div className='col-md-12'>
                                      <div className="card p-3 tcitypeCard cursor">
                                        <div className="d-flex gap-3 align-items-center">
                                          <img src={item.Icon} alt="" />
                                          <div className="row">
                                            <p className="font-size-14 mb-1">{item.Heading}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              })}
                            </div>
                          </div>
                        </div>

                        {/* <div style={{ marginTop: "1rem" }}>
                          <div className='p-4 card border-0 rounded-3 '>
                            <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>Insurance Timeline </div>
                            <div className='d-flex align-items'>
                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}
                                // style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                              <label style={{ marginTop: "0.5rem" }}>Insurance Applied</label>
                              <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(92, 226, 225)", borderRadius: "100px" }}>
                              </div>
                              <div className="timeline-icon">
                                <img
                                  src="assets/images/formProgressCircleActive.png"
                                  alt="Completed"
                                  style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}
                                // style={{ width: "30px", height: "30px" }}
                                />
                              </div>
                              <label style={{ marginTop: "0.5rem" }}>Insurance Approved</label>
                            </div>
                          </div>
                        </div> */}




                      </div>

                    }

                    {tab === 5 &&
                      <div>
                        <div className="p-4 card border-0 rounded-3 ">
                          <div className='card mt-1 p-3' style={{ marginBottom: "2rem" }}>
                            <div className='d-flex justify-content-center'><h6>Finance Timeline</h6></div>

                            {/* Timeline Container */}
                            <div className="d-flex align-items" style={{ padding: "3rem" }}>


                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Finance Quote Applied</label>



                              </div>


                              {!boolscfpresent ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}



                              <div className="timeline-step highlight">
                                <div >
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Quote Recieved</label>
                              </div>

                              {!boolscfpresent ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}
                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>
                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>Quote Accepted By Financer</label>



                              </div>
                              {scfStatus !== "Approved" ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}

                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>


                                {scfStatus === "Approved" ? <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                  Quote Approved
                                </label> : <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                  Quote Rejected
                                </label>}






                              </div>
                              {scfdata.termSheetSignedByFinancerOn ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              )}
                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>

                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                  TermSheet Sent
                                </label>
                                {scfdata.termSheetSignedByFinancerOn ? <label
                                  className="d-block font-size-15 m-0 p-0 font-wt-400"
                                  style={{ marginRight: "2rem" }}
                                >
                                  {new Date(scfdata.termSheetSignedByFinancerOn).toISOString().split("T")[0]}{" "}
                                  {new Date(scfdata.termSheetSignedByFinancerOn).toISOString().split("T")[1].split(".")[0]}
                                </label> : <label
                                  className="d-block font-size-15 m-0 p-0 font-wt-400"
                                  style={{ marginRight: "2rem" }}
                                >
                                  -
                                </label>}

                              </div>


                              {inAccept ? (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(173, 243, 242)", borderRadius: "100px" }}>
                                </div>
                              ) : (
                                <div className='mx-0 mt-2' style={{ width: '7rem', height: '0.2rem', background: "rgb(48, 218, 94) ", borderRadius: "100px" }}>
                                </div>
                              )}
                              <div >

                                <div className="timeline-icon">
                                  <img
                                    src="assets/images/formProgressCircleActive.png"
                                    alt="Completed"
                                    style={{ marginLeft: "3rem", marginBottom: "0.5rem" }}

                                  />
                                </div>


                                <label className="d-block font-size-15 m-0 p-0 font-wt-400" style={{ marginRight: "2rem" }}>
                                  TermSheet Signed
                                </label>
                                {scfdata.termSheetSignedByExporterOn ? <label
                                  className="d-block font-size-15 m-0 p-0 font-wt-400"
                                  style={{ marginRight: "2rem" }}
                                >
                                  {new Date(scfdata.termSheetSignedByExporterOn).toISOString().split("T")[0]}{" "}
                                  {new Date(scfdata.termSheetSignedByExporterOn).toISOString().split("T")[1].split(".")[0]}
                                </label> : <label
                                  className="d-block font-size-15 m-0 p-0 font-wt-400"
                                  style={{ marginRight: "2rem" }}
                                >
                                  -
                                </label>}


                              </div>

                            </div>


                          </div>
                          {/* <ApplyLimitComponentV2 userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} /> */}
                          {/* <ApplyLimitSCF userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} /> */}
                          {/* {
                            !boolscfpresent ?
                              <LcdiscountingcardV2 userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} /> :
                              <Quotes userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} />} */}

                          {
                            userEmail === dbData.buyer_email
                              ? <ApplyLimitSCF userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} />
                              : (!boolscfpresent
                                ? <LcdiscountingcardV2 userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} />
                                : <Quotes userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} userShipmentDetails={dbData} />
                              )
                          }


                        </div>
                      </div>
                    }


                    {/* {tab === 2 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_invReport && aclMap.contract_access.cont_invReport.isExist) &&
                          <InvestigationReportForm
                            userTokenDetails={userTokenDetails}
                            contractNo={cId}
                            contractData={{
                              contractNo: cId,
                              contractId: dbData.contract_id,
                              inv_agency_importer: dbData.inv_agency_importer,
                              inv_agency_exporter: dbData.inv_agency_exporter,
                              inv_agency_third: dbData.inv_agency_third,
                              importer_id: dbData.importer_id,
                              exporter_id: dbData.exporter_id,
                            }} />}
                      </div>
                    } */}
                    {/* {tab === 3 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_insuranceDetails && aclMap.contract_access.cont_insuranceDetails.isExist) &&
                          <InsuranceDetailAccord
                            userTokenDetails={userTokenDetails}
                            contractDetails={{
                              contractNo: cId,
                              contractId: dbData.contract_id,
                              commodity_insurer: dbData.commodity_insurer,
                              trade_insurer: dbData.trade_insurer,
                              importer_id: dbData.importer_id,
                              exporter_id: dbData.exporter_id,
                            }}
                            contractType={"commodity"} />}
                      </div>
                    } */}
                    {/* {tab === 4 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_shipmentDetails && aclMap.contract_access.cont_shipmentDetails.isExist) &&
                          <>
                            <ShipmentDetailAccord
                              userTokenDetails={userTokenDetails}
                              contractNo={cId}
                              shipContractNo={null} />
                            <hr />
                            <LaytimeSheetTrail
                              userTokenDetails={userTokenDetails}
                              laytimeType={1}
                              contractNo={cDetailsState.cId}
                              contractType={'master'} />
                            <hr />
                            <LaytimeSheetTrail
                              userTokenDetails={userTokenDetails}
                              laytimeType={1}
                              contractNo={cDetailsState.cId}
                              contractType={'master'} />
                          </>}
                      </div>
                    } */}
                    {/* {tab === 5 &&
                      <div className="tab-pane active show col-md-12">
                        {(aclMap.contract_access && aclMap.contract_access.cont_blprocess && aclMap.contract_access.cont_blprocess.isExist) &&
                          <BillOfLading
                            userTokenDetails={userTokenDetails}
                            contractNo={cId}
                            shipContractNo={null} />}
                      </div>} */}

                    {/* {tab === 6 &&
                      <div className="tab-pane active show col-md-12">
                   
                        <BlockchainTrail
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} />
                     
                      </div>} */}

                    {tab === 7 &&
                      <div className="tab-pane active show col-md-12">
                        {/* {(aclMap.contract_access && aclMap.contract_access.cont_auditTrail && aclMap.contract_access.cont_auditTrail.isExist) && */}
                        <ContractLogs itemData={dbData}
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} />
                        {/* } */}
                      </div>}
                    {tab === 8 &&
                      <div className="col-md-12">
                        <ContractTrail
                          userTokenDetails={userTokenDetails}
                          contractNo={cId} modal={false} />

                      </div>
                    }

                  </div>
                  }

                  {/* {booldocContract && <Oc docType={"Order Confirmation"} boolbulk={boolbulk} dbData={dbData} />} */}
                  {selectedDocType === "Order Confirmation" && (
                    <Oc docType="Order Confirmation" boolbulk={boolbulk} setboolBulk={setboolBulk} dbData={dbData} userTokenDetails={userTokenDetails} />
                  )}

                  {selectedDocType === "Invoice" && (
                    <Invoice docType="Invoice" boolbulk={boolbulk} setboolBulk={setboolBulk} dbData={dbData} userTokenDetails={userTokenDetails} />
                  )}
                  {booldraftLC && <ELC userShipmentDetails={dbData} userTokenDetails={userTokenDetails} hideSideBarAndHeader={true} setbooldraftLC={setbooldraftLC} />}

                  {/* QMS code start */}
                  <>
                    <div className="chat-button" onClick={async () => {
                      let oppositeParty = dbData.exporter_id / 1 == userId / 1 ? dbData.importer_id : dbData.exporter_id
                      let reqObj = {
                        senderId: userId,
                        receiverId: oppositeParty,
                        textMessage: 'Hii',
                        chat_room_name: "CHAT" + new Date().getTime(),
                        includeAdmins: true,
                        contractApplicationId: dbData.contract_id,
                        receiverParties: oppositeParty,
                        dontSendInitialMsg: true
                      }
                      console.log("reqObjjjjjjjjjjjjjjj", reqObj);
                      setshowLoader(true)
                      let apiResp = await call('POST', 'sendChatMessageV2', reqObj)
                      setshowLoader(false)
                      getChatDetails({
                        chat_room_id: apiResp.id,
                        loggedInUser: userId
                      })
                      setSelectedChat({
                        chatRoomId: apiResp.id,
                        receiverName: `${cId}`,
                        contractApplicationId: dbData.contract_id,
                        parties: apiResp.parties,
                        userId: userId,
                        isChatOpen: true,
                        receiverId: oppositeParty
                      })
                    }}>
                      <i className="fa fa-comments-o" aria-hidden="true"></i>
                      {/* <img src='assets/images/supplier-images/chat.png' /> */}
                    </div>
                    <div className="circle-rippl"></div>
                    {selectedChat.isChatOpen &&
                      <>
                        <div className="chatboxDivFixed">
                          <ChatBoxPopUp2
                            clientType={dbData.importer_id / 1 == userId / 1 ? "buyer" : "seller"}
                            forContract={true}
                            chatList={chatList}
                            user_avatar={selectedChat.logo}
                            userId={selectedChat.userId}
                            receiverName={selectedChat.receiverName}
                            parties={selectedChat.parties}
                            userTokenDetails={userTokenDetails}
                            onChatSend={(file, parties) => sendChatMsg(file, parties)}
                            message={message}
                            setTextMsg={setTextMsg}
                            reloadChatList={() => getChatDetails({
                              chat_room_id: selectedChat.chatRoomId,
                              loggedInUser: userId
                            })}
                            onPopupClose={() => {
                              setSelectedChat({
                                receiverName: '',
                                receiverId: '',
                                userId: userId,
                                isChatOpen: false,
                                logo: ''
                              })
                              setRefresh(refresh + 1)
                            }}
                          />
                        </div>

                      </>
                    }
                  </>
                </main>
              </div >
            </div >}
      {/* QMS code end */}
    </>
  )
};

// Redux
const mapStateToProps = state => {
  return {
    cDetailsState: state.contractDetails,
    contractDocList: state.contractDocList,
    ViewTrack: state.ViewTrack,
    InvoiceDetail: state.InvoiceDetail,
    clientType: state.clientType,
    navToggleState: state.navToggleState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setContractDetails: (id) => { dispatch(setContractDetails({ cId: id, modal: false })) },
    setContractDocList: (id) => { dispatch(setContractDocList({ modal: true, contractId: id })) },
    setViewTrack: (flag, data) => { dispatch(setViewTrack({ modal: flag, info: data })) },
    setInvoiceDetail: (flag, data) => { dispatch(setInvoiceDetail({ modal: flag, info: data })) },
  }
}
//---------------------------------------------------------------------------------------------------------------------


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrderContractDetaills)