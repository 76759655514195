import React, { useEffect, useState } from 'react';
import call from '../../service';
import { connect } from 'react-redux';
import { setshowModalAddComm } from '../../store/actions/action';
import toastDisplay from '../../utils/toastNotification';
import SpecificationsForm from '../specifications/specificationForm'
import commImageUrl from '../../utils/makeCommImageUrl'
import { is_valid } from '../../utils/myFunctions';
import { InputWithSelect, NewInput, NewSelect } from '../../utils/newInput';


const UserCommodityAddModal = ({ userTokenDetails, showModalAddComm, setshowModalAddComm, dispatch, activeClass, productDetails }) => {

  const queryParams = new URLSearchParams(window.location.search)
  let closeAfter = queryParams.get("closeAfter")
  let itemNameToSave = queryParams.get('itemNameToSave') || null

  console.log("showModalAddCommmmmmmmmmmmmmmmm", productDetails, showModalAddComm);

  //---------------------------------------------------------------------------------------------------------------------
  // States and variables
  const [dbData, setdbData] = useState([]);
  const [selectedComm, setselectedComm] = useState('');
  const [commQuantity, setcommQuantity] = useState(0);
  const [inprocessCommQuantity, setinprocessCommQuantity] = useState(0);
  const [processedCommQuantity, setprocessedCommQuantity] = useState(0);
  const [procuredCommQuantity, setprocuredCommQuantity] = useState(0);
  const [refresh, setrefresh] = useState(0);
  // const [errorCommodities, seterrorCommodities] = useState(false);
  const [spec, setSpecification] = useState({});
  const [specOrder, setspecOrder] = useState([])
  const [commodityCategories, setcommodityCategories] = useState([])
  let [formError, setformError] = useState({});
  const [data, setdata] = useState({ commName: itemNameToSave });
  const [data2, setdata2] = useState({ commUnit: 'mt', state0: 'Procured', ...showModalAddComm?.info?.tally_addon });
  const [userDbData, setuserDbData] = useState([])
  const [procured, setProcured] = useState([null])
  const [commGroups, setCommGroups] = useState([])
  const [godowns, setGodowns] = useState([])
  const [tallyUnits, setTallyUnits] = useState([])
  // const [extraSpec, setextraSpec] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const [quotaAvail, setQuotaAvail] = useState(false)

  let jj = -1


  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  //---------------------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------------------
  // UseEffects
  useEffect(() => { console.log("data2 here", data2) }, [data2])
  useEffect(() => {
    call('POST', 'getInventoryGodowns', { userId }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        res.data[index]["name"] = res.data[index]["details"]["name"]
      }
      setGodowns([{ name: 'Primary' }, { name: 'Main Location' }, ...res.data])
    })
    call('POST', 'getInventoryStockGroups', { userId }).then(res => {
      for (let index = 0; index < res.data.length; index++) {
        res.data[index]["name"] = res.data[index]["details"]["name"]
      }
      setCommGroups([{ name: 'Primary' }, ...res.data])
    })

    call('GET', 'getcommoditycategory').then((result) => {
      console.log("API result getcommoditycategory in comm modal:-->", result);
      setshowLoader(false)
      if (result.length) {
        setcommodityCategories(result);
        if (productDetails?.product_name && productDetails?.product_category) {
          setdata({
            ...data, commName: productDetails.product_name,
            "commdCategorys": result.filter(item => { if (item.category == productDetails?.product_category) return item })?.[0]?.['id']
          })
          // setselectedComm('temp')
          setdata2({
            ...data2, hsn: productDetails?.product_hs_code, procuredQuantity0: productDetails?.product_quantity,
            state0: productDetails?.product_state, procuredTargetFromDate0: productDetails?.procurring_from_date,
            procuredTargetToDate0: productDetails?.procurring_to_date, procuredDate0: productDetails?.procured_date
          })
        }
        else if (showModalAddComm.info.status) {
          setdata({
            ...data,
            "commdCategorys": result.filter(item => { if (item.category == showModalAddComm.info.category) return item })[0]['id'],
            "commName": showModalAddComm.info.commodity_pretty_name
          })
          let tempProcured = showModalAddComm.info.procured || []
          let tempData2 = {}
          let tempArr = []
          for (let index = 0; index < tempProcured.length; index++) {
            const element = tempProcured[index];
            tempArr.push(null)
            if (element.state) {
              tempData2[`state${index}`] = element.state
            }
            if (element.godown) {
              tempData2[`godown${index}`] = element.godown
            }
            if (element.rate) {
              tempData2[`rate${index}`] = element.rate
            }
            if (element.quantity) {
              tempData2[`procuredQuantity${index}`] = element.quantity
            }
            if (element.targetFrom) {
              tempData2[`procuredTargetFromDate${index}`] = element.targetFrom
            }
            if (element.targetTo) {
              tempData2[`procuredTargetToDate${index}`] = element.targetTo
            }
            if (element.actual) {
              tempData2[`procuredDate${index}`] = element.actual
            }
            if (element.state === "Procured" && element.quantity) {
              tempData2[`nonEditable${index}`] = true
            }
          }
          if (tempArr.length) { setProcured([...tempArr]) }
          setdata2({
            ...data2, ...tempData2, hsn: showModalAddComm.info.hsn, commUnit: showModalAddComm.info.unit, commdGroup: showModalAddComm.info.group,
            commdTaxabilityType: showModalAddComm.info.taxabilityType, gstRate: showModalAddComm.info.gstRate
          })
          setdbData([{
            "category": showModalAddComm.info.category,
            "commodity": showModalAddComm.info.commodity,
            "commodity_name": showModalAddComm.info.commodity_name,
            "commodity_pretty_name": showModalAddComm.info.commodity_pretty_name,
            "filename": showModalAddComm.info.filename,
            "id": showModalAddComm.info.id
          }])
          setcommQuantity(showModalAddComm.info.total_qty ? showModalAddComm.info.total_qty : 0)
          setinprocessCommQuantity(showModalAddComm.info.inprocess_qty ? showModalAddComm.info.inprocess_qty : 0)
          setprocessedCommQuantity(showModalAddComm.info.processed_qty ? showModalAddComm.info.processed_qty : 0)
          setprocuredCommQuantity(showModalAddComm.info.procured_qty ? showModalAddComm.info.procured_qty : 0)
          setSpecification(showModalAddComm.info.specifications)
          setspecOrder((showModalAddComm.info && showModalAddComm.info.spec_order_string) ? showModalAddComm.info.spec_order_string.split(":") : [])
          setselectedComm(showModalAddComm.info.id)
        }
      }
    }).catch((e) => {
      console.log("Error while querying getcommoditycategory:", e);
      setshowLoader(false)
    })

    if (!showModalAddComm.info.status) {
      call('POST', 'getusercommoditylist', { "userId": userId, 'userTypeId': userTypeId }).then((result) => {
        // console.log("getusercommoditylist->", result)
        if (result.length) {
          setuserDbData(result)
        }
      }).catch((e) => {
        console.log("Error while querying users:", e);
      })
    }

    //------------------------------------------------------------------
    //API Calls
    setshowLoader(true)

    if (!showModalAddComm.info.status) {
      call('POST', 'getCommodityList', { specifications: true, commadd: true, userId: userId }).then((result) => {
        console.log("API result getCommodityList in comm modal:-->", result);
        setdbData(result);
        setshowLoader(false)
      }).catch((e) => {
        setshowLoader(false)
        console.log("Error while querying getCommodityList:", e);
      })
    }
    // else {
    //   setdbData([{
    //     "category": showModalAddComm.info.category,
    //     "commodity": showModalAddComm.info.commodity,
    //     "commodity_name": showModalAddComm.info.commodity_name,
    //     "commodity_pretty_name": showModalAddComm.info.commodity_pretty_name,
    //     "filename": showModalAddComm.info.filename,
    //     "id": showModalAddComm.info.id
    //   }])
    //   setcommQuantity(showModalAddComm.info.total_qty)
    //   setSpecification(showModalAddComm.info.specifications)
    //   setspecOrder((showModalAddComm.info && showModalAddComm.info.spec_order_string) ? showModalAddComm.info.spec_order_string.split(":") : [])
    //   setselectedComm(showModalAddComm.info.id)
    //   setshowLoader(false)
    // }
    //------------------------------------------------------------------
    if (showModalAddComm.info.type === "editCommodityUser") {
      setQuotaAvail(true)
    } else {
      call('get', 'v1/plan/quota').then((result) => {
        console.log("result while  getUserCurrentPlanQuota:-->", result);
        if (result && result.commodities && result.commodities.enable && ((result.commodities.limitLeft / 1) > 0 || (result.commodities.limitLeft / 1) === -1))
          setQuotaAvail(true)
      }).catch((e) => {
        console.log("Error while  getUserCurrentPlanQuota:-->", e);
      })
    }


    //------------------------------------------------------------------

  }, [refresh, productDetails]);
  // console.log("dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", data2, selectedComm);
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // Handlers
  const handlequantity = (event) => {
    event.persist();
    setcommQuantity(event.target.value);
  }
  const handleSpecification = (event) => {
    event.persist();
    let tempArray = event.target.name.split(",")
    let specdataElement = { ...spec }
    specdataElement[tempArray[0]][tempArray[1]] = event.target.value
    setSpecification(specdataElement);
  }

  //---------------------------------------------------------------------------------------------------------------------

  console.log("specOrder -->", specOrder)
  // console.log("showModalAddComm -->", showModalAddComm)

  // console.log("quotaAvail-->", quotaAvail)
  //console.log("commQuantity-->", commQuantity)
  //console.log("spec in user modal-->", spec)

  //---------------------------------------------------------------------------------------------------------------------
  // Submit handler
  async function handleSubmit() {
    setshowLoader(true)
    if (selectedComm === "temp") {
      let formData = new FormData();
      formData.append('commdCategory', data["commdCategorys"]);
      formData.append('commodityPrettyName', data["commName"]);
      formData.append('commodityName', data["commName"].toLowerCase().replace(/\s/g, ''));
      formData.append('spec', JSON.stringify({}));
      formData.append('specOrder', "");
      formData.append('hsn', data2.hsn)
      formData.append('group', data2.commdGroup)
      formData.append('taxabilityType', data2.commdTaxabilityType)
      formData.append('gstRate', data2.gstRate)
      formData.append("data2", JSON.stringify(data2))

      let result = await call('POST', 'savecommoditymaster', formData)
      if (result && result.message && result.message.id) {
        setshowLoader(false)
        setselectedComm(result.message.id)
        add_update_user_commodity(result.message.id)
      }
      else {
        setshowLoader(false)
        toastDisplay("Failed to add commodity master", "error")
      }
    } else {
      add_update_user_commodity()
      // let formData = new FormData();
      // formData.append('owner_id', userId)
      // formData.append('selectedComm', selectedComm);
      // formData.append('commQuantity', commQuantity);
      // formData.append('spec', JSON.stringify(spec));
      // formData.append('specOrder', specOrder.join(":"));
      // console.log("formdata to submit=>", formData);

      // call('POST', (!showModalAddComm.info.status) ? 'setCommodityuser' : 'updateCommodityuser', formData).then((result) => {
      //   console.log("set comm user==>", result);
      //   if (result) {
      //     setshowLoader(false)
      //     setshowModalAddComm(false, { refresh: true })
      //     setrefresh(refresh + 1)
      //     setselectedComm("")
      //     setSpecification({})
      //     // seterrorCommodities(false)
      //     toastDisplay(!showModalAddComm.info.status ? "New Commodity added" : "Commodity Updated", "success")
      //   }
      // }).catch(err => {
      //   setshowLoader(false)
      //   console.log("Error:", err)
      //   toastDisplay(err, "error");
      // })
    }

  }

  function add_update_user_commodity(commodity_id) {
    let formData = new FormData();
    formData.append('owner_id', userId)
    formData.append('selectedComm', commodity_id ? commodity_id : selectedComm);
    formData.append('commQuantity', commQuantity);
    formData.append('inprocessCommQuantity', inprocessCommQuantity);
    formData.append('processedCommQuantity', processedCommQuantity);
    formData.append('procuredCommQuantity', procuredCommQuantity);
    formData.append('spec', JSON.stringify(spec));
    formData.append('specOrder', specOrder.join(":"));
    let procuredDetails = []
    for (let index = 0; index < procured.length; index++) {
      const element = procured[index];
      if (element === null) {
        if (data2[`commUnit`]) {
          procuredDetails.push({
            nonEditable: data2[`nonEditable${index}`],
            state: data2[`state${index}`],
            godown: data2[`godown${index}`],
            rate: data2[`rate${index}`],
            quantity: data2[`procuredQuantity${index}`], targetFrom: data2[`procuredTargetFromDate${index}`],
            targetTo: data2[`procuredTargetToDate${index}`], actual: data2[`procuredDate${index}`]
          })
        }
      }
    }
    formData.append('procuredDetails', JSON.stringify(procuredDetails))
    formData.append('commUnit', data2.commUnit)
    formData.append("extra", JSON.stringify({
      commodityPrettyName: data["commName"], group: data2.commdGroup,
      hsn: data2.hsn, gstRate: data2.gstRate
    }))
    formData.append("data2", JSON.stringify(data2))
    // console.log("formdata to submit=>", formData);

    call('POST', (!showModalAddComm.info.status) ? 'setCommodityuser' : 'updateCommodityuser', formData).then((result) => {
      // console.log("set comm user==>", result);
      if (result) {
        setshowLoader(false)
        // setshowModalAddComm(false, { refresh: true })
        setrefresh(refresh + 1)
        setselectedComm("")
        setSpecification({})
        // seterrorCommodities(false)
        toastDisplay(!showModalAddComm.info.status ? "New Item added" : "Item Updated", "success", () => {
          if (closeAfter) {
            window.opener.postMessage(data["commName"]?.toLowerCase(), window.location.origin);
            window.close()
          }
          else {
            window.location = '/manageCommodities'
          }
        })
      }
    }).catch(err => {
      setshowLoader(false)
      console.log("Error:", err)
      toastDisplay(err, "error");
    })
  }

  useEffect(() => {
    if (showModalAddComm.info.status) {
      return
    }
    setCommodity()
  }, [data])
  useEffect(() => {
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      "type": 'all'
    }
    call('POST', 'getInventoryStockUnits', objectAPI).then((result) => {
      // console.log('running getInventoryGodowns api-->', result);
      // setDbData(result.data);
      // setCount(result.countData);
      setTallyUnits(result.units)
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getInventoryGodowns', e);
      setshowLoader(false)
    });

  }, [])
  const setCommodity = () => {
    // console.log("setccccccccccccccccccccccccccccc", data);
    setselectedComm('');
    setSpecification({});
    setspecOrder([]);
    setformError({})
    if (data["commdCategorys"] && is_valid(data["commName"])) {
      let alredySelected = userDbData.filter((item) => {
        if (item.commodity_name == data["commName"].toLowerCase().replace(/\s/g, '') && item.category_id == data["commdCategorys"]) return item
      })
      if (alredySelected.length) {
        setformError({ "commName": "Commodity with same name already added" })
        return
      }
      // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", alredySelected);
      let commDetails = dbData.filter((item) => {
        if (item.commodity_name == data["commName"].toLowerCase().replace(/\s/g, '') && item.category_id == data["commdCategorys"]) return item
      })
      console.log("cccccccccccccccccccccccc", commDetails, dbData, alredySelected.length);
      if (commDetails.length) {
        setselectedComm(commDetails[0].id);
        setSpecification(commDetails[0].specifications ? commDetails[0].specifications : {});
        setspecOrder(commDetails[0].spec_order_string ? commDetails[0].spec_order_string.split(":") : []);
        setdata2({ ...data2, hsn: commDetails[0].hsn })
      }
      else {
        setselectedComm('temp');
        setSpecification({});
        setspecOrder([]);
      }
    }
  }

  const handleChange = (event) => {
    event.persist();
    // alert("eventcame")
    setdata(data => ({ ...data, [event.target.name]: (event.target.value).replace(/^\s?/, '') }));
  }

  const handleChange2 = (event) => {
    event.persist();
    setdata2(data2 => ({ ...data2, [event.target.name]: event.target.value }));
  }

  const astrix = <span className="required-field text-danger">*</span>
  //---------------------------------------------------------------------------------------------------------------------


  //---------------------------------------------------------------------------------------------------------------------
  // JSX Return
  return (
    <>
      {(showModalAddComm.modal) &&
        <div className={(showModalAddComm.modal ? " show" : "")} id="EditCommodity">
          <div className="modal-dialog d-flex modal-xl border-inner mw-100" id="parent_class">
            {/* <div className="modal-content"> */}
            <div style={{ "pointer-events": "auto" }} className='w-100'>
              {/* <div className="modal-header primary">
                <h4 className="modal-title text-white">{showModalAddComm.info.type === "editCommodityUser" ? " Edit Commodity" : "Add New Commodity"}</h4>
                <button type="button" className="close" onClick={() => { setshowModalAddComm(false, {}); }}>×</button>
              </div> */}
              {/* <div className="modal-body"> */}
              <div className='card d-flex p-4' >
                {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
                <label className="green pb-0 font-size-16 font-wt-600">Item Details</label>
                <hr className="" />
                <div className='row pb-2'>
                  <div className='col-3'>
                    <label >Item Group {astrix} </label>
                    <div >
                      <select
                        disabled={showModalAddComm.info.status}
                        className={" form-control" + (!formError["commdGroup"] ? '' : ' border-danger')} name={"commdGroup"}
                        value={data2["commdGroup"]} onChange={handleChange2}>
                        <option value="" selected>--Select Group--</option>
                        {
                          commGroups.length ? commGroups.map((item, index) => {
                            return (<option value={item.name}>{item.name}</option>)
                          }) : ''
                        }
                      </select>
                      {formError["commdGroup"] ? <div class="text-danger error-contract">{formError["commdGroup"]}</div> : ''}
                    </div>
                  </div>

                  <div className='col-3'>
                    <label >Item Type/Category {astrix} </label>
                    <div >
                      <select
                        disabled={showModalAddComm.info.status}
                        className={" form-control" + (!formError["commdCategorys"] ? '' : ' border-danger')} name={"commdCategorys"}
                        value={data["commdCategorys"]} onChange={handleChange}>
                        <option value="" selected>--Select Category--</option>
                        {
                          commodityCategories.length ? commodityCategories.map((item, index) => {
                            return (<option value={item.id}>{item.category}</option>)
                          }) : ''
                        }
                      </select>
                      {formError["commdCategorys"] ? <div class="text-danger error-contract">{formError["commdCategorys"]}</div> : ''}
                    </div>
                  </div>



                  <div className="col-3">
                    <div className="row form-group">
                      <label className="col-md-12">Item name {astrix} </label>
                      <div className="col-md-12">
                        <input disabled={showModalAddComm.info.status} type="text" name="commName" value={data.commName} placeholder="Commodity name" className={" form-control" + (!formError.commName ? '' : ' border-danger')} onChange={handleChange} />
                        {formError.commName ? <div class="text-danger error-contract">{formError.commName}</div> : ''}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-3">
                    <div className="row form-group">
                      <label className="col-md-12">HS Code {astrix} </label>
                      <div className="col-md-12">
                        <input name="hsn" value={data2.hsn} placeholder="Enter HSN" className={" form-control" + (!formError.commName ? '' : ' border-danger')}
                          onChange={handleChange2} />
                        {formError.hsn ? <div class="text-danger error-contract">{formError.hsn}</div> : ''}
                      </div>
                    </div>
                  </div> */}
                  {/* <label className="green pb-0 font-size-16 font-wt-600">GST & HSN Details</label> */}

                  <div className="col-md-3 ">
                    <label >GST Applicability</label>
                    <NewSelect
                      selectData={[
                        { name: '\u0004 Applicable' },
                        { name: '\u0004 Not Applicable' }
                      ]}
                      // label="GST Applicability"
                      name="gstApplicability"
                      value={data2.gstApplicability}

                      optionLabel="name"
                      optionValue="name"
                      onChange={handleChange2}
                    />
                  </div>
                  {data2.gstApplicability === "\u0004 Applicable" ? <>
                    <div className='col-3'>
                      <label >Taxability Type</label>
                      <div >
                        <select
                          disabled={showModalAddComm.info.status}
                          className={" form-control" + (!formError["commdTaxabilityType"] ? '' : ' border-danger')} name={"commdTaxabilityType"}
                          value={data2["commdTaxabilityType"]} onChange={handleChange2}>
                          <option value="" selected>--Select Taxability Type--</option>
                          {
                            [{ 'name': 'Exempt' }, { name: 'Nil Rated' }, { name: 'Non-GST' }, { name: 'Taxable' }].map((item, index) => {
                              return (<option value={item.name}>{item.name}</option>)
                            })
                          }
                        </select>
                        {formError["commdTaxabilityType"] ? <div class="text-danger error-contract">{formError["commdTaxabilityType"]}</div> : ''}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label >Type Of Supply</label>

                      <NewSelect selectData={[{ name: 'Goods' }, { name: 'Capital Goods' }, { name: 'Services' }]}
                        name={"typeOfSupply"} value={data2.typeOfSupply} optionLabel={"name"} optionValue={"name"}
                        onChange={handleChange2}
                      />
                    </div>
                    <div className="col-3">
                      <div className="row form-group">
                        <label className="col-md-12">GST Rate (%) </label>
                        <div className="col-md-12">
                          <input type="number" disabled={showModalAddComm.info.status} name="gstRate" value={data2.gstRate} placeholder="GST Rate" className={" form-control" + (!formError.commName ? '' : ' border-danger')} onChange={handleChange2} />
                          {formError.gstRate ? <div class="text-danger error-contract">{formError.gstRate}</div> : ''}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label >GST Applicable From</label>

                      <NewInput type={"date"}
                        isAstrix={true}
                        value={data2.GSTAppFrom}
                        name={"GSTAppFrom"} selected={data2.GSTAppFrom}
                        onChange={handleChange2} />
                    </div>
                    <div className="col-md-3">
                      <NewInput
                        isAstrix={true}
                        label={"HSN/SAC"} name={"hsn"} value={data2.hsn}
                        onChange={handleChange2} />
                    </div>
                    <div className="col-md-3">
                      <NewInput type={"date"}
                        isAstrix={true}
                        value={data2.HSNAppFrom}
                        label={"HSN Applicable From"} name={"HSNAppFrom"} selected={data2.HSNAppFrom}
                        onChange={handleChange2} />
                    </div>
                    <div className="col-md-3">
                      <NewInput
                        isAstrix={true}
                        label={"Description"} name={"description"} value={data2.description}
                        onChange={handleChange2} />
                    </div>
                    <div className="col-md-3">
                      {/* <label >Batch Wise</label> */}

                      <NewSelect selectData={[{ name: 'Yes' }, { name: 'No' }]} label={"Batch Wise"}
                        name={"IsBatchWiseOn"} value={data2.IsBatchWiseOn} optionLabel={"name"} optionValue={"name"}
                        onChange={handleChange2}
                      />
                    </div>

                    <div className="col-md-3">
                      <label >Perishable</label>
                      <NewSelect selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        name={"IsPerishableOn"} value={data2.IsPerishableOn} optionLabel={"name"} optionValue={"name"}
                        onChange={handleChange2}
                      />
                    </div>
                    <div className="col-md-3">
                      <label >Has Mfg Date</label>
                      <NewSelect selectData={[{ name: 'Yes' }, { name: 'No' }]}
                        name={"HasMfgDate"} value={data2.HasMfgDate} optionLabel={"name"} optionValue={"name"}
                        onChange={handleChange2}
                      />
                    </div>
                    <div className="col-md-3">
                      <label >Costing Method</label>
                      <NewSelect selectData={[{ name: 'At Zero Cost' },
                      { name: 'Avg. Cost' },
                      { name: 'FIFO' },
                      { name: 'FIFO Perpetual' },
                      { name: 'Last Purchase Cost' },
                      { name: 'LIFO Annual' },
                      { name: 'LIFO Perpetual' },
                      { name: 'Monthly Avg. Cost' },
                      { name: 'Std. Cost' }, { name: 'Default' }]}
                        name={"CostingMethod"} value={data2.CostingMethod} optionLabel={"name"} optionValue={"name"}
                        onChange={handleChange2}
                      />
                    </div>
                    {/* <button type="button" style={{ "margin-left": '45%' }} className="btn btn-primary btn-sm mb-1" onClick={() => validateFields()}>SUBMIT</button> */}
                  </> : <></>}
                </div>

                {selectedComm && <>
                  <label className="green pt-3 font-size-16 font-wt-600">Item Quantity & Specification</label>
                  <hr className="" />
                  <div className='row' >
                    {procured.map((i, j) => {
                      if (i === null) {
                        jj = jj + 1
                        return (
                          <>
                            <label className='font-size-16 font-wt-600 my-3' >{`Quantity Details ${jj + 1}`}</label>
                            <div className='col-2' >
                              <NewSelect isDisabled={data2[`nonEditable${j}`]} label={'Commodity State'} name={`state${j}`} value={data2[`state${j}`]}
                                onChange={handleChange2} selectData={[{ name: 'Procuring' }, { name: 'Procured' }]}
                                optionLabel={"name"} optionValue={"name"} />
                            </div>
                            <div className='col-2' >
                              <NewSelect isDisabled={data2[`nonEditable${j}`]} label={'Godown'} name={`godown${j}`} value={data2[`godown${j}`]}
                                onChange={handleChange2} selectData={godowns}
                                optionLabel={"name"} optionValue={"name"} />
                            </div>
                            <div className='col-2' >
                              <InputWithSelect isDisabled={data2[`nonEditable${j}`]} type={"number"} label={'Quantity'} name={`procuredQuantity${j}`} value={data2[`procuredQuantity${j}`]}
                                onChange={handleChange2} selectData={tallyUnits} selectName={`commUnit`}
                                selectValue={data2.commUnit} optionLabel={"name"} optionValue={"name"} />
                            </div>
                            <div className='col-2' >
                              <NewInput isDisabled={data2[`nonEditable${j}`]} type={"number"} label={'Rate'} name={`rate${j}`} value={data2[`rate${j}`]}
                                onChange={handleChange2} />
                            </div>
                            {data2[`state${j}`] != "Procured" ?
                              <>
                                <div className='col-2' >
                                  <NewInput type={"date"} label={"Target From Date"} name={`procuredTargetFromDate${j}`}
                                    value={data2[`procuredTargetFromDate${j}`]} onChange={handleChange2} />
                                </div>
                                <div className='col-2' >
                                  <NewInput type={"date"} label={"Target To Date"} name={`procuredTargetToDate${j}`}
                                    value={data2[`procuredTargetToDate${j}`]} onChange={handleChange2} />
                                </div>
                              </>
                              : <div className='col-2' >
                                <NewInput isDisabled={data2[`nonEditable${j}`]} type={"date"} label={"Procured Date"} name={`procuredDate${j}`}
                                  value={data2[`procuredDate${j}`]} onChange={handleChange2} />
                              </div>}
                            <div className='col-2' >
                              <div className="d-flex w-100 justify-content-between" >
                                <div className="" >
                                  {(procured.length - procured.filter(i => {
                                    if (i === undefined) {
                                      return true
                                    }
                                  }).length) - 1 === jj ?
                                    <img
                                      onClick={async () => {
                                        let temp = procured
                                        temp.push(null)
                                        setProcured([...temp])
                                      }}
                                      className="cursor" src="assets/images/add_black_icon.png" /> :
                                    <img
                                      onClick={() => {
                                        if (!data2[`nonEditable${j}`]) {
                                          let temp = procured
                                          temp[j] = undefined
                                          setProcured([...temp])
                                        }
                                      }}
                                      className="cursor" src="assets/images/delete.png" />}
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}
                    {/* <div className='col-3'>
                      <h3 className="mt-2 mb-1 font-size-14 font-wt-500">Total Quantity</h3>
                      <div className="col-md-12 pl-0">
                        <div className="row form-group">
                          <div className="col-md-12"><input type='number' name="totalQuantity" value={commQuantity} placeholder="Enter Total Quantity" className="form-control" onChange={handlequantity} /></div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className='col-3'>
                      <h3 className="mt-2 mb-1 font-size-14 font-wt-500">In-process Quantity</h3>
                      <div className="col-md-12 pl-0">
                        <div className="row form-group">
                          <div className="col-md-12"><input type='number' name="inprocessCommQuantity" value={inprocessCommQuantity} placeholder="Enter In-process Quantity" className="form-control"
                            onChange={(e) => { setinprocessCommQuantity(e.target.value) }} /></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-3'>
                      <h3 className="mt-2 mb-1 font-size-14 font-wt-500">Processed Quantity</h3>
                      <div className="col-md-12 pl-0">
                        <div className="row form-group">
                          <div className="col-md-12"><input type='number' name="processedCommQuantity" value={processedCommQuantity} placeholder="Enter Processed Quantity" className="form-control"
                            onChange={(e) => { setprocessedCommQuantity(e.target.value) }} /></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-3'>
                      <h3 className="mt-2 mb-1 font-size-14 font-wt-500">Procured Quantity</h3>
                      <div className="col-md-12 pl-0">
                        <div className="row form-group">
                          <div className="col-md-12"><input type='number' name="procuredCommQuantity" value={procuredCommQuantity} placeholder="Enter Procured Quantity" className="form-control"
                            onChange={(e) => { setprocuredCommQuantity(e.target.value) }} /></div>
                        </div>
                      </div>
                    </div> */}

                  </div>
                  <SpecificationsForm
                    hideModalPadding
                    commodity={selectedComm}
                    spec={spec}
                    handleSpecification={handleSpecification}
                    setSpecification={setSpecification}
                    specOrder={specOrder}
                    setspecOrder={setspecOrder}
                    editLevel={true}
                    masterid={(!showModalAddComm.info.status) ? true : false}
                    commName={data.commName ? data.commName : '-'}
                  />
                </>}

                <div className="mt-4">
                  {(dbData && dbData.length > 0) ? <>
                    {/* <button type="button" className="btn btn-danger btn-sm" onClick={() => { setshowModalAddComm(false, {}); }}>Cancel</button> */}
                    <button
                      // disabled={!(is_valid(specOrder) && data2.hsn)}
                      disabled={!(data2.commdGroup)}
                      type="button" className="btn bg-1ea3ae text-white btn-sm" onClick={() => handleSubmit()}>Save Item</button>
                  </> : <>
                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { setshowModalAddComm(false, {}); }}>Close</button>
                  </>}
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
};

const mapStateToProps = state => {

  return {
    showModalAddComm: state.showModalAddComm
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setshowModalAddComm: (flag, data) => { dispatch(setshowModalAddComm({ modal: flag, info: data })) }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCommodityAddModal)
