import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { connect } from "react-redux";
import NewTablev2 from "../../utils/newTablev2";
import { bgGetConfirmationDocs } from "../Dashboard/TableJson";
import { FileInput } from "../../utils/FileInput";
import { astrix, financialYearMonths, getCO2EmissionTotal, getDocDetails, getTotalLogisticAmount, handleFileCommonFunction, handleMultipleFile, mergeMultiplePdfs, most_used_currencies, multiSelectEventHandler } from "../../utils/myFunctions";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import SignDocument from "../InvoiceDiscounting/components/SignDocument";
import { InputForTable, InputWithSelect, InputWithSelectForTable, NewInput, NewSelect, SelectForTable } from "../../utils/newInput";
import { useEffect } from "react";
import avatarUrl from "../../utils/makeAvatarUrl";
import { environment, platformBackendUrl } from "../../urlConstants";
import call from "../../service";
import toastDisplay from "../../utils/toastNotification";
import NewRegistration, { industryData } from "../registration/newRegistration";
import moment from "moment";
import axios from "axios";
import ChatBoxPopUp from "../chatRoom/components/ChatBoxPopUp";
import FinanceInvoiceModal from "../InvoiceDiscounting/contract/components/financeinvoiceModal";
import ExtraDetailsForLimitApplication from "../InvoiceDiscounting/extraDetailsForLimitApplication";
import Papa from 'papaparse';
import MultipleSelect from "../../utils/MultipleSelect";
import { getTraderType, setTraderType } from "../../utils/cookieHelper";
import { IncoTerms } from "../UserOnboard/BuyersTab";
import ReactCountryFlag from "react-country-flag";
import MultipleSelectForTable from "../../utils/MultipleSelectForTable";
import { DeliveryMode, airTransportation, cargoType, landTransportation, seaTransportation, shipContainerType, shipType, truckType, uldType, unitSystem, wagonType } from "./ApplyForBooking";
import ChatBoxPopUp2 from "../chatRoom/components/ChatBoxPopUp2";

let shipmentBookingQsts = [
  { name: 'Do You Need Customs Clearance?' },
  { name: 'Do You Need Insurance?' },
  { name: 'Do You Need Pre Shipment Inspection Service?' },
  { name: 'Do You Need to Defer Your Cargo Payment?' },
  {
    name: 'Do You Want to Offset Your Carbon Footprint?'
  }
]

let paymentTypes = [{ name: 'Pay With Invoice', img: 'assets/images/payWithInvoice.svg' },
{ name: 'Pay With Razorpay', img: 'assets/images/payWithCreditCard.svg' }]

const ShipmentBookingMarketPlace = ({ userTokenDetails, navToggleState, hideSideBarAndHeader, contractapplicationNo, setShipmentBooked }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let applicationNo = queryParams.get('applicationNo') || contractapplicationNo
  let ShipmentQuoteDetails = JSON.parse(localStorage.getItem('ShipmentQuoteDetails') || "{}")

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;
  const user_avatar = userTokenDetails.userAvatar ? userTokenDetails.userAvatar : null

  let stops = [{ distInPer: '100%' }, { distInPer: '0%' }]

  let containerTypes = [{ name: '20’ Standard' }, { name: '40’ Standard' }, { name: '40’ High Cube' }, { name: '45’ High Cube' }
    , { name: '20’ Refrigerated' }, { name: '40’ Refrigerated' }]

  const [showLoader, setshowLoader] = useState(false)
  const [tab, setTab] = useState(0)
  const [data, setData] = useState({
  })
  const [errors, setErrors] = useState({})
  const [commList, setCommList] = useState([])
  const [ports, setPorts] = useState([])
  const [countryData, setcountryData] = useState([]);
  const [commCategory, setCommCategory] = useState([]);
  const [supplierCommodityList, setsupplierCommodityList] = useState([]);
  const [currencyData, setcurrencyData] = useState([]);
  const [ShipperList, setShipperList] = useState([])
  const [reviewScrollIndex, toggleReviewScrollIndex] = useState(0)
  const [seeReview, setSeeReview] = useState({})

  const [shipmentQuoteData, setShipmentQuoteData] = useState([])
  const [contractList, setContractList] = useState([])

  const [quoteArr, setQuoteArr] = useState([null, null, null, null, null, null, null])
  const [viewMore, toggleViewMore] = useState(null)

  const [selectedChat, setSelectedChat] = useState({
    receiverName: '',
    receiverId: '',
    isChatOpen: false,
    logo: null
  })
  const [message, setTextMsg] = useState('')
  const [chatList, setChatList] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [bookingPopupForm, toggleBookingPopupForm] = useState({ show: false, data: {} })


  console.log(
    userTokenDetails.UserAccessPermission,
    JSON.parse(userTokenDetails.UserAccessPermission),
    "this is exportermanagement--->>>>>"
  );

  // const [userPermissions, setUserPermissions] = useState([]);
  // let subPermissions

  // useEffect(() => {
  //   if (userTokenDetails.UserAccessPermission) {
  //     try {
  //       const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
  //       setUserPermissions(parsedPermissions);
  //     } catch (error) {
  //       console.error("Failed to parse user permissions:", error);
  //     }
  //   }
  // }, [userTokenDetails.UserAccessPermission]);

  // console.log(userPermissions, "Parsed user permissions");

  // const getSubPermissions = (componentPath) => {
  //   for (const role of userPermissions) {
  //     for (const perm of role.permissions) {
  //       if (perm.item === componentPath) {
  //         return perm.selected;
  //       }
  //     }
  //   }
  //   return [];
  // };

  // if (!hideSideBarAndHeader) {
  //   subPermissions = getSubPermissions("ShipmentBookingMarketPlace");
  //   console.log(subPermissions, "this is subpermission--//////");
  // }


  // // Boolean states for permissions
  // const [booladd, setbooladd] = useState(false);
  // const [boolview, setboolview] = useState(false);

  // useEffect(() => {
  //   const hasAddPermission = subPermissions.includes("create");
  //   const hasViewPermission = subPermissions.includes("view");
  //   console.log(hasAddPermission, "this is add permission--->>>>>");

  //   // Update state only if there is a change
  //   if (booladd !== hasAddPermission) {
  //     setbooladd(hasAddPermission);
  //   }
  //   if (boolview !== hasViewPermission) {
  //     setboolview(hasViewPermission);
  //   }
  // }, [subPermissions, booladd, boolview]);

  // console.log(subPermissions, "this is buyer management--->>>>>");
  // console.log(booladd, "this is bool add--->>>>");



  async function loadShipperQuotes(shipperId) {
    let shipperQuotesObj = JSON.parse(ShipmentQuoteDetails.shipperQuotes || "[]")
    shipperQuotesObj = shipperQuotesObj.find((i) => {
      if (i.shipper_id / 1 == shipperId / 1) {
        return true
      }
    }) || {}
    let shipperQuotes = shipperQuotesObj?.["charges"]
    let chargesType = Object.keys(shipperQuotes || {})
    let tempData = {}
    let tempQuoteArr = []
    for (let index = 0; index < chargesType.length; index++) {
      for (let j = 0; j < shipperQuotes[chargesType[index]].length; j++) {
        const element = shipperQuotes[chargesType[index]][j];
        tempData[`chargeType${tempQuoteArr.length}`] = chargesType[index]
        tempData[`chargeName${tempQuoteArr.length}`] = element.name
        tempData[`chargeTime${tempQuoteArr.length}`] = element.time
        tempData[`chargeDistance${tempQuoteArr.length}`] = element.distance
        tempData[`chargeAmount${tempQuoteArr.length}`] = element.amount
        tempData[`chargeAmountUnit${tempQuoteArr.length}`] = element.currency
        tempData[`chargeco2${tempQuoteArr.length}`] = element.co2
        tempQuoteArr.push(null)
      }
    }
    tempData["offerValidTill"] = shipperQuotesObj["offerValidTill"]
    tempData["oceanFreightDays"] = shipperQuotesObj["oceanFreightDays"]
    tempData["molType"] = shipperQuotesObj["molType"]
    tempData["quantityVaryIndexPercentage"] = shipperQuotesObj["quantityVaryIndexPercentage"]
    tempData["loadingType"] = shipperQuotesObj["loadingType"]
    tempData["unloadingType"] = shipperQuotesObj["unloadingType"]
    if (tempQuoteArr.length) {
      setData({ ...data, ...tempData })
      setQuoteArr([...tempQuoteArr])
    }
  }

  useEffect(() => {
    if (applicationNo) {
      setRedirectedApplication()
    }
    getContractList()
  }, [])

  async function getContractList() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getContracts', {
      "currentPage": 1,
      "resultPerPage": 1000,
      "userId": userId,
      "type_id": userTypeId,
      "gridOnly": true
    })
    // console.log("apiResppppppppppppppppppppppppppppppppp", apiResp);
    if (apiResp?.contractdata?.length) {
      setContractList(apiResp?.contractdata)
    }

    call('get', 'getcurrencylist').then((result) => {
      setcurrencyData(result)
    }).catch((e) => {
      console.log('error in getcurrencylist', e);
    })
    setshowLoader(false)
  }

  async function setRedirectedApplication() {
    setshowLoader(true)
    let tempShipmentQuoteData = JSON.parse(ShipmentQuoteDetails?.shipperQuotes || "[]")
    let shipperApiResp = await call('POST', "getShippers", {})

    for (let index = 0; index < tempShipmentQuoteData.length; index++) {
      const element = tempShipmentQuoteData[index];
      let tempShipperDetails = shipperApiResp.find(i => {
        if (i.id / 1 == element.shipper_id / 1) {
          return true
        }
      })
      element["logo"] = tempShipperDetails?.["user_avatar"] || null
      element["name"] = tempShipperDetails?.["name"] || null
    }

    setShipmentQuoteData([...tempShipmentQuoteData])
    setData({
      ...data,
      ...ShipmentQuoteDetails.details
    })
    setshowLoader(false)
  }

  console.log("shipmentquotedataaaaaaaaa", shipmentQuoteData);

  useEffect(() => {
    getPorts()
  }, [])

  async function getPorts() {
    setshowLoader(true)
    let apiResp = await call('POST', 'getOldPortMaster', {})
    // console.log("aaaaaaaaaaaaaaaaaaaaaaaa", apiResp);
    let tempPorts = apiResp
    for (let index = 0; index < tempPorts.length; index++) {
      tempPorts[index][`html`] = <div>
        <ReactCountryFlag
          countryCode={tempPorts[index].sortname}
          style={{ width: '20px', height: '20px', marginRight: '1rem' }} svg />
        <label className="font-size-12 font-wt-500" >{`${tempPorts[index]["port_name"]} ${tempPorts[index]["name"] ? `(${tempPorts[index]["name"]})` : ''}`}
        </label>
      </div>
    }
    setshowLoader(false)
    setPorts([...tempPorts])
  }

  const handleFilterOptions = (typedInput, name) => {
    // console.log("typedInput", typedInput);
    let filteredPorts = []
    if (name.includes("Port")) {
      if (typedInput) {
        filteredPorts = ports.filter((i) => {
          if (i.port_name.toLowerCase().includes(typedInput.toLowerCase()) || i.name.toLowerCase().includes(typedInput.toLowerCase())) {
            return true
          }
        })
      }
      else {
        filteredPorts = ports
      }
      setPorts([...filteredPorts])
    }
  }

  const handleChange = async (event) => {
    if (event.persist) {
      event.persist()
    }
    setData({ ...data, [event.target.name]: event.target.value })
    setErrors({ ...errors, [event.target.name]: "" })
  }

  console.log(data, "dataaaaaaaaa")

  const sendChatMsg = (file, parties) => {

    const formdata = new FormData()
    formdata.append('senderId', userId)
    if (message) {
      formdata.append('textMessage', message)
    }
    if (file) {
      formdata.append('docs', file)
    }
    formdata.append('shipmentBookingId', selectedChat.shipmentBookingId)
    formdata.append("includeAdmins", true)
    formdata.append("receiverId", selectedChat.receiverId)
    formdata.append("receiverParties", parties.join(","))
    formdata.append("traderActiveType", selectedChat.traderActiveType)

    call('POST', 'sendChatMessageV2', formdata).then((result) => {
      console.log('success in sendChatMessageV2 ', result)
      getChatDetails({
        chat_room_id: result.id,
        loggedInUser: userId
      })
    })
  }

  const getChatDetails = (reqObj) => {
    call('POST', 'getChatsByUser', reqObj).then(async result => {
      let finalArr = []
      for (let i = 0; i <= result.length - 1; i++) {
        let obj = result[i]
        const element = result[i]
        if (element.docs) {
          obj["chatFile"] = await getDocDetails(parseInt(element.docs))
        }
        finalArr.push(obj)
      }
      setChatList(finalArr)
    }).catch(e => {
      console.log('error in getChatsByUser', e)
    })
  }

  const onPurchasePlan = async (plan_id, plan_amount_usd, currency, shipper_id, booking_data) => {
    try {
      let reqObj = {
        plan_id,
        plan_amount_usd,
        userId,
        currency,
        forShipping: true,
        shipper_id,
        booking_data,
        userEmail
      }
      const result = await call('POST', booking_data.payemntType === "Pay With Invoice" ? 'sendDraftInvoice' : 'createPaymentlink', reqObj)
      if (booking_data.payemntType != "Pay With Invoice") {
        window.location = `${result}&forShipping=true`
      }
      else {
        toggleBookingPopupForm({ show: false, data: {} })
        toastDisplay("Request raised successfully, kindly check your mail", "success")
      }
    } catch (e) {
      console.log('error in createPaymentlink', e)
    }
  }

  return (
    <div>

      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      {selectedChat.isChatOpen &&
        <div className="chatboxDivFixed">
          <ChatBoxPopUp2
            forShipmentBooking={true}
            clientType={selectedChat.traderActiveType}
            chatList={chatList}
            user_avatar={selectedChat.logo}
            userId={selectedChat.userId}
            receiverName={selectedChat.receiverName}
            parties={selectedChat.parties}
            userTokenDetails={userTokenDetails}
            onChatSend={(file, parties) => sendChatMsg(file, parties)}
            message={message}
            setTextMsg={setTextMsg}
            reloadChatList={() => getChatDetails({
              chat_room_id: selectedChat.chatRoomId,
              loggedInUser: userId
            })}
            onPopupClose={() => {
              setSelectedChat({
                receiverName: '',
                receiverId: '',
                userId: userId,
                isChatOpen: false,
                logo: ''
              })
              setRefresh(refresh + 1)
            }}
          />
        </div>
      }

      {bookingPopupForm.show ? (
        <FinanceInvoiceModal modalSize={"lg"} limitinvoice={bookingPopupForm.show} closeSuccess={() => {
          toggleBookingPopupForm({ show: false, data: {} })
        }}>
          <div>
            <label className='font-size-18 font-wt-600 w-100 text-left px-3 mb-4' ><u>Shipment Information</u></label>
            <div className='px-3' >
              <div
                className="d-flex row align-items-center"
              >
                <div className="col-4" >
                  <div>
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >Origin Port</label>
                    <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{ports.find(i => {
                      if (i.port_name === data.loadingPort) { return true }
                    })?.['html']}</label>
                  </div>
                </div>
                <div className="col-4">
                  <div>
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >Destination Port</label>
                    <label className="font-size-14 font-wt-600 textColorABABAB w-100" >{ports.find(i => {
                      if (i.port_name === data.unloadingPort) { return true }
                    })?.['html']}</label>
                  </div>
                </div>
                <div className="col-4">
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Delivery Mode</label>
                  <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.deliveryMode}</label>
                </div>
              </div>
              <div
                className="d-flex row align-items-center mt-4"
              >
                <div className="col-4" >
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Ready To Load</label>
                  <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.readyToLoad}</label>
                </div>
                <div className="col-4">
                  <label
                    className="font-size-14 font-wt-600 textColorABABAB w-100"
                  >Transportation By</label>
                  <label className="font-size-14 font-wt-600 textColorABABAB w-100" >{data.transportationBy}</label>
                </div>
                {data.containerTruckWagonUldType && data.transportationBy && (data.transportationBy.includes('FCL') || data.transportationBy.includes('FTL') ||
                  data.transportationBy.includes('FWL') || data.transportationBy.includes('ULD')) ?
                  <div className="col-4" >
                    <label
                      className="font-size-14 font-wt-600 textColorABABAB w-100"
                    >{data.transportationBy.includes('FCL') ? 'Container Type' :
                      data.transportationBy.includes('FTL') ? 'Truck Type' : data.transportationBy.includes('FWL') ? 'Wagon Type' : 'ULD Container Type'}</label>
                    <label className="font-size-14 font-wt-500 textColorABABAB w-100" >{data.containerTruckWagonUldType}</label>
                  </div> : null}
              </div>
            </div>
            <div className="my-5 px-3" >
              {shipmentBookingQsts.map((i, j) => {
                return (
                  <div style={{ height: '4.5rem' }} className="card d-flex flex-row m-0 p-0 align-items-center mt-4" >
                    <div className="w-10" >
                      <img src={`assets/images/ShipmentBookingQst${j + 1}.svg`} />
                    </div>
                    <label className="font-size-16 font-wt-500 w-60" >{i.name}</label>
                    <div className="d-flex align-items-center w-30 justify-content-center" >
                      <img
                        onClick={() => {
                          toggleBookingPopupForm({
                            show: true, data: { ...bookingPopupForm.data, [i.name]: true }, i: bookingPopupForm.i,
                            item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                          })
                        }}
                        className="cursor" src={bookingPopupForm.data[i.name] ? "assets/images/radio-Select.svg" :
                          "assets/images/radio-NonSelect.svg"} />
                      <label className="font-size-14 font-wt-600 ml-3 mr-4 pt-1" >Yes</label>
                      <img
                        onClick={() => {
                          toggleBookingPopupForm({
                            show: true, data: { ...bookingPopupForm.data, [i.name]: false }, i: bookingPopupForm.i,
                            item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                          })
                        }} className="cursor" src={!bookingPopupForm.data[i.name] ? "assets/images/radio-Select.svg" :
                          "assets/images/radio-NonSelect.svg"} />
                      <label className="font-size-14 font-wt-600 ml-3 pt-1" >No</label>
                    </div>
                  </div>
                )
              })}
            </div>
            <label className='font-size-18 font-wt-600 w-100 text-left px-3 mb-3' ><u>Payment Information</u></label>
            <label className='font-size-16 font-wt-500 w-100 text-left px-3'>Choose payment method below</label>

            <div className="d-flex row mb-3 px-3 my-3" >
              {paymentTypes.map((i, j) => {
                return (
                  <div
                    onClick={() => {
                      toggleBookingPopupForm({
                        show: true, data: { ...bookingPopupForm.data, payemntType: i.name }, i: bookingPopupForm.i,
                        item: bookingPopupForm.item, totalChargesLabel: bookingPopupForm.totalChargesLabel
                      })
                    }}
                    style={i.name === bookingPopupForm.data.payemntType ? { border: "3px solid #B9EFFF", borderRadius: '10px' } :
                      {
                        boxShadow: "0px 1px 6px #ccc",
                        borderRadius: "10px"
                      }}
                    className="w-30 mx-2 d-flex align-items-center justify-content-center cursor" >
                    <img src={i.img} className="mr-2 cursor" />
                    <label className="font-size-13 font-wt-500 text-dark pt-2 cursor" >{i.name}</label>
                  </div>
                )
              })}
            </div>

            <div className="px-4 d-flex row mt-5 justify-content-start" >
              <button type="button"
                isDisabled={!bookingPopupForm.data.payemntType}
                onClick={() => {
                  onPurchasePlan(bookingPopupForm.item.applicationId, bookingPopupForm.totalChargesLabel.split(" ")[1],
                    bookingPopupForm.totalChargesLabel.split(" ")[0], bookingPopupForm.i.shipper_id, bookingPopupForm.data)
                }}
                className={` border-0 my-2 text-white enableQuotebtn  w-30`}>
                {bookingPopupForm.data.payemntType === "Pay With Invoice" ? "Book Now" : "Pay Now"}
              </button>
            </div>

          </div>
        </FinanceInvoiceModal>
      ) : null}

      <div className="container-fluid">
        <div className="row">
          {!hideSideBarAndHeader && (
            <SideBarV2
              state={applicationNo ? 'ShipmentBookingQuotes' : "ShipmentBookingMarketPlace"}
              userTokenDetails={userTokenDetails}
            />
          )}
          <main role="main" className={`ml-sm-auto ${navToggleState.status ? "expanded-right" : ""} ${hideSideBarAndHeader ? "col-lg-12" : "col-lg-10"}`} id="app-main-div">
            {/* <HeaderV2
              title={applicationNo ? 'Logistic > Booking > Quotes' : "Logistic > Spot Rate"}
              userTokenDetails={userTokenDetails} /> */}

            {!hideSideBarAndHeader && (
              <HeaderV2
                title={applicationNo ? 'Logistic > Booking > Quotes' : "Logistic > Spot Rate"}
                userTokenDetails={userTokenDetails}
              />
            )}


            <div>
              <div className='card shipmentBookingSearchCard d-flex py-2 flex-row'>
                <div
                  style={{ borderRight: '1px solid #ABABAB' }}
                  className="w-50 px-3" >
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >ORIGIN & DESTINATION PORT {astrix}</label>
                  <div
                    className="d-flex row align-items-center"
                  >
                    <div className="w-45" >
                      <MultipleSelectForTable
                        isDisabled={applicationNo}
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "loadingPort")
                        }}
                        Label={""}
                        Id={`loadingPort`}
                        optiondata={ports}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `loadingPort`, "port_name"))
                        }}
                        value={data[`loadingPort`] ? [data[`loadingPort`]] : []}
                        name={`loadingPort`}
                        labelKey={"html"}
                        valKey={"port_name"}
                        error={errors[`loadingPort`]}
                      />
                    </div>
                    <div className="w-10 d-flex justify-content-center" >
                      <img src="assets/images/twoWayArrow.png" className="cursor" />
                    </div>
                    <div className="w-45">
                      <MultipleSelectForTable
                        isDisabled={applicationNo}
                        filterOption={() => true}
                        onInputChange={(e) => {
                          handleFilterOptions(e, "unloadingPort")
                        }}
                        Label={""}
                        Id={`unloadingPort`}
                        optiondata={ports}
                        onChange={(e) => {
                          handleChange(multiSelectEventHandler(e, `unloadingPort`, "port_name"))
                        }}
                        value={data[`unloadingPort`] ? [data[`unloadingPort`]] : []}
                        name={`unloadingPort`}
                        labelKey={"html"}
                        valKey={"port_name"}
                        error={errors[`unloadingPort`]}
                      />
                    </div>

                  </div>
                </div>

                <div className="px-3" style={{ width: '16%', borderRight: '1px solid #ABABAB' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >READY TO LOAD {astrix}</label>
                  <div className="mt-2" >
                    <InputForTable isDisabled={applicationNo} type={"date"} name={"readyToLoad"} value={data.readyToLoad} onChange={handleChange} error={errors.readyToLoad} />
                  </div>
                </div>

                <div className="w-15 px-3" style={{ borderRight: '1px solid #ABABAB' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >DELIVERY MODE {astrix}</label>
                  <div className="mt-0"  >
                    <MultipleSelectForTable
                      isDisabled={applicationNo}
                      Label={""}
                      Id={`deliveryMode`}
                      optiondata={DeliveryMode}
                      onChange={(e) => {
                        handleChange(multiSelectEventHandler(e, `deliveryMode`, "name"))
                      }}
                      value={data[`deliveryMode`] ? [data[`deliveryMode`]] : []}
                      name={`deliveryMode`}
                      labelKey={"name"}
                      valKey={"name"}
                      error={errors[`deliveryMode`]}
                    />
                  </div>
                </div>

                <div className="px-3" style={{ width: '19%' }}>
                  <label
                    className="font-size-14 font-wt-500 textColorABABAB"
                  >Commodity</label>
                  <div className="mt-2"  >
                    <InputForTable name={"commodity"} value={data.commodity} onChange={handleChange} error={errors.commodity} />
                  </div>
                </div>
                {applicationNo ? null :
                  <div className="w-auto mt-2 px-3" >
                    <img src="assets/images/shipmentSearchIcon.png" className="cursor" />
                  </div>}
              </div>

              <div className="row d-flex mt-4" >
                {applicationNo ? null :
                  <label className="font-size-16 font-wt-500" ><u>Search Results</u></label>}
                <div style={{ width: '22%' }} >
                  {applicationNo ?
                    <div className="card py-4" >
                      <div className="mx-3"
                      >
                        {data['unitSystem'] ?
                          <div className="mb-3">
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >Unit System</label>
                            <MultipleSelectForTable
                              isDisabled={true}
                              Id={`unitSystem`}
                              optiondata={unitSystem}
                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `unitSystem`, "name"))
                              }}
                              value={data[`unitSystem`] ? [data[`unitSystem`]] : []}
                              name={`unitSystem`}
                              labelKey={"name"}
                              valKey={"name"}
                              error={errors[`unitSystem`]}
                            />
                          </div> : null}
                        {data[`commodityContract`] ?
                          <div className="mb-3">
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >Commodity Contract</label>
                            <MultipleSelectForTable
                              Label={''}
                              isDisabled={true}
                              Id={`commodityContract`}
                              optiondata={contractList}
                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `commodityContract`, "contract_number"))
                              }}
                              value={data[`commodityContract`] ? [data[`commodityContract`]] : []}
                              name={`commodityContract`}
                              labelKey={"contract_name"}
                              valKey={"contract_number"}
                              error={errors[`commodityContract`]}
                            />
                          </div>
                          : null}
                        {data[`cargoType`] ?
                          <div className="mb-3" >
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >Cargo Type</label>
                            <div className="position-relative" >
                              <MultipleSelectForTable
                                isDisabled={true}
                                Label={''}
                                Id={`cargoType`}
                                optiondata={cargoType}
                                value={data[`cargoType`] ? [data[`cargoType`]] : []}
                                name={`cargoType`}
                                labelKey={"name"}
                                valKey={"name"}
                                error={errors[`cargoType`]}
                              />
                            </div>
                          </div> : null}
                        {data.transportationBy ?
                          <div className="mb-3">
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >Transportation By</label>
                            <div className="position-relative" >
                              <MultipleSelectForTable
                                isDisabled={true}
                                Label={''}
                                Id={`transportationBy`}
                                optiondata={data.deliveryMode === "Sea" ? seaTransportation :
                                  data.deliveryMode === "Air" ? airTransportation : data.deliveryMode === "Land" ? landTransportation : []}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `transportationBy`, "name"))
                                }}
                                value={data[`transportationBy`] ? [data[`transportationBy`]] : []}
                                name={`transportationBy`}
                                labelKey={"name"}
                                valKey={"name"}
                                error={errors[`transportationBy`]}
                              />
                            </div>
                          </div> : null}
                        {data.shipType ? <> <div className="mb-3">
                          <label
                            className="font-size-14 font-wt-500 textColorABABAB"
                          >Ship Type</label>
                          <div className="position-relative" >
                            <MultipleSelectForTable
                              isDisabled={true}
                              Label={''}
                              Id={`shipType`}
                              optiondata={shipType}
                              onChange={(e) => {
                                handleChange(multiSelectEventHandler(e, `shipType`, "name"))
                              }}
                              value={data[`shipType`] ? [data[`shipType`]] : []}
                              name={`shipType`}
                              labelKey={"name"}
                              valKey={"name"}
                              error={errors[`shipType`]}
                            />
                          </div>
                        </div>
                          <div className="mb-3" >
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >{`Loading Rate ${data.weightUnit}/day`}</label>
                            <InputForTable type={"number"}
                              label={''} name={'loadingRate'}
                              value={data.loadingRate} error={errors.loadingRate} onChange={handleChange} />
                          </div>
                          <div className="mb-3" >
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >{`Discharging Rate ${data.weightUnit}/day`}</label>
                            <InputForTable type={"number"}
                              label={`Discharging Rate ${data.weightUnit}/day`} name={'dischargeRate'}
                              value={data.dischargeRate} error={errors.dischargeRate} onChange={handleChange} />
                          </div>
                        </> : null}
                        {data.containerTruckWagonUldType && data.transportationBy && (data.transportationBy.includes('FCL') || data.transportationBy.includes('FTL') ||
                          data.transportationBy.includes('FWL') || data.transportationBy.includes('ULD')) ? <>
                          <div className="mb-3">
                            <label
                              className="font-size-14 font-wt-500 textColorABABAB"
                            >{data.transportationBy.includes('FCL') ? 'Container Type' :
                              data.transportationBy.includes('FTL') ? 'Truck Type' : data.transportationBy.includes('FWL') ? 'Wagon Type' : 'ULD Container Type'}</label>
                            <div className="position-relative" >
                              <MultipleSelectForTable
                                isDisabled={true}
                                Id={`containerTruckWagonUldType`}
                                optiondata={data.transportationBy.includes('FCL') ? shipContainerType : data.transportationBy.includes('FTL') ? truckType :
                                  data.transportationBy.includes('FWL') ? wagonType : data.transportationBy.includes('ULD') ? uldType : []}
                                onChange={(e) => {
                                  handleChange(multiSelectEventHandler(e, `containerTruckWagonUldType`, "name"))
                                }}
                                value={data[`containerTruckWagonUldType`] ? [data[`containerTruckWagonUldType`]] : []}
                                name={`containerTruckWagonUldType`}
                                labelKey={"name"}
                                valKey={"name"}
                                error={errors[`containerTruckWagonUldType`]}
                              />
                            </div>
                          </div>
                          <div className="mb-3" >
                            <label className="font-size-14 font-wt-500 textColorABABAB">{"Quantity of" + (data.transportationBy.includes("FCL") ? ' Container' :
                              data.transportationBy.includes('FTL') ? ' Truck' : data.transportationBy.includes('FWL') ? ' Wagon' : ' ULD')}</label>
                            <InputForTable type={"number"}
                              name={'containerTruckWagonUldTypeQuantity'}
                              value={data.containerTruckWagonUldTypeQuantity} error={errors.containerTruckWagonUldTypeQuantity} onChange={handleChange} />
                          </div>
                        </> : null}
                        {data.weight ?
                          <div className="mb-3" >
                            <label className="font-size-14 font-wt-500 textColorABABAB">{`Weight in ${data.weightUnit}`}</label>
                            <InputForTable
                              isDisabled={true} type={"number"} name={'weight'}
                              value={data.weight} error={errors.weight} onChange={handleChange} />
                          </div> : null}
                        {(data.transportationBy && !data.transportationBy.includes('FCL') &&
                          !data.transportationBy.includes('FTL') && !data.transportationBy.includes('FWL') && !data.transportationBy.includes('ULD')
                          && !data.transportationBy.includes('Bulk')) || data.deliveryMode === 'Auto' ?
                          <div className="mb-3" >
                            <label className="font-size-14 font-wt-500 textColorABABAB">{`Volume in ${data.volumeUnit}`}</label>
                            <div className="" >
                              <InputForTable isDisabled={true} label={`Volume in ${data.volumeUnit}`} type={"number"} name={'volume'}
                                value={data.volume} error={errors.volume} onChange={handleChange} />
                            </div>
                          </div> : null}
                        <div className="mb-3" >
                          <label className="font-size-14 font-wt-500 textColorABABAB">{'Inco Terms'}</label>
                          <MultipleSelectForTable isDisabled={true}
                            Id={`incoTerms`}
                            optiondata={IncoTerms}
                            onChange={(e) => {
                              handleChange(multiSelectEventHandler(e, `incoTerms`, "name"))
                            }}
                            value={data[`incoTerms`] ? [data[`incoTerms`]] : []}
                            name={`incoTerms`}
                            labelKey={"name"}
                            valKey={"name"}
                            error={errors[`incoTerms`]}
                          />
                        </div>

                        <div className="mb-3">
                          <label className="font-size-14 font-wt-500 textColorABABAB">{'Ready to Load'}</label>
                          <InputForTable isDisabled={true}
                            type={"date"}
                            onChange={handleChange}
                            value={data['readyToLoad']}
                            name={`readyToLoad`}
                            error={errors[`readyToLoad`]}
                          />
                        </div>

                        <div className="" >
                          <label className="font-size-14 font-wt-500 textColorABABAB">{"Total Shipment Value"}</label>
                          <InputWithSelectForTable isDisabled={true} type={"number"}
                            selectData={currencyData}
                            selectName={"commodityPriceUnit"} selectValue={data.commodityPriceUnit}
                            optionLabel={"code"} optionValue={'code'}
                            name={'commodityPrice'} value={data.commodityPrice}
                            error={errors.commodityPrice || errors.commodityPriceUnit}
                            onChange={handleChange} />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="card py-3" >
                      <div
                        style={{ borderBottom: '2px solid #D5D5D5' }}
                        className="d-flex mx-4 pb-2" >
                        <div>
                          <img src="assets/images/filter.png" /></div>
                        <label className="font-size-16 font-wt-500 ml-3" >Filter</label>
                      </div>

                      <div className="py-4 mx-4"
                        style={{ borderBottom: '2px solid #D5D5D5' }}>
                        <label className="font-size-16 font-wt-600" >Container Type</label>
                        {containerTypes.map((i, j) => {
                          return (
                            <div>
                              <img
                                onClick={() => {
                                  setData({ ...data, containerType: i.name })
                                }}
                                className="mr-3 cursor" src={`assets/images/${data.containerType === i.name ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                              <label className="font-size-14 font-wt-400">{i.name}</label>
                            </div>
                          )
                        })}
                      </div>

                      {/* <div className="py-4 mx-4"
                      style={{ borderBottom: '2px solid #D5D5D5' }}>
                      <label className="font-size-16 font-wt-600" >Included Services</label>
                      {containerTypes.map((i, j) => {
                        return (
                          <div>
                            <img className="mr-3" src={`assets/images/${data.containerType === i.name ? 'bullet_box' : 'empty_bullet_box'}.svg`} />
                            <label className="font-size-14 font-wt-400">{i.name}</label>
                          </div>
                        )
                      })}
                    </div> */}

                    </div>}
                </div>

                <div style={{ width: '78%' }} >
                  {shipmentQuoteData?.length ?
                    shipmentQuoteData.map((i, j) => {
                      let item = ShipmentQuoteDetails
                      console.log(item, "itemmm")
                      let chatRoomIds = item.chatRoomIds?.split(",") || []
                      let chatRoomUsers = item.chatRoomUsers?.split(",") || []
                      let chatRoomUnreadMsgCount = item.chatRoomUnreadMsgCount?.split(",") || []

                      let openChatRoomIndx = null
                      chatRoomUsers.forEach((u, i) => {
                        if (u?.split("::")[5] / 1 == i.shipper_id / 1) {
                          openChatRoomIndx = i
                        }
                      })

                      let totalChargesLabel = getTotalLogisticAmount(i.charges)

                      return (
                        <div className="card row d-flex flex-row py-3">
                          <div
                            className="px-4"
                            style={{
                              borderRight: "1px solid #ABABAB",
                              width: "48%",
                            }}
                          >
                            <div className="d-flex justify-content-center mb-3">
                              {i.logo ? (
                                <img
                                  style={{
                                    maxWidth: "8rem",
                                    maxHeight: "2rem",
                                  }}
                                  className="mx-2"
                                  src={avatarUrl(i.logo)}
                                  alt=""
                                />
                              ) : null}
                              <label className="font-size-16 font-wt-400">
                                {i.name}
                              </label>
                              <div className="mx-2">
                                <img
                                  className="cursor"
                                  onClick={async () => {
                                    let reqObj = {
                                      senderId: userId,
                                      receiverId: i.shipper_id,
                                      textMessage: "Hii",
                                      chat_room_name:
                                        "CHAT" + new Date().getTime(),
                                      includeAdmins: true,
                                      shipmentBookingId: item.applicationId,
                                      receiverParties: i.shipper_id,
                                      dontSendInitialMsg: true,
                                      traderActiveType:
                                        item.details.traderActiveType,
                                    };
                                    setshowLoader(true);
                                    let apiResp = await call(
                                      "POST",
                                      "sendChatMessageV2",
                                      reqObj
                                    );
                                    setshowLoader(false);
                                    getChatDetails({
                                      chat_room_id: apiResp.id,
                                      loggedInUser: userId,
                                    });
                                    setSelectedChat({
                                      chatRoomId: apiResp.id,
                                      receiverName: `${i.name} - ${chatRoomIds[openChatRoomIndx] ||
                                        apiResp.id
                                        }`,
                                      shipmentBookingId: item.applicationId,
                                      parties:
                                        chatRoomUsers[openChatRoomIndx] ||
                                        apiResp.parties,
                                      userId: userId,
                                      isChatOpen: true,
                                      receiverId: i.shipper_id,
                                      traderActiveType:
                                        item.details.traderActiveType,
                                    });
                                  }}
                                  src={
                                    chatRoomIds[openChatRoomIndx]
                                      ? `assets/images/comment_filled.png`
                                      : `assets/images/chat.png`
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label className="w-50">
                                {
                                  ports.find((i) => {
                                    if (i.port_name === data.loadingPort) {
                                      return true;
                                    }
                                  })?.["html"]
                                }
                              </label>
                              <label className="w-50 text-right">
                                {
                                  ports.find((i) => {
                                    if (i.port_name === data.unloadingPort) {
                                      return true;
                                    }
                                  })?.["html"]
                                }
                              </label>
                              <div className="d-flex align-items-center">
                                {stops.map((k, l) => {
                                  return (
                                    <>
                                      <img
                                        src={
                                          k.isStop
                                            ? "assets/images/shipmentStopBlue.png"
                                            : "assets/images/shipmentStopGrey.png"
                                        }
                                      />
                                      <div
                                        style={{
                                          height: "2px",
                                          width: k.distInPer,
                                          backgroundColor: "#5CB8D3",
                                        }}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            {stops.length - 2 ? (
                              <label
                                className="fpnt-size-14 font-wt-400 text-center w-100 mt
                          -2"
                              >{`${stops.length - 2} Stops`}</label>
                            ) : null}
                          </div>
                          <div
                            style={{
                              borderRight: "1px solid #ABABAB",
                              width: "29%",
                            }}
                            className="  d-flex align-items-center justify-content-center"
                          >
                            <div>
                              <div className=" d-flex  w-100 flex-row">
                                <div>
                                  <img src="assets/images/timerLine.png" />
                                </div>
                                <label className="font-size-14 font-wt-400 w-50 pl-2 pr-4">
                                  Valid Till
                                </label>
                                <label className="font-size-14 font-wt-600 w-50 text-right ">
                                  {i.offerValidTill}
                                </label>
                              </div>
                              <div className=" d-flex  w-100 flex-row my-2">
                                <div>
                                  <img src="assets/images/leaf.png" />
                                </div>
                                <label className="font-size-14 font-wt-400 w-50 pl-2 pr-5">
                                  CO2
                                </label>
                                <label className="font-size-14 font-wt-600 w-50 text-right">
                                  {`${getCO2EmissionTotal(i.charges)} Kg`}
                                </label>
                              </div>
                              <div className=" d-flex  w-100 flex-row">
                                <div>
                                  <img src="assets/images/freight_icon.png" />
                                </div>
                                <label className="font-size-14 font-wt-400 w-90 pl-2 pr-4">
                                  Ocean Freight (Days)
                                </label>
                                <label className="font-size-14 font-wt-600 w-10 text-right">
                                  {i.oceanFreightDays}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ width: "23%" }}
                          >
                            <div>
                              <div className="d-flex align-items-center w-100 justify-content-center ">
                                <label className="text-color1 font-size-24 font-wt-600 pt-2 pr-2">
                                  {`${totalChargesLabel}`}
                                </label>
                                <img src="assets/images/greenLeafWithBorder.png" />
                              </div>
                              <div className="d-flex justify-content-center">
                                {/* <button type="button"
                                disabled={userTokenDetails.main_user_name === "Admin FOB"}
                                  onClick={() => {
                                    if (item.selectedShipper / 1 != i.shipper_id) {
                                      toggleBookingPopupForm({ show: true, data: {}, i, item, totalChargesLabel })
                                      // onPurchasePlan(item.applicationId, totalChargesLabel.split(" ")[1], totalChargesLabel.split(" ")[0], i.shipper_id)
                                    }
                                  
                                  }}
                                  className={` border-0 my-2 text-white enableQuotebtn `}
                                  
                                  >
                                  
                                  {item.selectedShipper / 1 == i.shipper_id ? "BOOKED" : "BOOK NOW"}
                                </button> */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (
                                      userTokenDetails.main_user_name !==
                                      "Admin FOB"
                                    ) {
                                      if (
                                        item.selectedShipper / 1 !==
                                        i.shipper_id
                                      ) {
                                        toggleBookingPopupForm({
                                          show: true,
                                          data: {},
                                          i,
                                          item,
                                          totalChargesLabel,
                                        });
                                        onPurchasePlan(item.applicationId, totalChargesLabel.split(" ")[1], totalChargesLabel.split(" ")[0], i.shipper_id)
                                      }
                                    }
                                    if (item.selectedShipper / 1 === i.shipper_id) {
                                      setShipmentBooked(true);
                                    }
                                  }}
                                  className={`border-0 my-2 text-white enableQuotebtn ${userTokenDetails.main_user_name ===
                                    "Admin FOB"
                                    ? "cursor-not-allowed opacity-50"
                                    : ""
                                    }`}

                                >
                                  {item.selectedShipper / 1 === i.shipper_id
                                    ? "BOOKED"
                                    : "BOOK NOW"}
                                  {/* {item.shipperAction} */}
                                </button>
                              </div>
                              <label
                                onClick={() => {
                                  loadShipperQuotes(i.shipper_id);
                                  toggleViewMore(
                                    viewMore === null ? i.shipper_id : null
                                  );
                                }}
                                className="font-size-13 font-wt-400 cursor w-100 text-center"
                              >
                                <u>
                                  {viewMore === i.shipper_id
                                    ? `View Less`
                                    : `View More`}
                                </u>
                              </label>
                            </div>
                          </div>
                          {viewMore === i.shipper_id ? (
                            <div className="mt-3">
                              <label className="font-size-16 font-wt-500">
                                Charges Details
                              </label>

                              <NewTablev2
                                stickTableHeaderToTop
                                showSideBorders={true}
                                columns={[
                                  {
                                    subColumns: "Type",
                                    subColumnStyle: { width: "15%" },
                                  },
                                  {
                                    subColumns: "Name",
                                    subColumnStyle: { width: "15%" },
                                  },
                                  {
                                    subColumns: "Time (days)",
                                    subColumnStyle: { width: "15%" },
                                  },
                                  {
                                    subColumns: "Distance (km)",
                                    subColumnStyle: { width: "15%" },
                                  },
                                  {
                                    subColumns: "Rate/Fees",
                                    subColumnStyle: { width: "20%" },
                                  },
                                  {
                                    subColumns: "co2 Release (kg)",
                                    subColumnStyle: { width: "15%" },
                                  },
                                ]}
                              >
                                <tr>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      MOL Type
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <SelectForTable
                                      optionLabel={"name"}
                                      optionValue={"name"}
                                      selectData={[
                                        { name: "MOLOO" },
                                        { name: "MOLCO" },
                                      ]}
                                      error={errors.molType}
                                      label={""}
                                      name={"molType"}
                                      value={data.molType}
                                    />
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      Quantity Vary Index (%)
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <InputForTable
                                      error={errors.quantityVaryIndexPercentage}
                                      label={""}
                                      type={"number"}
                                      name={"quantityVaryIndexPercentage"}
                                      value={data.quantityVaryIndexPercentage}
                                    />
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      Loading Type
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <SelectForTable
                                      optionLabel={"name"}
                                      optionValue={"name"}
                                      selectData={[
                                        { name: "Berth Loading" },
                                        { name: "Anchorage Loading" },
                                      ]}
                                      error={errors.loadingType}
                                      label={""}
                                      name={"loadingType"}
                                      value={data.loadingType}
                                    />
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      Unloading Type
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                  <td>
                                    <SelectForTable
                                      optionLabel={"name"}
                                      optionValue={"name"}
                                      selectData={[
                                        { name: "Berth Unloading" },
                                        { name: "Anchorage Unloading" },
                                      ]}
                                      error={errors.unloadingType}
                                      label={""}
                                      name={"unloadingType"}
                                      value={data.unloadingType}
                                    />
                                  </td>
                                  <td>
                                    <label className="font-size-12 font-wt-500">
                                      -
                                    </label>
                                  </td>
                                </tr>
                                {quoteArr.map((i, j) => {
                                  if (i === null) {
                                    return (
                                      <tr>
                                        <td>
                                          <InputForTable
                                            name={`chargeType${j}`}
                                            value={
                                              data[`chargeType${j}`] || "-"
                                            }
                                            error={errors[`chargeType${j}`]}
                                          />
                                        </td>
                                        <td>
                                          <InputForTable
                                            name={`chargeName${j}`}
                                            value={
                                              data[`chargeName${j}`] || "-"
                                            }
                                            error={errors[`chargeName${j}`]}
                                          />
                                        </td>
                                        <td>
                                          <InputForTable
                                            type={"number"}
                                            name={`chargeTime${j}`}
                                            value={
                                              data[`chargeTime${j}`] || "-"
                                            }
                                            error={errors[`chargeTime${j}`]}
                                          />
                                        </td>
                                        <td>
                                          <InputForTable
                                            type={"number"}
                                            name={`chargeDistance${j}`}
                                            value={
                                              data[`chargeDistance${j}`] || "-"
                                            }
                                            error={errors[`chargeDistance${j}`]}
                                          />
                                        </td>
                                        <td>
                                          <InputWithSelectForTable
                                            type={"number"}
                                            selectData={currencyData}
                                            selectName={`chargeAmountUnit${j}`}
                                            selectValue={
                                              data[`chargeAmountUnit${j}`]
                                            }
                                            optionLabel={"code"}
                                            optionValue={"code"}
                                            name={`chargeAmount${j}`}
                                            value={
                                              data[`chargeAmount${j}`] || "-"
                                            }
                                            error={
                                              errors[`chargeAmount${j}`] ||
                                              errors[`chargeAmountUnit${j}`]
                                            }
                                          />
                                        </td>
                                        <td>
                                          <InputForTable
                                            type={"number"}
                                            name={`chargeco2${j}`}
                                            value={data[`chargeco2${j}`] || "-"}
                                            error={errors[`chargeco2${j}`]}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                              </NewTablev2>
                            </div>
                          ) : null}
                        </div>
                      );
                    }) : <div className="pt-5 mt-5" >
                      <label className="font-size-16 font-wt-600 text-center w-100" >No Quotes Received</label>
                    </div>}

                </div>

              </div>
            </div>

          </main>
        </div >
      </div >
    </div >
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(ShipmentBookingMarketPlace)