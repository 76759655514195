import jsPDF from "jspdf";
import cryptoJs from "crypto-js";
import React from "react";
import call from "../service";
import { getUserDataFromCookie } from "./cookieHelper";
import { NewInput, NewSelect } from "./newInput";
import {
  Lcdetailsdata,
  lcTenorDD,
} from "../components/lcV2/applyforLimit/components/lcdiscountingcard";
import html2canvas from "html2canvas";
import { mergePDF, createPDF, pdfArrayToBlob } from "pdf-actions";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import toastDisplay from "./toastNotification";
import { companyTypes } from "../components/registration/newRegistration";

let secretKey =
  "5N-LzDy;LcKYHYgp^d]2Q59,}rR=|V&=3/Me,ugIuw]92<7cX@uEmE):+%|/#mA";

export const is_valid = (data) => {
  if (data && data.length) return true;
  return false;
};

// export const validate_number_field = str => {
//   return str.replace(/[^0-9\.]/g, "").replace(/(?<=\..*)\./g, '')
// }

export const validate_text_field = (str) => {
  return str.replace(/^\s?/, "");
};

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

const imageDimensionsOnA4 = (dimensions) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;
  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width,
      height:
        A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
    };
  }
  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor =
      (A4_PAPER_RATIO * dimensions.height) / dimensions.width;
    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;
    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }
  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export async function convertImageToPdf(imgDataUrl, imgFileName) {
  return new Promise((resolve, reject) => {
    try {
      let pdfFileName = imgFileName.split(".")[0] + ".pdf";
      let image = new Image();
      image.src = imgDataUrl;
      image.onload = function () {
        const doc = new jsPDF();
        doc.deletePage(1);
        const imageDimensions = imageDimensionsOnA4({
          width: image.width,
          height: image.height,
        });
        doc.addPage();
        doc.addImage(
          image.src,
          // Images are vertically and horizontally centered on the page.
          (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          imageDimensions.width,
          imageDimensions.height,
          "",
          "FAST"
        );
        // To view pdf in new tab
        // const test = doc.output('bloburl')
        // window.open(test, "_blank");
        const pdfBlob = doc.output("blob");
        resolve({
          file: new File([pdfBlob], pdfFileName, {
            type: pdfBlob["type"],
            lastModified: new Date(),
          }),
          dataUrl: doc.output("datauristring", { filename: pdfFileName }),
        });
      };
    } catch (error) {
      reject(false);
    }
  });
}

export const GetCache = (key) => {
  return JSON.parse(localStorage.getItem(key)) || {};
};

export const SetCache = (key, data_obj) => {
  localStorage.setItem(key, JSON.stringify({ ...GetCache(key), ...data_obj }));
};

export const ClearCache = (key) => {
  localStorage.removeItem(key);
};

export const encryptData = (strData) => {
  return cryptoJs.AES.encrypt(strData, secretKey).toString();
};

export const decryptData = (encStrData) => {
  try {
    let bytes = cryptoJs.AES.decrypt(encStrData, secretKey);
    console.log(bytes, "this is bytesss in decrypt-->>>>", bytes.toString(cryptoJs.enc.Utf8))
    return bytes.toString(cryptoJs.enc.Utf8);
  } catch (err) {
    return ""
  }

};

export const cargoTypeDD = [
  { name: "Break Bulk" },
  { name: "Bulk Cargo" },
  { name: "Oversize Load" },
  { name: "Liquid Cargo" },
  { name: "Gas" },
  { name: "Wheeled Carg" },
];

export const containerTypeDD = [
  { name: "20 Standard" },
  { name: "40 Standard" },
  { name: "40 High Cube" },
  { name: "45 High Cube" },
  { name: "20 Open Top" },
  { name: "40 Open Top" },
  { name: "20 Flatrack" },
  { name: "40 Flatrack" },
  { name: "20 Refrigerate" },
];

export const vesselTypeDD = [
  { name: "General Cargo" },
  { name: "Bulk Carriers" },
  { name: "Containership" },
  { name: "Tanker Market" },
  { name: "Specialized" },
];

export function calcHaversineDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export const modifi_currencies = [
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 25, name: "Yuan Renminbi", code: "CNY", symbol: "¥" },
];

export const downloadJSONFile = (jsonData, fileName) => {
  // Step 1: Generate JSON content
  const content = JSON.stringify(jsonData, null, 2); // Pretty-print with 2 spaces

  // Step 2: Convert content to Blob
  const blob = new Blob([content], { type: "application/json" });

  // Step 3: Create a downloadable link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || "data.json"; // Default filename if not provided
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const most_used_currencies_with_percentage = [
  { id: null, name: "%", code: "%", symbol: "%" },
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  { id: 4, name: "Pesos", code: "ARS", symbol: "$" },
  { id: 18, name: "Reais", code: "BRL", symbol: "R$" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 25, name: "Yuan Renminbi", code: "CNY", symbol: "¥" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 48, name: "Rupiahs", code: "IDR", symbol: "Rp" },
  { id: 53, name: "Yen", code: "JPY", symbol: "¥" },
  { id: 68, name: "Pesos", code: "MXN", symbol: "$" },
  { id: 87, name: "Rubles", code: "RUB", symbol: "py6" },
  { id: 89, name: "Riyals", code: "SAR", symbol: "﷼" },
  { id: 92, name: "Dollars", code: "SGD", symbol: "$" },
  { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  { id: 53, name: "WON", code: "KRW", symbol: "₩" },
  { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  { id: 103, name: "Lira", code: "TRY", symbol: "TL" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },
];

export const most_used_currencies = [
  { id: 47, name: "Rupees", code: "INR", symbol: "₹" },
  { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  { id: 4, name: "Pesos", code: "ARS", symbol: "$" },
  { id: 18, name: "Reais", code: "BRL", symbol: "R$" },
  { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  { id: 25, name: "Yuan Renminbi", code: "CNY", symbol: "¥" },
  { id: 11, name: "Euro", code: "EUR", symbol: "€" },
  { id: 48, name: "Rupiahs", code: "IDR", symbol: "Rp" },
  { id: 53, name: "Yen", code: "JPY", symbol: "¥" },
  { id: 68, name: "Pesos", code: "MXN", symbol: "$" },
  { id: 87, name: "Rubles", code: "RUB", symbol: "py6" },
  { id: 89, name: "Riyals", code: "SAR", symbol: "﷼" },
  { id: 92, name: "Dollars", code: "SGD", symbol: "$" },
  { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  { id: 53, name: "WON", code: "KRW", symbol: "₩" },
  { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  { id: 103, name: "Lira", code: "TRY", symbol: "TL" },
  { id: 19, name: "Pounds", code: "GBP", symbol: "£" },
  { id: 2, name: "Dollars", code: "USD", symbol: "$" },

  // { id: 44, name: "Dollars", code: "HKD", symbol: "$" },
  // { id: 22, name: "Dollars", code: "CAD", symbol: "$" },
  // { id: 6, name: "Dollars", code: "AUD", symbol: "$" },
  // { id: 63, name: "Switzerland Francs", code: "CHF", symbol: "CHF" },
  // { id: 95, name: "Rand", code: "ZAR", symbol: "R" },
  // { id: 74, name: "Dollars", code: "NZD", symbol: "$" }
];

export function toolTip(msg) {
  return (
    <span className="error-icon" flow="right" tooltip={msg}>
      i
    </span>
  );
}

export function toolTip2(msg) {
  return (
    <span className="help-icon" flow="right" tooltip={msg}>
      i
    </span>
  );
}

export const astrix = <span className="required-field text-danger">*</span>;

export function roundOfNumberInDecimal(no, pointAfterDecimal) {
  no = no / 1;
  if (no.toString().includes(".")) {
    return no.toFixed(pointAfterDecimal || 2);
  } else {
    return no;
  }
}

export async function getDocDetails(id, fileHash, isEximBank, clientType, includeFileData) {
  return new Promise((resolve, reject) => {
    try {
      let userTokenDetails = getUserDataFromCookie()
      call('POST', 'getDoc', { id, fileHash, typeId: userTokenDetails.type_id, isEximBank, clientType, includeFileData }).then((result) => {
        console.log("getDoc fileResult-->", result)
        if (result) {
          result["fromDb"] = true
          resolve(result)
        }
        else {
          resolve({})
        }
      }).catch(err => {
        resolve({})
      })
        .then((result) => {
          console.log("getDoc fileResult-->", result);
          if (result) {
            result["fromDb"] = true;
            resolve(result);
          } else {
            resolve({});
          }
        })
        .catch((err) => {
          resolve({});
        });
    } catch (error) {
      resolve({});
    }
  });
}

export function dataURItoBlobURL(base64Data) {
  // console.log("base64Data==============", base64Data);
  if (!base64Data.includes("base64")) {
    base64Data = "data:application/pdf;base64," + base64Data;
  }
  const byteCharacters = atob(base64Data.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  return URL.createObjectURL(blob);
}

export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  // console.log("dataURI==============", dataURI);
  if (!dataURI.includes("base64")) {
    dataURI = "data:application/pdf;base64," + dataURI;
  }
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const LCTypesObject = {
  sight_lc: "Sight LC",
  usance_lc: "Usance LC",
  b2bank_lc: "Back to back LC",
  green_clause_lc: "Green Clause LC",
  red_clause_lc: "Red Clause LC",
  transferable_lc: "Transferable LC",
};

export const LCPurposeObject = {
  lc_discounting: "LC discounting (International)",
  lc_confirmation: "LC confirmation (International)",
  lc_confirmation_discounting: "LC Confirmation & Discounting (International)",
  lc_discounting_domestic: "LC discounting (Domestic)",
  lc_confirmation_domestic: "LC confirmation (Domestic)",
  lc_confirmation_discounting_domestic:
    "LC Confirmation & Discounting (Domestic)",
  sblc: "SBLC",
};

export const LCPurposeObjectV2 = {
  lc_discounting: "Discounting (International)",
  lc_confirmation: "Confirmation (International)",
  lc_confirmation_discounting: "Confirmation & Discounting (International)",
  lc_discounting_domestic: "Discounting (Domestic)",
  lc_confirmation_domestic: "Confirmation (Domestic)",
  lc_confirmation_discounting_domestic: "Confirmation & Discounting (Domestic)",
  sblc: "SBLC",
};

export function getBGTotalOtherCharges(chargeArr, refAmt) {
  let totalCharges = 0;
  let referenceAmount = refAmt || 50000;
  if (chargeArr && chargeArr.length) {
    for (let index = 0; index < chargeArr.length; index++) {
      const element = chargeArr[index];
      if (element.amount && element.unit != "%") {
        totalCharges += element.amount / 1;
      } else if (element.amount) {
        totalCharges += (element.amount / 100) * referenceAmount;
      }
    }
  }
  return totalCharges;
}

export function getInvoiceTotalOtherCharges(chargeArr, refAmt) {
  let totalCharges = 0;
  let referenceAmount = refAmt || 50000;
  if (chargeArr && chargeArr.length) {
    for (let index = 0; index < chargeArr.length; index++) {
      const element = chargeArr[index];
      if (element.amount && element.unit != "%") {
        totalCharges += element.amount / 1;
      } else if (element.amount) {
        totalCharges += (element.amount / 100) * referenceAmount;
      }
    }
  }
  return totalCharges;
}

export function getWCTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0;
  let referenceAmount = refAmt || 50000;

  let getSlabDivider = (creditDays || 30) / 30;
  if (item.factoringRateCurrency === "%") {
    totalCharges +=
      (((item.factoringRate / 100) * referenceAmount) / 12) * getSlabDivider;
  } else {
    totalCharges += item.factoringRate;
  }
  if (item.interestRateCurrency === "%") {
    totalCharges +=
      (((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider;
  } else {
    totalCharges += item.interestRate;
  }
  if (item.setUpFeesCurrency === "%") {
    totalCharges +=
      (((item.setUpFees / 100) * referenceAmount) / 12) * getSlabDivider;
  } else {
    totalCharges += item.setUpFees;
  }
  totalCharges += getBGTotalOtherCharges(item.otherCharges, refAmt);
  return totalCharges / 1;
}

export function getBGTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0;
  let referenceAmount = refAmt || 50000;

  let getSlabDivider = (creditDays || 30) / 30;
  if (item.confirmationRateCurrency === "%") {
    totalCharges +=
      (((item.confirmationRate / 100) * referenceAmount) / 12) * getSlabDivider;
  } else {
    totalCharges += item.confirmationRate;
  }
  totalCharges += getBGTotalOtherCharges(item.otherCharges, refAmt);
  return totalCharges;
}

export function getInvoiceTotalCharges(item, refAmt, creditDays) {
  let totalCharges = 0;
  let referenceAmount = refAmt || 50000;
  let factoringFeesType = item.factoringFeesType || "Annually";
  let interestRateType = item.interestRateType || "Annually";

  let factoringFeesMultiplier = factoringFeesType === "Annually" ? 1 : 12;
  let interestRateMultiplier = interestRateType === "Annually" ? 1 : 12;

  let getSlabDivider = (creditDays || 30) / 30;

  // console.log("ssssssssssssssssssssss", factoringFeesMultiplier, interestRateMultiplier, getSlabDivider);

  if (item.factoringFeesCurrency === "%") {
    totalCharges +=
      ((((item.factoringFees * factoringFeesMultiplier) / 100) *
        referenceAmount) /
        12) *
      getSlabDivider;
  } else {
    totalCharges += item.factoringFees / 1;
  }
  totalCharges +=
    ((((item.interestRate * interestRateMultiplier) / 100) * referenceAmount) /
      12) *
    getSlabDivider;
  if (item.setupFeesCurrency === "%") {
    totalCharges += (item.setupFees / 100) * referenceAmount;
  } else {
    totalCharges += item.setupFees / 1;
  }
  totalCharges += getInvoiceTotalOtherCharges(item.otherCharges, refAmt);
  return totalCharges;
}

export function getInvoiceFinalChargesObj(contractAmt, creditDays, item) {
  let finalCharges = {
    factoring: 0,
    interest: 0,
    oneTime: 0,
    otherCharges: [],
    total: 0,
  };
  let referenceAmount = contractAmt;
  // let getSlabDivider = creditDays <= 30 ? 1 : (creditDays > 30 && creditDays <= 45) ? 8.11 : (creditDays > 45 && creditDays <= 60) ? 6 : (creditDays > 60 && creditDays <= 90) ? 4 : creditDays >= 90 ? 3 : null
  let getSlabDivider = creditDays / 30;
  console.log("getSlabDivider", creditDays, getSlabDivider);
  if (getSlabDivider) {
    if (item.factoringFeesCurrency === "%") {
      finalCharges["factoring"] =
        ((item.factoringFees / 100) * referenceAmount).toFixed(2) +
        " " +
        item.financeLimitCurrency +
        " (" +
        item.factoringFees +
        "%)";
      finalCharges["total"] += (item.factoringFees / 100) * referenceAmount;
    } else {
      finalCharges["factoring"] =
        item.factoringFees + " " + item.financeLimitCurrency;
      finalCharges["total"] += item.factoringFees / 1;
    }
    if (item.setupFeesCurrency === "%") {
      finalCharges["oneTime"] =
        ((item.setupFees / 100) * referenceAmount).toFixed(2) +
        " " +
        item.financeLimitCurrency +
        " (" +
        item.setupFees +
        "%)";
      finalCharges["total"] += (item.setupFees / 100) * referenceAmount;
    } else {
      finalCharges["oneTime"] =
        item.setupFees + " " + item.financeLimitCurrency;
      finalCharges["total"] += item.setupFees / 1;
    }
    finalCharges["interest"] =
      (
        (((item.interestRate / 100) * referenceAmount) / 12) *
        getSlabDivider
      ).toFixed(2) +
      " " +
      item.financeLimitCurrency +
      " (" +
      item.interestRate +
      "%)";
    finalCharges["total"] +=
      (((item.interestRate / 100) * referenceAmount) / 12) * getSlabDivider;
    if (item.otherCharges && item.otherCharges.length) {
      for (let index = 0; index < item.otherCharges.length; index++) {
        const element = item.otherCharges[index];
        if (element.unit === "%") {
          finalCharges["otherCharges"][index] = {
            ["OtherCharge" + (index + 1)]:
              ((element.amount / 100) * referenceAmount).toFixed(2) +
              " " +
              item.financeLimitCurrency +
              " (" +
              element.amount +
              "%)",
          };
          finalCharges["total"] += (element.amount / 100) * referenceAmount;
        } else {
          finalCharges["otherCharges"][index] = {
            ["OtherCharge" + (index + 1)]:
              element.amount + " " + item.financeLimitCurrency,
          };
          finalCharges["total"] += element.amount / 1;
        }
      }
    }
    finalCharges["total"] =
      finalCharges["total"].toFixed(2) + " " + item.financeLimitCurrency;
  }
  console.log("ccccccccccccccccccccccccc", finalCharges);
  return finalCharges;
}

export function getTotalOtherCharges(chargeArr) {
  let totalCharges = 0;
  let referenceAmount = 50000;
  for (let index = 0; index < chargeArr?.length; index++) {
    const element = chargeArr[index];
    if (element.amount && element.unit != "%") {
      totalCharges += element.amount / 1;
    } else if (element.amount) {
      totalCharges += (element.amount / 100) * referenceAmount;
    }
  }
  return totalCharges;
}

export function getTotalCharges(item) {
  let totalCharges = 0;
  let referenceAmount = 50000;
  if (item.giveConfirmationFees) {
    totalCharges += ((item.confirmationFees / 100) * referenceAmount) / 12;
  }
  if (item.giveDiscountingRate) {
    totalCharges += ((item.discountingRate / 100) * referenceAmount) / 12;
  }
  totalCharges += item.setupFees / 1;
  totalCharges += item.handlingCharges / 1;
  totalCharges += item.courierCharges / 1;
  totalCharges += getTotalOtherCharges(item.otherCharges);
  return totalCharges;
}

export let LcFieldsMaster = {
  lcNo: {
    title: "LC Number",
    component: NewInput,
    type: "text",
    name: "lcNo",
    value: "lcNo",
    error: "lcNo",
  },
  lcTenor: {
    title: "LC Tenor",
    component: NewInput,
    type: "number",
    name: "lcTenor",
    value: "lcTenor",
    error: "lcTenor",
  },
  lcType: {
    title: "LC Type",
    component: NewSelect,
    selectData: Lcdetailsdata,
    name: "lcType",
    value: "lcType",
    error: "lcType",
    optionLabel: "name",
    optionValue: "value",
  },
  shipmentFromCountry: {
    title: "Shipment from Country",
    component: NewSelect,
    selectData: [],
    name: "shipmentFromCountry",
    value: "shipmentFromCountry",
    error: "shipmentFromCountry",
    optionLabel: "name",
    optionValue: "sortname",
  },
  shipmentToCountry: {
    title: "Shipment to Country",
    component: NewSelect,
    selectData: [],
    name: "shipmentToCountry",
    value: "shipmentToCountry",
    error: "shipmentToCountry",
    optionLabel: "name",
    optionValue: "sortname",
  },
  portOfLoading: {
    title: "Port of Loading",
    component: NewSelect,
    selectData: [],
    name: "portOfLoading",
    value: "portOfLoading",
    error: "portOfLoading",
    optionLabel: "port_name",
    optionValue: "id",
  },
  portOfDischarge: {
    title: "Port of Discharge",
    component: NewSelect,
    selectData: [],
    name: "portOfDischarge",
    value: "portOfDischarge",
    error: "portOfDischarge",
    optionLabel: "port_name",
    optionValue: "id",
  },
  commodity: {
    title: "Commodity",
    component: NewInput,
    type: "text",
    name: "commodity",
    value: "commodity",
    error: "commodity",
  },
  expectedDateOfShipment: {
    title: "Expected date of shipment",
    component: NewInput,
    type: "date",
    name: "expectedDateOfShipment",
    value: "expectedDateOfShipment",
    error: "expectedDateOfShipment",
  },
  lcIssuingBankAddress: {
    title: "LC issuing bank address",
    component: NewInput,
    type: "text",
    name: "lcIssuingBankAddress",
    value: "lcIssuingBankAddress",
    error: "lcIssuingBankAddress",
  },
  lcIssuingBankSwiftCode: {
    title: "LC issuing bank SWIFT code",
    component: NewInput,
    type: "text",
    name: "lcIssuingBankSwiftCode",
    value: "lcIssuingBankSwiftCode",
    error: "lcIssuingBankSwiftCode",
  },
  countryOfOrigin: {
    title: "Country of origin",
    component: NewSelect,
    selectData: [],
    name: "countryOfOrigin",
    value: "countryOfOrigin",
    error: "countryOfOrigin",
    optionLabel: "name",
    optionValue: "sortname",
  },
  confirmingBankName: {
    title: "Confirming bank name",
    component: NewInput,
    type: "text",
    name: "confirmingBankName",
    value: "confirmingBankName",
    error: "confirmingBankName",
  },
  confirmingBankAddress: {
    title: "Confirming bank address",
    component: NewInput,
    type: "text",
    name: "confirmingBankAddress",
    value: "confirmingBankAddress",
    error: "confirmingBankAddress",
  },
  confirmingBankSwiftCode: {
    title: "Confirming bank SWIFT code",
    component: NewInput,
    type: "text",
    name: "confirmingBankSwiftCode",
    value: "confirmingBankSwiftCode",
    error: "confirmingBankSwiftCode",
  },
};

export const finLogoByUserId = {
  51: "assets/images/supplier-images/stenn-logo.png",
  972: "assets/images/Drip_logo.png",
  // "a.wakil@tradewindfinance.com": require("../../constants/assets/tradewind_logo.png"),
  748: "assets/images/supplier-images/modifi-logo.png",
  5035: "assets/images/supplier-images/modifi-logo.png",
};

export const extractFloatFromText = (text) => {
  if (text) {
    text = text.toString();
  }
  const regex = /[\d,]*\.\d+/;
  const match = text?.match(regex);
  if (match) {
    const numberString = match[0].replace(/,/g, ""); // Remove commas
    return parseFloat(numberString); // Convert to float
  } else {
    return null; // Return null if no match found
  }
};

export const extractNoFromTxt = (noWithTxt) => {
  if (!isNaN(noWithTxt)) {
    return noWithTxt;
  }
  if (noWithTxt) {
    let validNos = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
    noWithTxt = noWithTxt.split("");
    let temp = [];
    let isDotScanned = false;
    for (let index = 0; index < noWithTxt.length; index++) {
      const element = noWithTxt[index];
      if (validNos.includes(element)) {
        if (element != ".") {
          temp.push(element);
        }
        if (element === "." && !isDotScanned) {
          temp.push(element);
          isDotScanned = true;
        }
      }
    }
    return temp.join("");
  } else {
    return "";
  }
};


export const printDiv = (divName, Filename, pdfArr, data, setData, fileKeyName, dontDownload) => {

  let printContents = document.getElementById(divName);
  window.scrollTo(0, 0);
  let w = printContents.clientWidth;
  let h = printContents.clientHeight;

  html2canvas(printContents, {
    width: w,
    height: h,
    scrollX: 0,
    scrollY: -window.scrollY,
    allowTaint: true,
    useCORS: true
  }).then(async (canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height] // Use the canvas size for the PDF
    });

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgProps = pdf.getImageProperties(imgData);
    const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

    let position = 0;

    // Create new pages and add the image data
    while (position < imgHeight) {
      pdf.addImage(imgData, 'PNG', 0, -position, pdfWidth, imgHeight);
      position += pdfHeight;
      if (position < imgHeight) {
        pdf.addPage();
      }
    }

    let filedataUI = new File([pdf.output('blob')], `${Filename}.pdf`, {
      type: pdf.output('blob').type,
      lastModified: pdf.output('blob').lastModified,
    });

    try {
      let filedataPDF = await createPDF.PDFDocumentFromFile(filedataUI);
      const allpdf = [filedataPDF, ...pdfArr];
      const pdfDoc = await mergePDF(allpdf);
      const u8intarr = await pdfDoc.save();
      const blobdata = pdfArrayToBlob(u8intarr);
      let filedata = new File([blobdata], `${Filename}.pdf`, {
        type: blobdata.type,
      });
      let reader = new FileReader();
      reader.readAsDataURL(filedata);
      reader.onerror = (e) => {
        console.log('Error in pdf download', e);
      };
      reader.onloadend = async (e) => {
        let fileObj = filedata;
        let fileDataUrl = e.target.result;
        fileObj["filebase64"] = fileDataUrl;
        console.log('PDF Downloaded successfully');
        if (!dontDownload) {
          downloadTheFile(fileObj);
        }
        if (setData) {
          setData({ ...data, [fileKeyName]: fileObj });
        }
      };
    } catch (e) {
      console.log('error', e);
    }
  }).catch(e => {
    console.log('Error in html2canvas', e);
  });
};



export function correctFileBase64(filebase64) {
  if (!filebase64.includes("base64,")) {
    return `data:application/pdf;base64,${filebase64}`;
  }
  return filebase64;
}

function downloadTheFile(result) {
  if (result.filebase64.includes("base64,")) {
    result["filebase64"] = result.filebase64.split("base64,")[1];
  }
  if (
    result.name.split(".").pop() === "png" ||
    result.name.split(".").pop() === "PNG"
  ) {
    console.log(result.name);
    let link = document.createElement("a");
    console.log(link);
    link.download = result.name.split(".")[0] + ".png";
    link.href = "data:application/png;base64," + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (
    result.name.split(".").pop() === "jpg" ||
    result.name.split(".").pop() === "JPG"
  ) {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".jpeg";
    link.href = "data:application/jpeg;base64," + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else if (
    result.name.split(".").pop() === "pdf" ||
    result.name.split(".").pop() === "PDF"
  ) {
    let link = document.createElement("a");
    link.download = result.name.split(".")[0] + ".PDF";
    link.href = "data:application/pdf;base64," + encodeURI(result.filebase64);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export const getPDFFromFile = async (file) => {
  console.log("Filebase64", file);
  if (file.filebase64) {
    const filedata = await fetch(
      "data:application/pdf;base64," + file.filebase64
    );
    const blob = await filedata.blob();
    const fileObj = new File([blob], file.name, { type: blob.type });
    const pdfFile = await createPDF.PDFDocumentFromFile(fileObj);
    return pdfFile;
  }
};
export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const ExportExcel = (data, filename) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheet.sheet;charset-UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelbuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const finaldata = new Blob([excelbuffer], { type: fileType });
  FileSaver.saveAs(finaldata, filename + fileExtension);
};
export const industryTypesObj = {
  apparelTextile: "Apparels & Textile",
  industrialMech: "Industrial & Mechanical",
  foodBeverage: "Food & Beverage",
  electronicConsumer: "Electronics & Consumer Goods",
  eCommerce: "E-Commerce",
  gamingMedia: "Gaming & Media",
  fmcg: "FMCG",
  medicalPharma: "Medical & Pharmaceutical",
  auto: "Auto",
  frozenFood: "Frozen Foods",
  ITStaffing: "IT Staffing",
  argo: "AGRO",
  govtEntity: "Buyer/Supplier Government Entity",
  oilGas: "Oil/Gas",
  diamond: "Diamond",
  preciousStone: "Precious Stone",
  gold: "Gold",
  others: "Others",
};

export const getDateTimeString = (dateTimeObj) => {
  const dateString = dateTimeObj.toLocaleDateString();
  const dateTimeFormated = dateString.split("/").join("-");
  const TimeString = dateTimeObj.toTimeString();
  const TimeStringFormatted = TimeString.split(" ")[0];
  return dateTimeFormated + " " + TimeStringFormatted;
};

export function copyToClipboard(textId) {
  let copyText = document.getElementById(textId);
  let temp = document.createElement("textarea");
  document.body.appendChild(temp);
  temp.value = copyText.innerText;
  temp.select();
  document.execCommand("copy");
  document.body.removeChild(temp);
}

export function getNotificationIcon(type, subType) {
  let srcString = `/assets/images/Notification/${type}_${subType}.svg`;
  console.log("notificationURL", srcString);
  return srcString;
}

export function redirectNotification(type, subType, id, userTypeId, creatorId) {
  let redirectpath = "";
  if (userTypeId === 1) {
    if (type === "LC") {
      redirectpath = `/LcAmendment?search=${id}`;
    } else if (type === "Invoice Discounting") {
      redirectpath = `/seeQuotes?buyer=${id}`;
    } else if (type === "Other") {
      if (subType === "Chat room") {
        localStorage.setItem("chat_type", "Users");
        localStorage.setItem("userId", creatorId);
        localStorage.setItem("chat_room_id", id);
        redirectpath = "/ChatRoomV2";
      } else if (subType === "Wallet") {
        redirectpath = "/wallet";
      } else if (subType === "Profile") {
        redirectpath = "/viewprofile";
      } else if (subType === "Buyer Management") {
        redirectpath = `/buyerManagement?search=${id}`;
      } else if (subType === "Reports") {
        redirectpath = "/Reportv2";
      }
    }
  } else {
    if (type === "LC") {
      if (subType === "Quote") {
        redirectpath = `/LcQuote?search=${id}`;
      } else if (subType === "Contract") {
        redirectpath = `/LcContract?search=${id}`;
      } else if (subType === "Apply Finance") {
        redirectpath = `/LcApplyforFinance?search=${id}`;
      } else if (subType === "Approved Finance") {
        redirectpath = `/LcApprovedFinance?search=${id}`;
      } else if (subType === "Ammendments") {
        redirectpath = `/LcAmendment?search=${id}`;
      }
    } else if (type === "Invoice Discounting") {
      if (subType === "Quote") {
        redirectpath = `/Quotes?search=${id}`;
      } else if (subType === "Contract") {
        redirectpath = `/invoicecontract?search=${id}`;
      } else if (subType === "Apply Finance") {
        redirectpath = `/apply-finance?search=${id}`;
      } else if (subType === "Approved Finance") {
        redirectpath = `/approvedFinance?search=${id}`;
      } else if (subType === "Sign Agreement") {
        redirectpath = `/invoice-agreement?search=${id}`;
      }
    } else if (type === "Other") {
      if (subType === "Chat room") {
        redirectpath = "/ChatRoomV2";
      } else if (subType === "Wallet") {
        redirectpath = "/wallet";
      } else if (subType === "Profile") {
        redirectpath = "/viewprofile";
      } else if (subType === "Buyer Management") {
        redirectpath = `/buyerManagement?search=${id}`;
      } else if (subType === "Reports") {
        redirectpath = "/Reportv2";
      }
    }
  }

  window.location = redirectpath;
}

export function getFinancerAvailability(findata, type, userdata) {
  return new Promise(async (resolve) => {
    try {
      const result = await call("POST", "getCreditLine", {
        userId: findata.id,
      });
      console.log("result", result);
      if (isEmpty(result)) {
        return resolve({
          isAvailable: true,
          info_text: "No Credit Line Found",
        });
      } else {
        let errors = {};
        let prevYearAnnualSaleRequired = result.prevYearAnnualSaleRequired;
        let onboarding = JSON.parse(result.onboarding);
        let industry = JSON.parse(result.industry);
        let companyTypes = JSON.parse(result.companyTypes);
        let services = JSON.parse(result.services);
        let hsncodes = JSON.parse(result.HSNCodes);
        let supplierCountries = JSON.parse(result.supplierCountries);
        let buyerCountries = JSON.parse(result.buyerCountries);
        let currencies = JSON.parse(result.currencies);
        let minimumRequiredLimitInUsd = result.minimumExpectedLimitInUSD;
        let userEntererdLimitInUsd = await call("POST", "convertValueIntoUSD", {
          amount: userdata.limitRequired,
          currencyCode: userdata.limitCurrency,
        });
        let exportRangeOfExporter = result.exportRange?.split("-") || [];
        let minRangeOfExport = exportRangeOfExporter?.[0]?.trim();
        let maxRangeOfExport = exportRangeOfExporter?.[1]?.trim();
        // console.log("getFinancerAvailabilityyyyyyyyyyyyyyyyyyyyyyyy", companyTypes, findata, type, userdata, exportRangeOfExporter)
        if (minRangeOfExport && maxRangeOfExport && userdata.exporterTurnOver) {
          if (
            maxRangeOfExport === "greater" &&
            userdata.exporterTurnOver < minRangeOfExport / 1
          ) {
            errors.exporterTurnOver = `Export turn over is ${userdata.exporterTurnOver} which is less than ${minRangeOfExport} USD`;
          }
          // else if (!(minRangeOfExport && maxRangeOfExport && userdata.exporterTurnOver > minRangeOfExport / 1 && userdata.exporterTurnOver < maxRangeOfExport / 1)) {
          //   errors.exporterTurnOver = `Export turn over is not in a range of ${result.exportRange} USD`
          // }
        }

        if (
          minimumRequiredLimitInUsd &&
          userEntererdLimitInUsd &&
          userEntererdLimitInUsd < minimumRequiredLimitInUsd
        ) {
          errors.minimumRequiredLimitInUsd = `Required limit is less than ${minimumRequiredLimitInUsd} USD`;
        }
        if (
          onboarding.hasOwnProperty("newBuyerNotAccept") &&
          onboarding.newBuyerNotAccept
        ) {
          if (!type.previousAnnualSale) {
            errors.previousAnnualSale =
              "Buyer Previous year annual sale not present";
          }
        }
        if (onboarding.hasOwnProperty("dunsNo") && onboarding.dunsNo) {
          if (!userdata.buyerDUNSNo || userdata.buyerDUNSNo === "NA") {
            errors.buyerDUNSNo = "Buyer DUNS No. not available";
          }
        }
        if (industry && !isEmpty(industry)) {
          if (!industry[userdata.industry_type]) {
            errors.industry_type = "Industry Type not supported by financer";
          }
        }
        if (companyTypes && !isEmpty(companyTypes)) {
          if (!companyTypes[userdata.organization_type]) {
            errors.organization_type = `Organization Type (${userdata.organization_type
              }) not supported by financer, only supports ${Object?.keys(
                companyTypes
              )?.filter((i) => {
                if (companyTypes[i]) {
                  return true;
                }
              })} `;
          }
        }

        if (services && !isEmpty(services)) {
          if (
            !Object.keys(services).some(
              (item) => item.includes(type) && services[item]
            )
          ) {
            errors.organization_type = "Service Not Provided by financer";
          }
        }
        if (hsncodes && !isEmpty(hsncodes)) {
          let isHSNSupported = false;
          if (userdata.buyerHsnCode) {
            Object.keys(hsncodes).forEach((item) => {
              const hsncodesBuyer = userdata.buyerHsnCode.split(",");
              if (hsncodesBuyer.includes(item?.toString()) && hsncodes[item]) {
                isHSNSupported = true;
              }
            });
          } else {
            isHSNSupported = true;
          }
          if (!isHSNSupported) {
            errors.hsncodes = "HSN Code Not Supported by financer";
          }
        }

        if (supplierCountries && !isEmpty(supplierCountries)) {
          let isCountryAvailable = true;
          Object.keys(supplierCountries).forEach((item) => {
            if (
              userdata.country_code?.toLowerCase() ===
              item.split("-")[0]?.toLowerCase() &&
              supplierCountries[item]
            ) {
              isCountryAvailable = false;
            }
          });
          if (!isCountryAvailable) {
            errors.supplierCountries =
              "Supplier Country Not Supported by financer";
          }
        }
        if (buyerCountries && !isEmpty(buyerCountries)) {
          let isBuyerCountryAvailable = true;
          Object.keys(buyerCountries).forEach((item) => {
            if (
              userdata.buyerCountry?.toLowerCase() ===
              item.split("-")[0]?.toLowerCase() &&
              buyerCountries[item]
            ) {
              isBuyerCountryAvailable = false;
            }
          });
          if (!isBuyerCountryAvailable) {
            errors.buyerCountries = "Buyer Country Not Supported by financer";
          }
        }
        if (currencies && !isEmpty(currencies)) {
          if (
            !Object.keys(currencies).some(
              (item) =>
                item?.toLowerCase() === userdata.limitCurrency?.toLowerCase() &&
                currencies[item]
            )
          ) {
            errors.currencies = "Currency Not Supported by financer";
          }
        }

        console.log("errors", errors);
        if (isEmpty(errors)) {
          return resolve({
            isAvailable: true,
            info_text: "",
          });
        } else {
          return resolve({
            isAvailable: false,
            info_text: Object.values(errors).map((value) => value),
          });
        }
      }
    } catch (e) {
      console.log("no cl", e);
      return resolve({
        isAvailable: true,
        info_text: "No Credit Line Found",
      });
    }
  });
}

export const sendDocumentsToFinancer = async (
  userId,
  doc_id,
  userEmail,
  doc_type,
  typeId
) => {
  if (typeId == 19) {
    try {
      const result = await call("POST", "sendDocumentsToFinancer", {
        userId,
        doc_id,
        userEmail,
        doc_type,
      });
    } catch (e) {
      console.log("error in sendDocumentsToFinancer", e);
    }
  }
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function detectPostalCode(str) {
  // Split the string into two parts
  let firstHalf = str.substring(0, str.length / 2);
  let secondHalf = str.substring(str.length / 2);

  // Use a regular expression to match a 5 to 8 digit number in the second half
  let match = secondHalf.match(/\d{5,8}/);

  // If a match is found, return the number
  if (match) {
    return match[0];
  }
  // If no match is found, return "Not Found"
  else {
    return "";
  }
}

export function checkPermission(
  UserAccessPermission,
  sub_user_id,
  event,
  route,
  toastDisplay,
  userId,
  callback,
  isSubUser,
  isAdmin
) {

  if (!isAdmin) {
    if (userId === sub_user_id) {
      if (callback) {
        callback();
      }
      return;
    }
  }
  if (isAdmin && isSubUser === "") {
    if (callback) {
      callback();
    }
    return;
  }
  if ((sub_user_id !== 0 && sub_user_id !== null) || isSubUser) {
    if (UserAccessPermission.includes(route) || UserAccessPermission.length > 0) {
      if (callback) {
        callback();
      }
    } else {
      event.preventDefault();
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
      if (callback) {
        callback();
      }
    }
  }
}

export const isOutStandingDatePassed = (scheduledOn, status) => {
  return moment(scheduledOn).add(1, "day") < moment() && status / 1 == 0;
};

export const insertObjectInArray = (arrayData, objToInsert) => {
  for (let index = 0; index < arrayData.length; index++) {
    arrayData[index] = { ...arrayData[index], ...objToInsert };
  }
  return arrayData;
};

export const isUserInactive = (lastLoginAt) => {
  if (!lastLoginAt) {
    return true;
  }
  let activeUserLogicDurationInWeeks = 4;
  let lastLoginBeforeWeeks = moment().diff(moment(lastLoginAt), "week");
  if (lastLoginBeforeWeeks >= activeUserLogicDurationInWeeks) {
    return true;
  }
  return false;
};

export function getInitials(name) {
  var initials = "";
  var nameArray = name?.split(" ");

  for (var i = 0; i < nameArray?.length; i++) {
    var firstLetter = nameArray[i].charAt(0).toUpperCase();
    initials += firstLetter;
  }

  return initials;
}

export function isFormDataEmpty(formData) {
  for (const value of formData.values()) {
    if (value) {
      return false; // Found a non-empty value, FormData is not empty
    }
  }
  return true; // No non-empty values found, FormData is empty
}

export function returnCSVTxtForm(txt, emptyTxt) {
  if (txt) {
    return `"${txt}"`;
  } else if (emptyTxt) {
    return `"${emptyTxt}"`;
  } else {
    return `"NA"`;
  }
}

export function removeNextLine(txt) {
  return txt?.replace(/(\r\n|\r|\n)/g, " ");
}

export function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

export function hasOverlapInObject(obj) {
  const seen = new Set();
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const array = obj[prop].EXPORTER_CODE;
      for (const value of array) {
        if (seen.has(value)) {
          return true;
        }
        seen.add(value);
      }
    }
  }
  return false;
}

export function evaluateCPCommissionPercentBasedOnInvoiceAmount(
  invoiceAmount,
  CPChargesCommission
) {
  invoiceAmount = invoiceAmount / 1;
  let CPCommissionObjKeysArray = Object.keys(CPChargesCommission);
  let CPCommissionObjValuesArray = Object.values(CPChargesCommission);
  let commissionPercentage = 0;
  for (let index = 0; index < CPCommissionObjKeysArray.length; index++) {
    const element = CPCommissionObjKeysArray[index];
    if (!commissionPercentage) {
      if (element.includes("<>")) {
        let rangeOfValue = element.split("<>");
        if (
          rangeOfValue[0] <= invoiceAmount &&
          invoiceAmount <= rangeOfValue[1]
        ) {
          commissionPercentage = CPCommissionObjValuesArray[index] / 1;
        }
      } else if (element.includes("<<")) {
        let maxRangeValue = element.split("<<");
        if (invoiceAmount >= maxRangeValue[0]) {
          commissionPercentage = CPCommissionObjValuesArray[index] / 1;
        }
      }
    }
  }
  return commissionPercentage;
}

export const getContactObject = (EXTRA_DETAILS = []) => {
  let contactRow = {};
  const primaryCotact = EXTRA_DETAILS.find(
    (element) => element.isPrimary && element["Contact Number"]
  );
  if (primaryCotact) {
    contactRow = primaryCotact;
    return primaryCotact;
  }
  const mangDirector = EXTRA_DETAILS.find(
    (element) =>
      element.Designation?.toUpperCase() === "MANAGING DIRECTOR" &&
      element["Contact Number"]
  );
  if (mangDirector && mangDirector["Contact Number"]) {
    contactRow = mangDirector;
    return mangDirector;
  }

  const wholetimeDirector = EXTRA_DETAILS.find(
    (element) =>
      element.Designation?.toUpperCase() === "WHOLETIME DIRECTOR" &&
      element["Contact Number"]
  );
  if (wholetimeDirector && wholetimeDirector["Contact Number"]) {
    contactRow = wholetimeDirector;
    return wholetimeDirector;
  }

  const directordata = EXTRA_DETAILS.find(
    (element) =>
      element.Designation?.toUpperCase() === "DIRECTOR" &&
      element["Contact Number"]
  );

  if (directordata && directordata["Contact Number"]) {
    contactRow = directordata;
    return directordata;
  }

  const desgPartner = EXTRA_DETAILS.find(
    (element) =>
      element.Designation?.toUpperCase() === "DESIGNATED PARTNER" &&
      element["Contact Number"]
  );
  if (desgPartner && desgPartner["Contact Number"]) {
    contactRow = desgPartner;
    return desgPartner;
  }

  const manager = EXTRA_DETAILS.find(
    (element) =>
      element.Designation?.toUpperCase() === "Manager" &&
      element["Contact Number"]
  );
  if (manager && manager["Contact Number"]) {
    contactRow = manager;
    return manager;
  }
  const financeDep = EXTRA_DETAILS.find(
    (element) => element.Department === "Finance" && element["Contact Number"]
  );
  if (financeDep && financeDep["Contact Number"]) {
    contactRow = financeDep;
    return financeDep;
  }
  const HRDep = EXTRA_DETAILS.find(
    (element) =>
      element.Department === "Human Resource" && element["Contact Number"]
  );
  if (HRDep && HRDep["Contact Number"]) {
    contactRow = HRDep;
    return HRDep;
  }
  const ManagementDep = EXTRA_DETAILS.find(
    (element) =>
      element.Department === "Management" && element["Contact Number"]
  );
  if (ManagementDep && ManagementDep["Contact Number"]) {
    contactRow = ManagementDep;
    return ManagementDep;
  }
  if (isEmpty(contactRow) && !contactRow["Contact Number"]) {
    const anyContactRow = EXTRA_DETAILS.find(
      (element) => element["Contact Number"]
    );
    if (anyContactRow) {
      return anyContactRow;
    }
  } else {
    return contactRow;
  }
};

export const generateInvoiceNumber = (createdAt, companyName) => {
  const companyInitials = getInitials(companyName);

  return `INV-${companyInitials}-${new Date(createdAt).getTime()}`;
};

export const getCompletePdfBase64 = (base64Str) => {
  if (base64Str.includes(";base64")) {
    return base64Str;
  } else {
    return "data:application/pdf;base64," + base64Str;
  }
};

export function getFiscalYearDates(year) {
  // Define the start month and day of the fiscal year
  const fiscalYearStartMonth = 4; // April
  const fiscalYearStartDay = 1;

  // Calculate the start date of the fiscal year
  const startDate = new Date(
    parseInt(year),
    fiscalYearStartMonth - 1,
    fiscalYearStartDay
  );

  // Calculate the end date of the fiscal year (start date of next fiscal year minus one day)
  const endDate = new Date(
    parseInt(year) + 1,
    fiscalYearStartMonth - 1,
    fiscalYearStartDay - 1
  );

  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
}
export const getFiscalYearsDropdown = () => {
  var startYear = new Date().getFullYear();
  var prevYear = new Date().getFullYear() - 1;
  let yearsDropDown = [];
  for (var i = 0; i < 30; i++) {
    yearsDropDown.push({
      label: prevYear + "-" + startYear,
      value: prevYear,
    });
    startYear = startYear - 1;
    prevYear = prevYear - 1;
  }
  return yearsDropDown;
};

export const gettotalCount = (data, key) => {
  let sum = 0;
  data?.forEach((element) => {
    sum += element[key];
  });
  return sum;
};

export function addDaysSkipSundays(startDate, days) {
  var currentDate = new Date(startDate.getTime());
  var count = 0;

  while (count < days) {
    currentDate.setDate(currentDate.getDate() + 1); // Add 1 day to the current date

    if (currentDate.getDay() !== 0) {
      // Check if the current date is not a Sunday (0 represents Sunday)
      count++;
    }
  }

  return currentDate;
}

export const mergeMultiplePdfs = async (files, fileName) => {
  if (files.length / 1 == 1) {
    return files[0];
  }

  const mergedPdfDoc = await PDFDocument.create();
  files = files.filter((element) => {
    return Boolean(element); // Removes empty elements
  });

  for (const file of files) {
    const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
    const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, [0]);
    copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
  }

  const mergedPdfBytes = await mergedPdfDoc.save();

  // Convert merged PDF bytes into a base64 string
  const mergedPdfBase64 = btoa(
    new Uint8Array(mergedPdfBytes).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );

  // Create a new File object with the additional fileBase64 property
  const mergedPdfFile = new File([mergedPdfBytes], fileName + ".pdf", {
    type: "application/pdf",
  });

  mergedPdfFile["filebase64"] =
    "data:application/pdf;base64," + mergedPdfBase64;

  return mergedPdfFile;
};

export const getProgressPercentage = (value) => {
  let numParts = 4;

  let parts = [];

  for (let i = 0; i < numParts; i++) {
    if (value >= 25) {
      parts.push(25);
      value -= 25;
    } else {
      parts.push(value);
      value = 0;
    }
  }
  return parts;
};

export const handleMultipleFile = async (event) => {
  // console.log("handleMultipleFile", event);
  return new Promise((resolve, reject) => {
    event.persist();
    if (!event.target.files.length) {
      return resolve({
        success: false,
        msg: { [event.target.name]: "Kindly Select File" },
      });
    } else {
      let file_type = event.target.files[0]["type"].toLowerCase();
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg") ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        )
      ) {
        return resolve({
          success: false,
          msg: {
            [event.target.name]:
              "Files with pdf, png, jpeg, docx & xlsx extension are allowed",
          },
        });
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0];
        let fileDataUrl = e.target.result;
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(
            fileDataUrl,
            event.target.files[0]["name"]
          );
          console.log("pdfconversionresp", response);
          fileObj = response["file"];
          fileDataUrl = response["dataUrl"];
          toastDisplay("File converted into pdf format", "success");
        }
        if (
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          try {
            let formData = new FormData();
            formData.append("file", fileObj);
            if (
              file_type.includes(
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              )
            ) {
              formData.append("docType", "docx");
            }
            if (
              file_type.includes(
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              )
            ) {
              formData.append("docType", "xlsx");
            }
            let apiResp = await call("POST", "docToPdf", formData);
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", {
              type: "application/pdf",
            });
            fileDataUrl = apiResp;
            toastDisplay("File converted into pdf format", "success");
          } catch (error) {
            return toastDisplay("failed to convert file", "error");
          }
        }
        fileObj["filebase64"] = fileDataUrl;
        return resolve({ success: true, msg: fileObj });
      };
    }
  });
};

export const monthsNoMapping = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const financialYearMonths = [
  { name: "April" },
  { name: "May" },
  { name: "June" },
  { name: "July" },
  { name: "August" },
  { name: "September" },
  { name: "October" },
  { name: "November" },
  { name: "December" },
  { name: "January" },
  { name: "February" },
  { name: "March" },
];

export const handleFileWithAsyncAwait = async (event, setshowLoader) => {
  // console.log("handleFileWithAsyncAwaitcalled", event, poNo, poSubNo);
  return new Promise((resolve, reject) => {
    event.persist();
    if (!event.target.files.length) {
      resolve({
        status: 0,
        msg: { [event.target.name]: "Kindly Select File" },
      });
      return null;
    } else {
      let file_type = event.target.files[0]["type"].toLowerCase();
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg") ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        )
      ) {
        resolve({
          status: 0,
          msg: {
            [event.target.name]:
              "Files with pdf, png, jpeg, docx & xlsx extension are allowed",
          },
        });
        return null;
      }
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onloadend = async (e) => {
        let fileObj = event.target.files[0];
        let fileDataUrl = e.target.result;
        if (file_type.includes("png") || file_type.includes("jpeg")) {
          let response = await convertImageToPdf(
            fileDataUrl,
            event.target.files[0]["name"]
          );
          console.log("pdfconversionresp", response);
          fileObj = response["file"];
          fileDataUrl = response["dataUrl"];
          toastDisplay("File converted into pdf format", "success");
        }
        if (
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        ) {
          try {
            setshowLoader(true);
            let formData = new FormData();
            formData.append("file", fileObj);
            if (
              file_type.includes(
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              )
            ) {
              formData.append("docType", "docx");
            }
            if (
              file_type.includes(
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              )
            ) {
              formData.append("docType", "xlsx");
            }
            let apiResp = await call("POST", "docToPdf", formData);
            let blob = dataURItoBlob(apiResp);
            fileObj = new File([blob], fileObj.name + "converted.pdf", {
              type: "application/pdf",
            });
            fileDataUrl = apiResp;
            toastDisplay("File converted into pdf format", "success");
            setshowLoader(false);
          } catch (error) {
            setshowLoader(false);
            return toastDisplay("failed to convert file", "error");
          }
          setshowLoader(false);
        }
        fileObj["filebase64"] = fileDataUrl;
        // merge current po with previous data start
        let tempData = { [event.target.name]: fileObj };
        // merge current po with previous data end
        console.log("insidehandleFileeeeeeeeeeeeeeee", tempData);
        resolve({ status: 1, msg: tempData });
        return null;
      };
    }
  });
};

export const handleFileCommonFunction = (
  event,
  data,
  errors,
  setData,
  setErrors,
  setshowLoader,
  supportedFileTypes
) => {
  event.persist();
  if (!event.target.files.length) {
    return null;
  } else {
    let file_type = event.target.files[0]["type"].toLowerCase();
    if (supportedFileTypes?.length) {
      let isFileFormatSupported = true;
      for (let index = 0; index < supportedFileTypes.length; index++) {
        const element = supportedFileTypes[index];
        if (!file_type.includes(element)) {
          isFileFormatSupported = false;
          break;
        }
      }
      if (!isFileFormatSupported) {
        setErrors({
          ...errors,
          [event.target.name]: `Files with ${supportedFileTypes.join(
            ","
          )} extension are allowed`,
        });
        return;
      }
    } else {
      if (
        !(
          file_type.includes("pdf") ||
          file_type.includes("png") ||
          file_type.includes("jpeg") ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) ||
          file_type.includes(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          )
        )
      ) {
        setErrors({
          ...errors,
          [event.target.name]:
            "Files with pdf, png, jpeg, docx & xlsx extension are allowed",
        });
        return;
      }
    }
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = async (e) => {
      let fileObj = event.target.files[0];
      let fileDataUrl = e.target.result;
      if (file_type.includes("png") || file_type.includes("jpeg")) {
        let response = await convertImageToPdf(
          fileDataUrl,
          event.target.files[0]["name"]
        );
        console.log("pdfconversionresp", response);
        fileObj = response["file"];
        fileDataUrl = response["dataUrl"];
        toastDisplay("File converted into pdf format", "success");
      }
      if (
        file_type.includes(
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) ||
        file_type.includes(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        try {
          setshowLoader(true);
          let formData = new FormData();
          formData.append("file", fileObj);
          if (
            file_type.includes(
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            )
          ) {
            formData.append("docType", "docx");
          }
          if (
            file_type.includes(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            )
          ) {
            formData.append("docType", "xlsx");
          }
          let apiResp = await call("POST", "docToPdf", formData);
          let blob = dataURItoBlob(apiResp);
          fileObj = new File([blob], fileObj.name + "converted.pdf", {
            type: "application/pdf",
          });
          fileDataUrl = apiResp;
          toastDisplay("File converted into pdf format", "success");
        } catch (error) {
          return toastDisplay("failed to convert file", "error");
        }
        setshowLoader(false);
      }
      fileObj["filebase64"] = fileDataUrl;
      setData({ ...data, [event.target.name]: fileObj });
      setErrors({ ...errors, [event.target.name]: "" });
    };
  }
};

export const replaceFileBase64WithFileObj = (dataObj) => {
  try {
    let objKeys = Object.keys(dataObj);
    for (let index = 0; index < objKeys.length; index++) {
      let tempFileObj = null;
      if (dataObj?.[objKeys[index]]?.filebase64) {
        tempFileObj = dataURItoBlob(dataObj[objKeys[index]].filebase64);
        tempFileObj = new File([tempFileObj], dataObj[objKeys[index]].path, {
          type: "application/pdf",
        });
        tempFileObj["filebase64"] = dataObj[objKeys[index]].filebase64;
        tempFileObj["path"] = dataObj[objKeys[index]].path;
        tempFileObj["signatureId"] = dataObj[objKeys[index]].signatureId;
        dataObj[objKeys[index]] = tempFileObj;
      }
    }
  } catch (error) {
    console.log("errorinreplaceFileBase64WithFileObj", error);
  }
  return dataObj;
};

export const multiSelectEventHandler = (data, nameKey, valueKey) => {
  // if (data?.length) {
  //   data = data.reverse();
  // }
  if (Array.isArray(data) && data.length) {
    data = data.reverse();
  }
  return {
    persist: () => { },
    target: {
      name: nameKey,
      value: data?.[0]?.[valueKey],
    },
  };
};

export const exportersNavConfig = [
  {
    id: "1",
    icon: "assets/images/sidebarV2/dashboard.svg",
    text: "Dashboard",
    level: 0,
    stateVal: "dashboard",
    redirectURL: "/dashboard",
    permissionVal: "dashboard",
    notificationVal: "buyer management",
  },
  {
    id: "2",
    icon: "assets/images/sidebarV2/buyermanagement.svg",
    text: "Network Management",
    level: 1,
    stateVal: "nwManagement",
    subItems: [
      {
        name: "Buyers",
        stateVal: "nwManagementBuyer",
        redirectURL: "/buyerManagement",
        permissionVal: "buyerManagement",
      },
      {
        name: "Suppliers",
        stateVal: "nwManagementExporter",
        redirectURL: "/nwManagementExporter",
        permissionVal: "nwManagementExporter",
      },
      {
        name: "Logistic Partners",
        stateVal: "nwManagementLogisticPartner",
        redirectURL: "/nwManagementLogisticPartner",
        permissionVal: "nwManagementLogisticPartner",
      },
    ],
    collapseTarget: "nwManagement",
    notificationVal: "nwManagement",
  },
  {
    id: "3",
    icon: "assets/images/sidebarV2/lc_menu.svg",
    text: "Letter of Credit",
    level: 1,
    stateVal: "Lc",
    subItems: [
      {
        name: "Check For Limit",
        stateVal: "LcDiscountingV2",
        redirectURL: "/LcDiscountingV2",
        permissionVal: "LcDiscountingV2",
      },
      {
        name: "Quotes",
        stateVal: "LcQuote",
        redirectURL: "/LcQuote",
        permissionVal: "LcQuote",
      },
      {
        name: "Contract",
        stateVal: "LcContract",
        redirectURL: "/LcContract",
        permissionVal: "LcContract",
        notificationVal: "",
      },
      {
        name: "Apply For Finance",
        stateVal: "LcApplyforFinance",
        redirectURL: "/LcApplyforFinance",
        permissionVal: "LcApplyforFinance",
        notificationVal: "",
      },
      {
        name: "Approved Finance",
        stateVal: "LcApprovedFinance",
        redirectURL: "/LcApprovedFinance",
        permissionVal: "LcApprovedFinance",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseLc",
    notificationVal: "lc",
  },
  {
    id: "4",
    icon: "assets/images/sidebarV2/invoice_menu.svg",
    activeIcon: "assets/images/sidebarV2/invoice_menu_active.svg",
    text: "Export Factoring",
    level: 1,
    stateVal: "invoice",
    subItems: [
      {
        name: "Apply For Limit",
        stateVal: "invoice_applyLimit",
        redirectURL: "/applyLimitV2",
        permissionVal: "applyLimit",
        notificationVal: "",
      },
      {
        name: "Quotes",
        stateVal: "invoice_Quotes",
        redirectURL: "/Quotes",
        permissionVal: "Quotes",
        notificationVal: "",
      },
      {
        name: "Termsheet",
        stateVal: "invoicecontract",
        redirectURL: "/invoicecontract",
        permissionVal: "Quotes",
        notificationVal: "",
      },
      {
        name: "Apply For Finance",
        stateVal: "invoice_apply-finance",
        redirectURL: "/apply-finance",
        permissionVal: "apply-finance",
        notificationVal: "",
      },
      {
        name: "Sign Agreement",
        stateVal: "invoice-agreement",
        redirectURL: "/invoice-agreement",
        permissionVal: "invoice-agreement",
        notificationVal: "",
      },
      {
        name: "Approved Finance",
        stateVal: "invoice_approvedFinance",
        redirectURL: "/approvedFinance",
        permissionVal: "approvedFinance",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseinv",
    notificationVal: "invoicee discounting",
  },
  {
    "id": "5",
    "icon": "assets/images/sidebarV2/other_financial.svg",
    "text": "Other Financial",
    "level": 2,
    "stateVal": "otherFin",
    "subItems": [
      // {
      //   "name": "GST Based Invoice Finance",
      //   "stateVal": "gstBasedInvoiceFinance",
      //   "collapseTarget": "collapsegstBasedInvoiceFinance",
      //   "stages": [
      //     { "name": "Offers", redirectURL: "/gstBasedInvoiceFinanceDiscovery", stateVal: "otherFingstBasedInvoiceFinanceDiscovery" },
      //     { "name": "Products Catalogue", redirectURL: "/gstBasedInvoiceFinanceProductCatalogue", stateVal: "otherFingstBasedInvoiceFinanceProductCatalogue" }
      //   ]
      // },
      {
        name: "Working Capital",
        stateVal: "otherFinworkingCapital",
        collapseTarget: "collapseWC",
        stages: [
          {
            name: "Apply For Limit",
            redirectURL: "/wcApplyLimit",
            stateVal: "otherFinWC",
          },
          {
            name: "Quote",
            redirectURL: "/wcQuote",
            stateVal: "otherFinWCQuote",
          },
        ],
      },
      {
        name: "CGTMSE",
        stateVal: "otherFinCGTMSE",
        collapseTarget: "collapseCGTMSE",
        stages: [
          {
            name: "Apply For Limit",
            redirectURL: "/applylimitCGTMSE",
            stateVal: "otherFinCGTMSEApplyLimit",
          },
          {
            name: "Quote",
            redirectURL: "/cgtmseQuote",
            stateVal: "otherFinCGTMSEQuote",
          },
        ],
      },
      {
        name: "Bank Gurantee",
        stateVal: "bg",
        collapseTarget: "collapseBG",
        stages: [
          {
            name: "Get Confirmation",
            redirectURL: "/bgGetConfirmation",
            stateVal: "bgGetConfirmation",
          },
          { name: "Quote", redirectURL: "/bgQuote", stateVal: "bgQuote" },
          {
            name: "Confirmed BG",
            redirectURL: "/bgConfirmed",
            stateVal: "bgConfirmed",
          },
        ],
      },
      {
        name: "Supply Chain Finance",
        stateVal: "otherFinSCF",
        collapseTarget: "collapseSCF",
        stages: [
          {
            name: "Apply For Limit",
            redirectURL: "/applylimitSCF",
            stateVal: "otherFinSCFlimit",
          },
          {
            name: "Quote",
            redirectURL: "/scfQuote",
            stateVal: "otherFinSCFQuote",
          },
          {
            name: "Approved Limit",
            redirectURL: "/approvedLimitSCF",
            stateVal: "otherFinSCFApprovedLimit",
          },
          {
            name: "Finance Application",
            redirectURL: "/approvedLimitSCF",
            stateVal: "otherFinSCFApprovedLimit",
          },
        ],
      },
      {
        name: "Domestic Invoice Discounting",
        stateVal: "otherFinDID",
        collapseTarget: "collapseDID",
        stages: [
          {
            name: "Apply For Finance",
            redirectURL: "/applyfinDID",
            stateVal: "otherFinDIDApplyFin",
          },
          {
            name: "Approved Finance",
            redirectURL: "/invoiceFinance",
            stateVal: "otherFinDIDApprovedFin",
          },
        ],
      },
    ],
    collapseTarget: "collapseOtherFin",
  },
  {
    id: "6",
    icon: "assets/images/sidebarV2/tradeDiscovery.svg",
    text: "Trade Discovery",
    level: 0,
    stateVal: "tradeDiscovery",
    redirectURL: "/tradeDiscovery",
    permissionVal: "tradeDiscovery",
    notificationVal: "tradeDiscovery",
  },
  {
    id: "7",
    icon: "assets/images/sidebarV2/wallet.svg",
    text: "Wallet",
    level: 0,
    stateVal: "wallet",
    redirectURL: "/wallet",
    permissionVal: "wallet",
    notificationVal: "wallet",
  },
  {
    id: "8",
    icon: "assets/images/sidebarV2/chatroom2.svg",
    text: "Chat Room",
    level: 0,
    stateVal: "ChatRoomV2",
    redirectURL: "/ChatRoomV2",
    permissionVal: "ChatRoomV2",
    notificationVal: "chat room",
  },
  {
    id: "9",
    icon: "assets/images/document_vault.svg",
    text: "Document Vault",
    level: 1,
    stateVal: "docVault",
    subItems: [
      {
        name: "Transaction",
        stateVal: "docVaultTransaction",
        redirectURL: "/docVaultTransaction",
        permissionVal: "",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapsedocVault",
    notificationVal: "",
  },
  {
    id: "10",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Reports",
    level: 1,
    stateVal: "Reportv2",
    localStorageKey: "report_name",
    subItems: [
      {
        "name": "Dashboard",
        "redirectURL": "/tallyReportsDashboard",
        "stateVal": "Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      },
      {
        "name": "Payment",
        "stateVal": "Reportv2",
        "redirectURL": "/Reportv2",
        "permissionVal": "Reportv2",
        "notificationVal": ""
      },
      {
        name: "Finance",
        stateVal: "Reportv2",
        redirectURL: "/Reportv2",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      {
        name: "Shipment",
        stateVal: "Reportv2",
        redirectURL: "/Reportv2",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      // {
      //   "name": "Inventory Management",
      //   "stateVal": "Reportv2",
      //   "redirectURL": "/manageCommodities",
      //   "permissionVal": "Reportv2",
      //   "notificationVal": ""
      // }
    ],
    collapseTarget: "collapseReports",
    notificationVal: "invoicee discounting",
  },
  {
    id: "11",
    icon: "assets/images/contractMangIcon.png",
    text: "Contract Management",
    level: 1,
    // redirectURL: "/contractManagementList",
    stateVal: "contractManagement",

    subItems: [
      {
        name: "Container",
        stateVal: "containerContractManagement",
        redirectURL: "/containerContractManagement",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Bulk",
        redirectURL: "/contractManagementList",
        stateVal: "bulkContractManagement",
        permissionVal: "",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseContractManagement",
  },

  {
    id: "12",
    icon: "assets/images/sidebarV2/settings_icon.svg",
    text: "Settings",
    level: 0,
    stateVal: "settings",
    redirectURL: "/settings",
    permissionVal: "settings",
    notificationVal: "settings",
  },
  {
    id: "13",
    icon: "assets/images/insurance.svg",
    text: "Insurance",
    level: 1,
    stateVal: "other_services",
    permissionVal: "",
    notificationVal: "other_services",
    subItems: [
      {
        name: "Trade Credit Insurance",
        stateVal: "other_services_credit_insurance",
        redirectURL: "/CreditInsurance",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Quote",
        redirectURL: "/insuranceQuote",
        stateVal: "other_services_credit_insurance_quote",
      },


    ],
    collapseTarget: "collapseOtherIns",
  },
  {
    id: "14",
    icon: "assets/images/edocs.png",
    text: "Edocs",
    level: 1,
    stateVal: "edocs",
    permissionVal: "",
    notificationVal: "edocs",
    subItems: [
      {
        name: "Delivery Challan",
        stateVal: "deliveryChallan",
        redirectURL: "/deliveryChallan",
        permissionVal: "",
        notificationVal: ""
      },
      {
        name: "Cash Memo",
        stateVal: "cashMemo",
        redirectURL: "/cashMemo",
        permissionVal: "",
        notificationVal: ""
      },
      {
        name: "E-Invoice",
        stateVal: "edocsEinvoice",
        redirectURL: "/edocsEinvoice",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "E-Way Bill",
        stateVal: "edocsEwaybill",
        redirectURL: "/edocsEwaybill",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "PO Generator",
        stateVal: "edocsPOGenerator",
        redirectURL: "/edocsPOGenerator",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Invoice",
        stateVal: "edocsInvoice",
        redirectURL: "/edocsInvoice",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Letter of Credit",
        stateVal: "edocsLC",
        redirectURL: "/edocsLC",
        permissionVal: "",
        notificationVal: "",
      },
      {
        "name": "Bill of Lading",
        "stateVal": "edocsBL",
        "redirectURL": "/edocsBL",
        "permissionVal": "",
        "notificationVal": ""
      },
      {
        "name": "Receipt Note",
        "stateVal": "receiptNotes",
        "redirectURL": "/receiptNotes",
        "permissionVal": "",
        "notificationVal": ""
      },
      {
        "name": "Credit Note",
        "stateVal": "creditNote",
        "redirectURL": "/creditNote",
        "permissionVal": "",
        "notificationVal": ""
      },
      {
        "name": "Debit Note",
        "stateVal": "debitNote",
        "redirectURL": "/debitNote",
        "permissionVal": "",
        "notificationVal": ""
      },
      {
        "name": "Sales Order",
        "stateVal": "salesOrder",
        "redirectURL": "/salesOrder",
        "permissionVal": "",
        "notificationVal": ""
      },

    ],
    collapseTarget: "edocs",
  },
  {
    "id": "15",
    "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "activeIcon": "assets/images/sidebarV2/tradeDiscovery.svg",
    "text": "Logistic",
    "level": 1,
    "stateVal": "ShipmentBooking",
    "subItems": [
      // {
      //   "name": "Spot Rate",
      //   "stateVal": "ShipmentBookingMarketPlace",
      //   "redirectURL": "/ShipmentBookingMarketPlace",
      //   "permissionVal": "ShipmentBookingMarketPlace",
      //   "notificationVal": "ShipmentBookingMarketPlace"
      // },
      {
        name: "Spot Rate",
        stateVal: "ShipmentBookingMarketPlace",
        redirectURL: "/ShipmentBookingMarketPlace",
        permissionVal: "ShipmentBookingMarketPlace",
        notificationVal: "ShipmentBookingMarketPlace",
      },
      {
        name: "Request Quote",
        stateVal: "ShipmentBookingApply",
        redirectURL: "/ShipmentBookingApply",
        permissionVal: "ShipmentBookingApply",
        notificationVal: "ShipmentBookingApply",
      },
      // {
      //   "name": "Request Quote",
      //   "stateVal": "ShipmentBookingApply",
      //   "redirectURL": "/ShipmentBookingApply",
      //   "permissionVal": "ShipmentBookingApply",
      //   "notificationVal": "ShipmentBookingApply"
      // },
      {
        name: "Booking",
        stateVal: "ShipmentBookingQuotes",
        redirectURL: "/ShipmentBookingQuotes",
        permissionVal: "ShipmentBookingQuotes",
        notificationVal: "ShipmentBookingQuotes",
      },
      {
        name: "Tracking",
        stateVal: "ShipmentBookingTracking",
        redirectURL: "/ShipmentBookingTracking",
        permissionVal: "ShipmentBookingTracking",
        notificationVal: "ShipmentBookingTracking",
      },
    ],
    collapseTarget: "ShipmentBooking",
    notificationVal: "ShipmentBooking",
  },
  {
    id: "16",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Tally",
    level: 2,
    stateVal: "tally",
    subItems: [
      {
        name: "Accounting Master",
        stateVal: "tallyMastersAccounting",
        collapseTarget: "tallyMastersAccounting",
        stages: [
          {
            name: "Group",
            redirectURL: "/tallyMastersAccountingGroup",
            stateVal: "tallyMastersAccountingGroup",
          },
          {
            name: "Ledger",
            redirectURL: "/tallyMastersAccountingLedger",
            stateVal: "tallyMastersAccountingLedger",
          },
          // { "name": "Currency", redirectURL: "/tallyMastersAccountingCurrency", stateVal: "tallyMastersAccountingCurrency" },
          {
            name: "Voucher Type",
            redirectURL: "/tallyMastersAccountingVoucherType",
            stateVal: "tallyMastersAccountingVoucherType",
          },
        ],
      },
      {
        name: "Inventory Master",
        stateVal: "tallyMastersInventory",
        collapseTarget: "tallyMastersInventory",
        stages: [
          {
            name: "Units",
            redirectURL: "/tallyMastersStockUnits",
            stateVal: "tallyMastersStockUnits",
          },
          {
            name: "Stock Group",
            redirectURL: "/tallyMastersStockGroup",
            stateVal: "tallyMastersStockGroup",
          },
          {
            name: "Stock Category",
            redirectURL: "/tallyMastersStockCategory",
            stateVal: "tallyMastersStockCategory",
          },
          {
            name: "Godown",
            redirectURL: "/tallyMastersStockGodown",
            stateVal: "tallyMastersStockGodown",
          },
          {
            name: "Stock Item",
            redirectURL: "/manageCommodities",
            stateVal: "tallyMastersStockItem",
          },
          // { "name": "Unit", redirectURL: "/tallyMastersStockUnit", stateVal: "tallyMastersStockUnit" },
        ],
      },
      {
        "name": "Transactions",
        "stateVal": "tallyTxns",
        "collapseTarget": "tallyTxns",
        "stages": [
          { "name": "Voucher", redirectURL: "/tallyTxnsVoucher", stateVal: "tallyTxnsVoucher" },
          // { "name": "Bank Statements", redirectURL: "/tallyTxnsBankStatements", stateVal: "tallyTxnsBankStatements" },
        ]
      },
      // {
      //   "name": "Reports",
      //   "stateVal": "tallyReports",
      //   "collapseTarget": "tallyReports",
      //   "stages": [
      //     { "name": "Dashboard", redirectURL: "/tallyReportsDashboard", stateVal: "tallyReportsDashboard" },
      //   ]
      // },
      // {
      //   name: "Reports",
      //   stateVal: "tallyReports",
      //   collapseTarget: "tallyReports",
      //   stages: [
      //     {
      //       name: "Dashboard",
      //       redirectURL: "/tallyReportsDashboard",
      //       stateVal: "tallyReportsDashboard",
      //     },
      //   ],
      // },

    ],
    collapseTarget: "tally",
  },
  {
    id: "17",
    icon: "assets/images/sidebarV2/chatroom2.svg",
    text: "Trade GPT",
    level: 0,
    stateVal: "tradeGpt",
    redirectURL: "/tradeGpt",
    permissionVal: "tradeGpt",
    notificationVal: "tradeGpt",
  },
  {
    "id": "18",
    "icon": "assets/images/sidebarV2/invoice_menu.svg",
    "text": "HSN Code Finder",
    "level": 0,
    "stateVal": "hsnCodeFinder",
    "redirectURL": "/hsnCodeFinder",
    "permissionVal": "hsnCodeFinder",
    "notificationVal": "hsnCodeFinder"
  },
  // {
  //   "id": "18",
  //   "icon": "assets/images/sidebarV2/invoice_menu.svg",
  //   "text": "Vendor Payments",
  //   "level": 0,
  //   "stateVal": "vendorPayments",
  //   "redirectURL": "/vendorPayments",
  //   "permissionVal": "vendorPayments",
  //   "notificationVal": "vendorPayments"
  // },
  {
    "id": "19",
    "icon": "assets/images/sidebarV2/invoice_menu.svg",
    "activeIcon": "assets/images/sidebarV2/invoice_menu_active.svg",
    "text": "Banking",
    "level": 1,
    "stateVal": "Bank",
    "subItems": [
      {
        "name": "RazorpayX Integration",
        "stateVal": "razorpayxBankIntegration",
        "redirectURL": "/razorpayxBankIntegration",
        "permissionVal": "razorpayxBankIntegration",
        "notificationVal": ""
      },
      {
        "name": "OpenMoney Integration",
        "stateVal": "openMoneyBankIntegration",
        "redirectURL": "/openMoneyBankIntegration",
        "permissionVal": "openMoneyBankIntegration",
        "notificationVal": ""
      },
      {
        "name": "Bank Statements",
        "stateVal": "tallyTxnsBankStatements",
        "redirectURL": "/tallyTxnsBankStatements",
        "permissionVal": "tallyTxnsBankStatements",
        "notificationVal": ""
      },
      {
        "name": "Vendor Payments",
        "stateVal": "vendorBankPayments",
        "redirectURL": "/vendorBankPayments",
        "permissionVal": "vendorBankPayments",
        "notificationVal": ""
      }
    ],
    "collapseTarget": "Bank",
    "notificationVal": "Banking"
  },
]

export const CPNavConfig = [
  {
    id: "1",
    icon: "assets/images/sidebarV2/dashboard.svg",
    text: "Dashboard",
    level: 0,
    stateVal: "dashboard",
    redirectURL: "/dashboard",
    permissionVal: "dashboard",
    notificationVal: "dashboard",
  },
  {
    id: "2",
    icon: "assets/images/sidebarV2/buyermanagement.svg",
    text: "Network Management",
    level: 1,
    stateVal: "nwManagement",
    subItems: [
      // {
      //   "name": "Buyers",
      //   "stateVal": "nwManagementBuyer",
      //   "redirectURL": "/buyerManagement",
      //   "permissionVal": "buyerManagement"
      // },
      {
        name: "Business",
        stateVal: "nwManagementExporter",
        redirectURL: "/nwManagementExporter",
        permissionVal: "nwManagementExporter",
      },
    ],
    collapseTarget: "nwManagement",
    notificationVal: "nwManagement",
  },
  {
    id: "3",
    icon: "assets/images/sidebarV2/invoice_menu.svg",
    activeIcon: "assets/images/sidebarV2/invoice_menu_active.svg",
    text: "Export Factoring",
    level: 1,
    stateVal: "invoice",
    subItems: [
      {
        name: "Limit",
        stateVal: "CPinvoiceLimit",
        redirectURL: "/CPinvoiceLimit",
        permissionVal: "CPinvoiceLimit",
        notificationVal: "",
      },
      {
        name: "Finance",
        stateVal: "CPinvoiceFinance",
        redirectURL: "/CPinvoiceFinance",
        permissionVal: "CPinvoiceFinance",
        notificationVal: "",
      },
      {
        name: "Approved Finance",
        stateVal: "CPinvoiceApprovedFinance",
        redirectURL: "/CPinvoiceApprovedFinance",
        permissionVal: "CPinvoiceApprovedFinance",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseinv",
    notificationVal: "invoice discounting",
  },
  {
    id: "4",
    icon: "assets/images/sidebarV2/lc_menu.svg",
    text: "Letter of Credit",
    level: 1,
    stateVal: "Lc",
    subItems: [
      {
        name: "Limit",
        stateVal: "CPlcLimit",
        redirectURL: "/CPlcLimit",
        permissionVal: "CPlcLimit",
      },
    ],
    collapseTarget: "collapseLc",
    notificationVal: "lc",
  },
  {
    id: "5",
    icon: "assets/images/sidebarV2/walletBlack.png",
    text: "Payments",
    level: 0,
    stateVal: "generateInvoiceCP",
    redirectURL: "/generateInvoiceCP",
    permissionVal: "generateInvoiceCP",
    notificationVal: "",
  },
  {
    id: "6",
    icon: "assets/images/sidebarV2/other_financial.svg",
    text: "Other Financial",
    level: 2,
    stateVal: "otherFinAdmin",
    subItems: [
      {
        name: "Working Capital",
        stateVal: "otherFinAdminworkingCapital",
        collapseTarget: "collapseWC",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminwcQuote",
            stateVal: "otherFinAdminWCQuote",
          },
        ],
      },
      {
        name: "CGTMSE",
        stateVal: "otherFinAdminCGTMSE",
        collapseTarget: "collapseCGTMSE",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdmincgtmseQuote",
            stateVal: "otherFinAdminCGTMSEQuote",
          },
        ],
      },
      {
        name: "Bank Gurantee",
        stateVal: "otherFinAdminbg",
        collapseTarget: "collapseBG",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminbgQuote",
            stateVal: "otherFinAdminbgQuote",
          },
        ],
      },
      {
        name: "Supply Chain Finance",
        stateVal: "otherFinAdminSCF",
        collapseTarget: "collapseSCF",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminscfQuote",
            stateVal: "otherFinAdminSCFQuote",
          },
        ],
      },
      // {
      //   "name": "Domestic Invoice Discounting",
      //   "stateVal": "otherFinDID",
      //   "collapseTarget": "collapseDID",
      //   "stages": [
      //     { "name": "Apply For Finance", redirectURL: "/applyfinDID", stateVal: "otherFinDIDApplyFin" },
      //     { "name": "Approved Finance", redirectURL: "/invoiceFinance", stateVal: "otherFinDIDApprovedFin" }
      //   ]
      // }
    ],
    collapseTarget: "collapseOtherFin",
  },
  {
    id: "7",
    icon: "assets/images/sidebarV2/tradeDiscovery.svg",
    activeIcon: "assets/images/sidebarV2/tradeDiscovery.svg",
    text: "Logistic",
    level: 1,
    stateVal: "ShipmentBooking",
    subItems: [
      {
        name: "Booking",
        stateVal: "ShipmentBookingQuotes",
        redirectURL: "/ShipmentBookingQuotes",
        permissionVal: "ShipmentBookingQuotes",
        notificationVal: "ShipmentBookingQuotes",
      },
    ],
    collapseTarget: "ShipmentBooking",
    notificationVal: "ShipmentBooking",
  },
];

export const buyersNavConfig = [
  {
    id: "4",
    icon: "assets/images/sidebarV2/invoice_menu.svg",
    activeIcon: "assets/images/sidebarV2/invoice_menu_active.svg",
    text: "Export Factoring",
    level: 1,
    stateVal: "invoice",
    subItems: [
      {
        name: "Sign Agreement",
        stateVal: "sign-invoice-agreement",
        redirectURL: "/sign-invoice-agreement",
        permissionVal: "sign-invoice-agreement",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseinv",
    notificationVal: "invoicee discounting",
  },
];

export const FinanciersNavConfig = [
  {
    id: "1",
    icon: "assets/images/sidebarV2/dashboard.svg",
    text: "Dashboard",
    level: 0,
    stateVal: "dashboard",
    redirectURL: "/dashboard",
    permissionVal: "dashboard",
    notificationVal: "dashboard",
  },
  {
    id: "2",
    icon: "assets/images/sidebarV2/buyermanagement.svg",
    text: "Supplier List",
    level: 0,
    stateVal: "supplierList",
    redirectURL: "/supplierList",
    permissionVal: "",
    notificationVal: "",
  },
  {
    id: "3",
    icon: "assets/images/sidebarV2/lc_menu.svg",
    text: "Letter of Credit",
    level: 1,
    stateVal: "Lc",
    subItems: [
      {
        name: "Limit Application",
        stateVal: "LcDiscountingV2",
        redirectURL: "/finLCQuotes",
        permissionVal: "LcDiscountingV2",
      },
      {
        name: "Finance Application",
        stateVal: "LcFinApplication",
        redirectURL: "/lcFinApplication",
        permissionVal: "lcFinApplication",
      },
    ],
    collapseTarget: "collapseLc",
    notificationVal: "lc",
  },
  {
    id: "4",
    icon: "assets/images/sidebarV2/invoice_menu.svg",
    activeIcon: "assets/images/sidebarV2/invoice_menu_active.svg",
    text: "Export Factoring",
    level: 1,
    stateVal: "invoice",
    subItems: [
      {
        name: "Limit Application",
        stateVal: "fininvoiceQuotes",
        redirectURL: "/finInvoiceQuotes",
        permissionVal: "finInvoiceQuotes",
        notificationVal: "",
      },
      {
        name: "Finance Application",
        stateVal: "invoice_financeApplication",
        redirectURL: "/financeApplication",
        permissionVal: "financeApplication",
        notificationVal: "",
      },
      {
        name: "Disbursement",
        stateVal: "invoice_disbursement",
        redirectURL: "/disbursementV2",
        permissionVal: "disbursementV2",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseinv",
    notificationVal: "invoicee discounting",
  },
  {
    id: "5",
    icon: "assets/images/sidebarV2/other_financial.svg",
    text: "Other Financial",
    level: 2,
    stateVal: "otherFin",
    subItems: [
      {
        name: "Working Capital",
        stateVal: "otherFinworkingCapital",
        collapseTarget: "collapseWC",
        stages: [
          {
            name: "Quote",
            redirectURL: "/wcFinancierQuote",
            stateVal: "otherFinworkingCapitalFinancierQuote",
          },
        ],
      },
      {
        name: "CGTMSE",
        stateVal: "otherFinCGTMSE",
        collapseTarget: "collapseCGTMSE",
        stages: [
          {
            name: "Quote",
            redirectURL: "/cgtmseFinancierQuote",
            stateVal: "otherFinCGTMSEFinancierQuote",
          },
        ],
      },
      {
        name: "Bank Gurantee",
        stateVal: "bg",
        collapseTarget: "collapseBG",
        stages: [
          {
            name: "Quote",
            redirectURL: "/bgFinancierQuote",
            stateVal: "bgFinancierQuote",
          },
          {
            name: "Confirmed BG",
            redirectURL: "/bgConfirmedFinancier",
            stateVal: "bgConfirmedFinancier",
          },
        ],
      },
      {
        name: "Supply Chain Finance",
        stateVal: "otherFinSCF",
        collapseTarget: "collapseSCF",
        stages: [
          {
            name: "Limit Application",
            redirectURL: "/scfFinancierQuote",
            stateVal: "otherFinSCFFinancierQuote",
          },
          {
            name: "Approved Limit",
            redirectURL: "/approvedLimitSCF",
            stateVal: "otherFinSCFApprovedLimit",
          },
        ],
      },
      {
        name: "Domestic Invoice Discounting",
        stateVal: "otherFinDID",
        collapseTarget: "collapseDID",
        stages: [
          {
            name: "Finance Application",
            redirectURL: "/",
            stateVal: "otherFinDIDApplyFin",
          },
          {
            name: "Approved Finance",
            redirectURL: "/",
            stateVal: "otherFinDIDApprovedFin",
          },
        ],
      },
    ],
    collapseTarget: "collapseOtherFin",
  },
  // {
  //   "id": "6",
  //   "icon": "assets/images/sidebarV2/tradeDiscovery.svg",
  //   "text": "Trade Discovery",
  //   "level": 0,
  //   "stateVal": "tradeDiscovery",
  //   "redirectURL": "/tradeDiscovery",
  //   "permissionVal": "tradeDiscovery",
  //   "notificationVal": "tradeDiscovery"
  // },
  {
    id: "7",
    icon: "assets/images/insurance.svg",
    text: "Insurance",
    level: 1,
    stateVal: "other_services",
    permissionVal: "",
    notificationVal: "other_services",
    subItems: [
      {
        name: "Quote",
        redirectURL: "/insuranceFinancierQuote",
        stateVal: "other_services_credit_insurance_quote",
      },
      // {
      //   "name": "Trade Credit Insurance",
      //   "stateVal": "other_services_credit_insurance",
      //   "redirectURL": "/CreditInsurance",
      //   "permissionVal": "",
      //   "notificationVal": ""
      // }
    ],
    collapseTarget: "collapseOtherIns",
  },
  {
    id: "8",
    icon: "assets/images/logistics.svg",
    text: "Logistics",
    level: 1,
    stateVal: "logistics",
    permissionVal: "",
    notificationVal: "",
    subItems: [],
    collapseTarget: "collapseOtherlogistics",
  },
  // {
  //   "id": "9",
  //   "icon": "assets/images/document_vault.svg",
  //   "text": "Document Vault",
  //   "level": 1,
  //   "stateVal": "document_vault",
  //   "permissionVal": "",
  //   "notificationVal": "",
  //   "subItems": [],
  //   "collapseTarget": "collapseOtherVault",

  // },
  // {
  //   "id": "10",
  //   "icon": "assets/images/Edocs.svg",
  //   "text": "E-Docs",
  //   "level": 1,
  //   "stateVal": "Edocs",
  //   "permissionVal": "",
  //   "notificationVal": "Edocs",
  //   "subItems": [
  //     {
  //       "name": "Letter of Credit",
  //       "stateVal": "edocsLC",
  //       "redirectURL": "/edocsLC",
  //       "permissionVal": "",
  //       "notificationVal": ""
  //     },
  //   ],
  //   "collapseTarget": "collapseOtherEdocs",

  // },
  {
    id: "10",
    icon: "assets/images/edocs.png",
    text: "Edocs",
    level: 1,
    stateVal: "edocs",
    permissionVal: "",
    notificationVal: "edocs",
    subItems: [
      {
        name: "Letter of Credit",
        stateVal: "edocsLC",
        redirectURL: "/edocsLC",
        permissionVal: "",
        notificationVal: "",
      },
    ],
    collapseTarget: "edocs",
  },
  {
    id: "11",
    icon: "assets/images/sidebarV2/chatroom2.svg",
    text: "Chat Room",
    level: 0,
    stateVal: "ChatRoomV2",
    redirectURL: "/ChatRoomV2",
    permissionVal: "ChatRoomV2",
    notificationVal: "chat room",
  },
  {
    id: "12",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Reports",
    level: 1,
    stateVal: "Reportv2",
    localStorageKey: "report_name",
    subItems: [
      {
        name: "Payment",
        stateVal: "Reportv2",
        redirectURL: "/Reportv2",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      {
        name: "Finance",
        stateVal: "Reportv2",
        redirectURL: "/Reportv2",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseReports",
    notificationVal: "invoicee discounting",
  },
  {
    id: "13",
    icon: "assets/images/sidebarV2/lc_menu.svg",
    text: "SBLC",
    level: 1,
    stateVal: "sblc",
    subItems: [
      {
        name: "Quote",
        stateVal: "sblcQuotations",
        redirectURL: "/sblcQuotations",
        permissionVal: "sblcQuotations",
      },
      {
        name: "Term Sheet",
        stateVal: "sblcTermSheet",
        redirectURL: "/sblcTermSheet",
        permissionVal: "sblcTermSheet",
      },
    ],
    collapseTarget: "collapseLc",
    notificationVal: "sblc",
  },
];

export const AdminConfig = [
  {
    id: "1",
    icon: "assets/images/sidebarV2/dashboard.svg",
    text: "Dashboard",
    level: 0,
    stateVal: "dashboard",
    redirectURL: "/dashboard",
    permissionVal: "dashboard",
    notificationVal: "buyer management",
  },
  {
    id: "2",
    icon: "assets/images/sidebarV2/task_icon.svg",
    text: "Task Manager",
    level: 1,
    stateVal: "taskManager",
    subItems: [
      {
        name: "Users",
        stateVal: "taskManagerUsers",
        redirectURL: "/taskManager",
        permissionVal: "taskManager",
      },
      {
        name: "Enquiry",
        stateVal: "taskManagerEnquiry",
        redirectURL: "/enquiry",
        permissionVal: "enquiry",
      },
      {
        name: "Call List",
        stateVal: "taskManagerCallList",
        redirectURL: "/callList",
        permissionVal: "callList",
        collapseSideBar: true,
      },
      {
        name: "Corporate",
        stateVal: "taskManagercorporate",
        redirectURL: "/corporate",
        permissionVal: "corporate",
      },
      {
        name: "Financer",
        stateVal: "taskManagerfinancier",
        redirectURL: "/financier",
        permissionVal: "financier",
      },
    ],
    collapseTarget: "collapseTaskManager",
    notificationVal: "tm",
  },
  {
    id: "3",
    icon: "assets/images/sidebarV2/tradeDiscovery.svg",
    text: "Trade Discovery",
    level: 0,
    stateVal: "tradeDiscovery",
    redirectURL: "/tradeDiscovery",
    permissionVal: "tradeDiscovery",
    notificationVal: "tradeDiscovery",
  },
  {
    id: "4",
    icon: "assets/images/sidebarV2/lc_menu.svg",
    text: "Letter of Credit",
    level: 1,
    stateVal: "Lc",
    subItems: [
      {
        name: "Limit",
        stateVal: "lcLimit",
        redirectURL: "/lcLimit",
        permissionVal: "lcLimit",
      },
    ],
    collapseTarget: "collapseLc",
    notificationVal: "lc",
  },
  {
    id: "5",
    icon: "assets/images/sidebarV2/invoice_menu.svg",
    activeIcon: "assets/images/sidebarV2/invoice_menu_active.svg",
    text: "Export Factoring",
    level: 1,
    stateVal: "invoice",
    subItems: [
      {
        name: "Limit",
        stateVal: "invoiceLimit",
        redirectURL: "/invoiceLimit",
        permissionVal: "invoiceLimit",
        notificationVal: "",
      },
      {
        name: "Finance",
        stateVal: "invoiceFinance",
        redirectURL: "/invoiceFinance",
        permissionVal: "invoiceFinance",
        notificationVal: "",
      },
      {
        name: "Approved Finance",
        stateVal: "invoiceApprovedFinance",
        redirectURL: "/invoiceApprovedFinance",
        permissionVal: "invoiceApprovedFinance",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseinv",
    notificationVal: "invoicee discounting",
  },
  {
    id: "6",
    icon: "assets/images/sidebarV2/buyermanagement.svg",
    text: "User Management",
    localStorageKey: "user_onboard",
    level: 1,
    stateVal: "UserOnboard",
    subItems: [
      {
        name: "Exporter",
        stateVal: "Exporter",
        redirectURL: "/usersonboard?tab=Exporter",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Financers",
        stateVal: "Financers",
        redirectURL: "/usersonboard?tab=Financers",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "Channel Partner",
        stateVal: "Channel Partner",
        redirectURL: "/usersonboard?tab=Channel Partner",
        permissionVal: "",
        notificationVal: "",
      },
      {
        name: "User Features",
        stateVal: "User Features",
        redirectURL: "/usersonboard",
        permissionVal: "",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseUserOnboard",
    notificationVal: "",
  },
  {
    id: "7",
    icon: "assets/images/sidebarV2/CRM_admin.svg",
    text: "CRM",
    level: 1,
    stateVal: "CRM",
    subItems: [
      {
        name: "Leads",
        stateVal: "CRMleads",
        redirectURL: "/leads",
        permissionVal: "leads",
        notificationVal: "",
      },
      {
        name: "Data",
        stateVal: "CRMdata",
        redirectURL: "/crmdata",
        permissionVal: "crmdata",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseCRM",
    notificationVal: "",
  },
  {
    id: "8",
    icon: "assets/images/sidebarV2/walletBlack.png",
    text: "Payments",
    level: 0,
    stateVal: "adminPayments",
    redirectURL: "/adminPayments",
    permissionVal: "adminPayments",
    notificationVal: "",
  },
  {
    id: "9",
    icon: "assets/images/sidebarV2/invoiceReceipt.svg",
    activeIcon: "assets/images/sidebarV2/invoiceReceipt.svg",
    text: "Invoice",
    level: 1,
    stateVal: "generateInvoice",
    subItems: [
      {
        name: "Financier",
        stateVal: "generateInvoiceFinancier",
        redirectURL: "/generateInvoiceFinancier",
        permissionVal: "generateInvoiceFinancier",
        notificationVal: "",
      },
      {
        name: "Channel Partner",
        stateVal: "generateInvoiceCP",
        redirectURL: "/generateInvoiceCP",
        permissionVal: "generateInvoiceCP",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseInvoice",
    notificationVal: "",
  },
  {
    id: "10",
    icon: "assets/images/sidebarV2/chatroom2.svg",
    text: "Chat Room",
    level: 1,
    stateVal: "Chat",
    subItems: [
      {
        name: "Admin",
        stateVal: "Chat_Admin",
        redirectURL: "/ChatRoomV2",
        permissionVal: "ChatRoomV2",
        notificationVal: "",
      },
      {
        name: "Users",
        stateVal: "Chat_Users",
        redirectURL: "/ChatRoomV2",
        permissionVal: "ChatRoomV2",
        notificationVal: "",
      },
      {
        name: "Internal",
        stateVal: "Chat_Internal",
        redirectURL: "/ChatRoomV2",
        permissionVal: "ChatRoomV2",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseCR",
    localStorageKey: "chat_type",
    notificationVal: "",
  },
  {
    id: "11",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Reports",
    level: 1,
    stateVal: "AdminReports",
    localStorageKey: "admin_report_name",
    subItems: [
      {
        name: "Users",
        stateVal: "Users",
        redirectURL: "/AdminReports",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      {
        name: "Application",
        stateVal: "Application",
        redirectURL: "/AdminReports",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      {
        name: "Buyers",
        stateVal: "Buyers",
        redirectURL: "/AdminReports",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
      {
        name: "CRM",
        stateVal: "CRM",
        redirectURL: "/AdminReports",
        permissionVal: "Reportv2",
        notificationVal: "",
      },
    ],
    collapseTarget: "collapseReports",
    notificationVal: "",
  },
  {
    id: "12",
    icon: "assets/images/sidebarV2/masterdata.svg",
    text: "Master Data",
    level: 0,
    stateVal: "masterdata",
    redirectURL: "/masterdata",
    permissionVal: "masterdata",
    notificationVal: "",
  },
  {
    id: "13",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Buyer Credit Check",
    level: 0,
    stateVal: "buyercreditcheck",
    redirectURL: "/buyercreditcheck",
    permissionVal: "buyercreditcheck",
    notificationVal: "",
  },
  {
    id: "14",
    icon: "assets/images/sidebarV2/reports_icon.svg",
    text: "Supplier Credit Check",
    level: 0,
    stateVal: "suppliercreditcheck",
    redirectURL: "/suppliercreditcheck",
    permissionVal: "suppliercreditcheck",
    notificationVal: "",
  },
  {
    id: "15",
    icon: "assets/images/sidebarV2/other_financial.svg",
    text: "Other Financial",
    level: 2,
    stateVal: "otherFinAdmin",
    subItems: [
      {
        name: "Working Capital",
        stateVal: "otherFinAdminworkingCapital",
        collapseTarget: "collapseWC",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminwcQuote",
            stateVal: "otherFinAdminWCQuote",
          },
        ],
      },
      {
        name: "CGTMSE",
        stateVal: "otherFinAdminCGTMSE",
        collapseTarget: "collapseCGTMSE",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdmincgtmseQuote",
            stateVal: "otherFinAdminCGTMSEQuote",
          },
        ],
      },
      {
        name: "Bank Gurantee",
        stateVal: "otherFinAdminbg",
        collapseTarget: "collapseBG",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminbgQuote",
            stateVal: "otherFinAdminbgQuote",
          },
        ],
      },
      {
        name: "Supply Chain Finance",
        stateVal: "otherFinAdminSCF",
        collapseTarget: "collapseSCF",
        stages: [
          {
            name: "Quote",
            redirectURL: "/otherFinAdminscfQuote",
            stateVal: "otherFinAdminSCFQuote",
          },
        ],
      },
      // {
      //   "name": "Domestic Invoice Discounting",
      //   "stateVal": "otherFinDID",
      //   "collapseTarget": "collapseDID",
      //   "stages": [
      //     { "name": "Apply For Finance", redirectURL: "/applyfinDID", stateVal: "otherFinDIDApplyFin" },
      //     { "name": "Approved Finance", redirectURL: "/invoiceFinance", stateVal: "otherFinDIDApprovedFin" }
      //   ]
      // }
    ],
    collapseTarget: "collapseOtherFin",
  },
  {
    id: "16",
    icon: "assets/images/contractMangIcon.png",
    text: "Contract Management",
    level: 0,
    stateVal: "contractManagement",
    redirectURL: "/contractManagementList",
    permissionVal: "contractManagement",
    notificationVal: "contractManagement",
  },
  {
    id: "17",
    icon: "assets/images/sidebarV2/tradeDiscovery.svg",
    activeIcon: "assets/images/sidebarV2/tradeDiscovery.svg",
    text: "Logistic",
    level: 1,
    stateVal: "ShipmentBooking",
    subItems: [
      {
        name: "Booking",
        stateVal: "ShipmentBookingQuotes",
        redirectURL: "/ShipmentBookingQuotes",
        permissionVal: "ShipmentBookingQuotes",
        notificationVal: "ShipmentBookingQuotes",
      },
    ],
    collapseTarget: "ShipmentBooking",
    notificationVal: "ShipmentBooking",
  },
];

export const shipperConfig = [
  {
    id: "1",
    icon: "assets/images/sidebarV2/tradeDiscovery.svg",
    activeIcon: "assets/images/sidebarV2/tradeDiscovery.svg",
    text: "Logistic",
    level: 1,
    stateVal: "ShipmentBooking",
    subItems: [
      {
        name: "Quotes",
        stateVal: "ShipmentBookingShipperQuotes",
        redirectURL: "/ShipmentBookingShipperQuotes",
        permissionVal: "ShipmentBookingShipperQuotes",
        notificationVal: "ShipmentBookingShipperQuotes",
      },
      {
        name: "Contract",
        stateVal: "ShipmentBookingShipperContract",
        redirectURL: "/ShipmentBookingShipperContract",
        permissionVal: "ShipmentBookingShipperContract",
        notificationVal: "ShipmentBookingShipperContract",
      },
    ],
    collapseTarget: "ShipmentBooking",
    notificationVal: "ShipmentBooking",
  },
];

export async function getMimeTypeOfFile(file) {
  const getMimeType = (signature) => {
    switch (signature) {
      case "89504E47":
        return "image/png";
      case "47494638":
        return "image/gif";
      case "25504446":
        return "application/pdf";
      case "FFD8FFDB":
      case "FFD8FFE0":
      case "FFD8FFE1":
        return "image/jpeg";
      case "504B0304":
        return "application/zip";
      default:
        return "UNK";
    }
  };
  return await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach((byte) => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase();
        const mimeType = getMimeType(hex);
        resolve(mimeType);
      }
    };
    fileReader.readAsArrayBuffer(file.slice(0, 4));
  });
}

export async function convertZipFileArrayToFilePdfObjects(zipFileArray) {
  try {
    const fileObjects = [];
    for (const [relativePath, zipEntry] of Object.entries(zipFileArray.files)) {
      const blob = await zipEntry.async("blob");
      // Create a File object from the extracted blob
      const extractedFile = new File([blob], zipEntry.name, {
        type: await getMimeTypeOfFile(blob),
      });
      fileObjects.push(extractedFile);
    }
    return fileObjects;
  } catch (error) {
    console.log("errorinconvertZipFileArrayToFileObject", error);
    return [];
  }
}

export function getPercentage(outof, total) {
  try {
    return isNaN(((outof / total) * 100).toFixed(2) / 1)
      ? 0
      : ((outof / total) * 100).toFixed(2) / 1;
  } catch (error) {
    return 0;
  }
}

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const subAdminDataDownloadLimit = 100;

export function getCO2EmissionTotal(data) {
  let total = 0;
  for (let index = 0; index < Object.keys(data).length; index++) {
    const element = Object.keys(data)[index];
    for (let j = 0; j < data[element].length; j++) {
      total += data[element][j].co2 / 1 || 0;
    }
  }
  return total.toFixed(2).split(".")[1] != "00" ? total.toFixed(2) : total;
}

export function getTotalLogisticAmount(data) {
  let total = 0;
  let currency = "NA";
  for (let index = 0; index < Object.keys(data).length; index++) {
    const element = Object.keys(data)[index];
    for (let j = 0; j < data[element].length; j++) {
      total += data[element][j].amount / 1 || 0;
      currency = data[element][j].currency;
    }
  }
  total = total.toFixed(2).split(".")[1] != "00" ? total.toFixed(2) : total;
  return `${currency} ${total}`;
}

export function mapOrganizationTypeWithKarza(karzaType) {
  return (
    companyTypes.filter((i) => {
      if (i.karzaType === karzaType) {
        return i;
      }
    })?.[0]?.alt || ""
  );
}

export const syndicationChargesTNCHtml = ({ userName }) => {
  return `<!DOCTYPE html>
<html >

<head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
<title>file_1671729340887</title>
<meta name="author" content="WCC Legal" />
<style type="text/css">
    * {
        margin: 0;
        padding: 0;
        text-indent: 0;
    }

    #l1 {
        padding-left: 0pt;
        counter-reset: c1 1;
    }

    #l1>li>*:first-child:before {
        counter-increment: c1;
        content: counter(c1, decimal)". ";
        color: black;
        font-family: Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 13px;
    }

    #l1>li:first-child>*:first-child:before {
        counter-increment: c1 0;
    }

    #l2 {
        padding-left: 0pt;
    }

    #l2>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l3 {
        padding-left: 0pt;
    }

    #l3>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l4 {
        padding-left: 0pt;
    }

    #l4>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l5 {
        padding-left: 0pt;
    }

    #l5>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l6 {
        padding-left: 0pt;
    }

    #l6>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l7 {
        padding-left: 0pt;
    }

    #l7>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l8 {
        padding-left: 0pt;
    }

    #l8>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 13px;
    }

    #l9 {
        padding-left: 0pt;
    }

    #l9>li>*:first-child:before {
        content: " ";
        color: black;
        font-family: Symbol, serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;  
        font-size: 13px;
    }
  * {
          font-size: 13px
        }

        table, tr, td {
    border: 2px solid black;
  }
  td {
    padding: 0.5rem !important;
  }
</style>
</head>

<body>
<p style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;"><span class="p">These Terms of Use
        including the </span><a href="https://www.tradereboot.com/privacy-policy">Privacy Policy</a><span class="p">
        govern the use of the website of TradeReboot </span><a href="https://www.tradereboot.com/"
        >(www.tradereboot.com)</a><span class="p"> (“Website”) and the
        services(“Services”) provided on the Website. The Company kindly requests that all users read the following
        terms and conditions carefully. By using the Website including but not limited to accessing or visiting or
        browsing the Website, you</span></p>
<p style="padding-left: 5pt;text-indent: 0pt;text-align: left;">(“Visitor/User/Member/Subscriber”) indicate your
    acceptance to these Terms of Use and agree to abide by them. The Terms of Use constitute a legal agreement
    between you, as the user of the Website and us, the owner of the Website. If you do not agree to these Terms of
    Use, please refrain from using this Website.</p>

<p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"><span><b>Acceptance of Commercials</b></span></p>
  
<table ><tr><td ><b>Borrower / Obligor</b><td >${userName} & its group Companies / Subsidiaries<tr><td ><b>Instrument</b><td >Export Factoring / Receivable Finance / Structured Trade Finance / Letter of Credit<tr><td ><b>Trade Funding Limits</b><td >As per requirement<tr><td ><b>Syndication Fee</b><td >•	Upto USD 500,000 limits: 1% (plus tax applicable) of the initial facility amount plus any enhancement of the limits by the respective lenders<br><br>•	Above USD 500,000 limits: 0.75% (plus tax applicable) of the initial facility amount plus any enhancement of the limits by the respective lenders<br><br>•	For example: If the Exporter avails USD 750,000 limits over the platform, 1% shall be charged on USD 500,000 and 0.75% shall be charged on balance USD 250,000<tr><td ><b>Payment terms of Syndication fee</b><td >100% of the fees to be paid at the time of first disbursement for respective lenders.</table>

<ol style="padding-top:2rem" id="l1">
    <li data-list-text="1.">
        <b style="text-indent: -18pt;text-align: justify;">INFORMATION ABOUT US AND SERVICES
            OFFERED</b>
        <ul id="l2">
            <li  >
                 The Website is owned and
                    operated by TradeReboot Fintech Pvt. Ltd., a company incorporated and registered under the laws
                    of India
            </li>
            <li  >
                 TradeReboot hosts a cloud
                    hosted platform which, inter alia, markets financial products such as loans that enables the
                    entities enrolled on the platform to engage in structured supply chain finance using the
                    platform (hereinafter referred to as “TRF Platform” or “TRF”). The Electronic Platform is an
                    electronic interface providing solutions for online commodity trading using the <u>Commodity
                        Trading</u> System (‘Services’). The Electronic Platform or TradeReboot is not a broker,
                    agent or intermediary of the User or Subscriber.
            </li>
            <li  >
                 Services include Export
                    Factoring/ Invoice Discounting, Letter of Credit Confirmation, LC Discounting, Forex Services,
                    Trade Credit Insurance, etc.
            </li>
            <li  >
                 Before availing the Services,
                    User has to register and pay the registration fee (‘Fee’) to create an account with the
                    Electronic Platform. The User registering with the Electronic Platform <u>(‘Subscriber’)</u>
                    shall be entitled to offer 10 (ten) other User(s) a right to become Subscribers to the
                    Electronic Platform, as long as the original Subscriber’s registration remains valid, without
                    any additional Fee. Such Subscribers shall also individually consent to this Terms of Use and
                    the Privacy Policy.
            </li>
            <li  >
                 The User shall be liable to
                    make an online payment defined as ‘Transaction Fees’ to TradeReboot on the platform. Transaction
                    fees for the purpose of this agreement is defined as the payment made for any limit or funds,
                    sanctioned or disbursed to The User on The Electronic Platform TradeReboot. The amount of the
                    Transaction Fees shall be decided solely by the TradeReboot based on the amount of sanction
                    and/or disbursal. Only after the payment of such fees the User will be able to access the limit
                    or funds, sanctioned or disbursed.
            </li>
            <li  >
                 The Subscriber shall be
                    responsible for keeping the username and password and security identification details or devices
                    secure and confidential and shall be solely responsible for all transactions entered and or
                    conducted by any person whosoever through the Electronic Platform using the Subscriber’s
                    identification details whether or not such person was authorized to do so.
            </li>
            <li  >
                 The Electronic Platform is
                    under no obligation to verify the authenticity of any instruction for transaction conducted or
                    purported to be conducted by the Subscriber and if so done, the same may only be means of
                    verification of the Subscriber’s password and or security identification details.
            </li>
            <li  >
                 Without prejudice to the
                    aforesaid, the Subscriber shall immediately inform the TradeReboot through ‘Contact Us’
            </li>
        </ul>
    </li><br>
    <li data-list-text="2.">
        <b style="text-indent: -18pt;text-align: justify;">INTELLECTUAL PROPERTY RIGHTS</b>
        <ul id="l3">
            <li  >When you
                    visit our website, we give you a limited license to access and use our information for personal
                    use.           </li>
            <li  >
                 You are permitted to download
                    the information available on the Website to any instrument for your use only provided it does
                    not include the right to copy or reproduce the information of our website on any other platform
                    or medium, without our prior written permission. You shall not use our information for any other
                    purpose other than for the aforesaid. You agree that any use of the proprietary information
                    displayed on the Website shall infringe our intellectual property rights.
            </li>
            <li  >
                 We have copyright on all the
                    contents displayed on the Website including graphics, logo, and software that is either owned or
                    licensed to us other than any third-party contents which are specifically identified as such.
                    Any infringement of our intellectual property rights shall be governed by the applicable law in
                    India.
            </li>
            <li  >
                 The license to access and use
                    the Website does not include the right to copy or reproduce the information of our website on
                    any other platform or medium, without our prior written permission.
            </li>
            <li  >
                 Except where otherwise
                    specified, any word, logo, or device to which is attached the symbols shall be considered as a
                    registered trademark that is either owned by us or which we have license to use. The right to
                    use the Website does not give a license to use those trademarks in any way.
            </li>
            <li  >
                 The User(s) or the
                    Subscriber(s) may interact with the agents or employees of TradeReboot using the <a href="https://www.tradereboot.com/contact-us"> ‘Help’ or
                    ‘Contact Us’ </a>sections on the Electronic Platform. User(s) are solely responsible for their
                    interactions with the consultants of the Website and any content that the User may share with
                    them.
            </li>
            <li  >
                 TradeReboot is not liable for
                    any damage or harm resulting from any posts by or interactions between Users.
            </li>
            <li  >
                 TradeReboot reserves the
                    right, but has no obligation, to monitor interactions between the Website (including its
                    consultants) and the Users.
            </li>
        </ul>
    </li><br>
    <li data-list-text="3.">
        <b style="text-indent: -18pt;text-align: left;">LINKED WEBSITES</b>
        <ul id="l4">
            <li  >
                 You may be able to access and
                    view third party websites through this Website. The links are provided for your convenience only
                    and may not be updated at all times.
            </li>
            <li  >
                 We do not endorse, review,
                    control or examine third party websites and we are not responsible for any content posted on
                    such third party websites. You understand that the inclusion of links on the Website is not
                    intended as an endorsement or recommendation of any linked website or content of such website.
                
            </li>
            <li  >
                 You agree that your access to
                    any third party website is governed by the terms of use of that website and has no relation to
                    the Terms of Use of the Website. You agree and understand that it is your responsibility to
                    comply with the terms and conditions of that website as well.
            </li>
        </ul>
    </li><br>
    <li data-list-text="4.">
        <b style="text-indent: -18pt;line-height: 13pt;text-align: left;">DISCLAIMER OF
            WARRANTIES AND LIABILITIES</b>
        <ul id="l5">
            <li  >
                 The Subscriber acknowledges
                    that it is fully aware of and understands the risks associated with availing the Services
                    including the risk of misuse and unauthorized use of his username, passwords and other security
                    identification details by a third party. The Subscriber shall be fully liable and responsible
                    for any and all transactions done through the use of its username, password and security
                    identification details. Further at no point in time shall TradeReboot and or its affiliates be
                    held liable for any loss, whether notional or actual, direct or indirect, that may be suffered
                    by the Subscriber on account of the use and or misuse of the password, username and security
                    identification details.
            </li>
            <li  >The
                    Subscriber agrees to hold TradeReboot harmless from any claims and confirms that TradeReboot
                    shall not be responsible for any loss, actual or notional, caused directly or indirectly as a
                    result of unauthorized access, equipment failure, communications failure, or any condition
                    beyond the control of TradeReboot that may prevent the Subscriber from entering or completing
                    any transaction. The Subscriber further agrees that it shall not be compensated by TradeReboot
                    for any loss of opportunity or incomplete transactions.
            </li>
            <li  >
                 Upon execution or cancellation
                    of any transaction placed through the Electronic Platform, the Subscriber shall be given a
                    confirmation, on the Electronic Platform or electronic email or SMS or any other means and this
                    shall be deemed to be valid delivery thereof by the Subscriber. It shall be the responsibility
                    of the Subscriber to contact Electronic Platform for all such confirmations that are not
                    received by the Subscriber within stipulated time.
            </li>
            <li  >
                 The Subscriber is aware that
                    the Electronic Platform is vulnerable for disruptions, breakdowns or failures. In the event of
                    cancellation of transactions due to the happening of such events or vulnerabilities due to
                    failure or disruption or breakdown of the Electronic Platform, the Subscriber may not be able to
                    execute the desired transactions. In such event TradeReboot and or its affiliates do not accept
                    responsibility for the losses, costs, expenses or damages incurred or may be incurred by the
                    Subscriber due to such eventualities.
            </li>
            <li  >
                 While TradeReboot has
                    endeavored to ensure that all the information on the Electronic Platform is correct, TradeReboot
                    neither warrants nor makes any representations regarding the quality, accuracy or completeness
                    of any data, information, or Electronic Platform (whether any third-party website, applications
                    or this Electronic Platform) or Service.
            </li>
            <li  >
                 TradeReboot and its
                    representatives, directors, employees, agents and affiliates shall not be liable for any loss :
                
            </li>
            <li  >
                 of revenue, income, any
                    direct, special, indirect or consequential loss or damage incurred by the User in connection
                    with the Electronic Platform; and
            </li>
            <li  >
                 in connection with the use,
                    inability to use, or results of the use of the Electronic Platform, any webpage or website
                    linked to it and any materials posted on it, including, loss of income or revenue; loss of
                    business; loss of profits or contracts; loss of anticipated savings; loss of data; loss of
                    goodwill; wasted management or office time; and
            </li>
            <li  >
                 of any other kind, however
                    arising and whether caused by tort (including negligence), breach of contract or otherwise, even
                    if foreseeable.
            </li>
            <li  >
                 TradeReboot and or its
                    affiliates shall not be liable for any possible hacks (including the database), bugs, malware or
                    faults in our system which may affect the Users, their computer hardware or software in any
                    possible way.
            </li>
            <li  >
                 The User agrees to be
                    responsible for their own use of the Electronic Platform and will use or access this Electronic
                    Platform in compliance as per the applicable laws of India.
            </li>
            <li  >
                 The User shall take the
                    responsibility to obtain valid permissions or licenses required on his or her respective devices
                    to be able to utilize the Services. TradeReboot does not take any responsibility for any act of
                    the User or Subscriber in breach of any such licenses.
            </li>
        </ul>
    </li><br>
    <li data-list-text="5.">
        <b style="text-indent: -18pt;text-align: left;">CONTENT OF THE WEBSITE</b>
        <ul id="l6">
            <li  >
                 We endeavour to ensure the
                    accuracy, updating and exhaustiveness of the information disseminated on this website and
                    reserve the right to change the content or presentation of this website at any time and without
                    notice.
            </li>
            <li  >
                 We cannot be held liable for
                    any decision made on the basis of information contained in the website or for the use that third
                    parties might make of it
            </li>
            <li  >Anyone who
                    wishes to use one or more of the services and/or one or more of the products presented on the
                    website is invited to contact us to ascertain the contractual conditions and tariffs applicable
                    to those products and/or services.
            </li>
            <li  >
                 Certain people or certain
                    countries may be allowed only restricted access to the products and services presented on the
                    website.
            </li>
            <li  >
                 None of the products and/or
                    services will be supplied by us to a person if the law of his/her country of origin or of any
                    other country that might concern that person prohibits them
            </li>
            <li  >
                 However, it remains the
                    responsibility of any interested party to check beforehand with his or her usual advisers that
                    his or her legal and fiscal status allows him or her to subscribe to the products and/or
                    services presented on the website.
            </li>
            <li  >
                 You also agree and understand
                    that the information displayed on the Website is for information purposes only and does not to
                    amount to any advice.
            </li>
            <li  >
                 You further acknowledge and
                    agree that we shall not be responsible for any defamatory, offensive or illegal conduct of third
                    parties on our Website, including users and operators of third-party websites
            </li>
        </ul>
    </li><br>
    <li data-list-text="6.">
        <b style="text-indent: -18pt;line-height: 13pt;text-align: left;">INDEMNITY</b>
        <ul id="l7">
            <li  >TradeReboot
                    retains the right to take legal course against the User, if the User’s act is illegal and or detrimental to the business
                    and or autonomy of TradeReboot or the Electronic Platform.
            </li>
            <li  >
                 The User agrees to indemnify
                    TradeReboot, its affiliates, officers, directors,employees and agents from and against any and
                    all third-party claims, liabilities, damages, losses (direct and indirect) or expenses,
                    including reasonable legal costs, arising out of or in any way connected with the usage of the
                    Electronic Platform and or any of its contents, the violation of the terms of these Terms of
                    Use, and violation of any third-party rights.
            </li>
        </ul>
    </li><br>
    <li data-list-text="7.">
        <b style="text-indent: -18pt;text-align: left;">AMENDMENTS TO THE TERMS OF USE</b>
        <ul id="l8">
            <li  >
                 We reserve the right to amend
                    the Terms of Use from time to time. Any amendment that is made will come into effect from the
                    moment it is displayed on the Website. The updated version of the Terms of Use shall supersede
                    any of the previous versions of the Terms of Use.
            </li>
            <li  >
                 The continued use of the
                    Website shall amount to your acceptance to the Terms of Use of the Website.
            </li>
        </ul>
    </li><br>
    <li data-list-text="8.">
        <b style="text-indent: -18pt;text-align: left;">GENERAL DISCLAIMER</b>
        <ul id="l9">
            <li  >
                 TradeReboot assumes no
                    responsibility for the accuracy of any information made available on this Website or in respect
                    of TradeReboot Services. Any information available on this Website, including information on
                    TradeReboot Services is subject to change without notice. TradeReboot may make improvements
                    and/or changes in the information or services set forth in this Website at any time and without
                    prior notice. Any mention of third party products or third party websites is for information
                    purposes only and constitutes neither an endorsement nor a recommendation from TradeReboot.
            </li>
            <li  >
                 ALL INFORMATION PROVIDED ON
                    THIS WEBSITE IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED,
                    INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY.
            </li>
            <li  >
                 TO THE EXTENT PERMITTED BY
                    APPLICABLE LAW, IN NO EVENT SHALL TRADEREBOOT, OR ANY OF ITS AFFILIATES, PARTNERS, SUPPLIERS OR
                    DISTRIBUTORS, BE LIABLE TO ANY PERSON FOR ANY GENERAL, DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR
                    CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT
                    LIMITATION, DAMAGES RESULTING FROM USE OF OR RELIANCE ON THE INFORMATION PRESENTED, LOSS OF
                    PROFITS OR REVENUES OR COSTS OF REPLACEMENT GOODS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE,
                    TORT OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THE USE OF OUR WEB SITE OR ANY
                    HYPERLINKED SITE, EVEN IF TRADEREBOOT HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH
                    DAMAGES. OUR AGGREGATE LIABILITY ARISING OUT OF THIS STATEMENT WILL NOT EXCEED USD ONE HUNDRED
                    (USD 100).
            </li>
        </ul>
    </li><br>
    <li data-list-text="9.">
        <b style="text-indent: -18pt;text-align: left;">GRIEVANCE</b>
    </li>
</ol>
<p class="s4" style="text-indent: 0pt;text-align: justify;"><a href="mailto:info@tradereboot.com"
        style=" color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 13px;"
        target="_blank">For any queries or grievances regarding Terms of Use, the Services offered on the Electronic
        Platform and the subsequent Privacy Policy etc. The user may contact at </a><span
        style=" color: #0462C1; font-family:Calibri, sans-serif; font-style: italic; font-weight: normal; text-decoration: underline; font-size: 13px;">info@tradereboot.com</span>.
</p>
</body>

</html>`;
};

export const printPDFFromBase64 = (base64String) => {
  // Convert the base64 string to binary
  const binaryString = atob(base64String);

  // Create a typed array from the binary string
  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  // Create a blob from the binary data
  const blob = new Blob([binaryData], { type: "application/pdf" });

  // Create a URL object from the blob
  const url = URL.createObjectURL(blob);

  // Create an iframe and set its source to the URL
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = url;
  document.body.appendChild(iframe);

  // Function to print the PDF
  const printPDF = () => {
    // Set print settings for A3 paper size
    iframe.contentWindow.print({
      paperSize: { width: "297mm", height: "420mm", margin: "0mm" }, // A3 dimensions with no margins
    });
  };

  // Call printPDF when the iframe is loaded
  iframe.onload = printPDF;

  // Cleanup function to remove the iframe and revoke the URL
  const cleanup = () => {
    document.body.removeChild(iframe);
    URL.revokeObjectURL(url);
  };

  return cleanup;
};

export const splitPdfIntoSinglePages = async (pdfFileObj) => {
  return new Promise(async (resolve) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(pdfFileObj);
      fileReader.onload = async () => {
        try {
          const pdfBytes = fileReader.result;
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const pages = pdfDoc.getPages();
          const pdfFiles = [];
          for (let i = 0; i < pages.length; i++) {
            const newPdfDoc = await PDFDocument.create();
            const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
            newPdfDoc.addPage(copiedPage);
            const pdfBytes = await newPdfDoc.save();
            const file = new File([pdfBytes], `page-${i + 1}.pdf`, {
              type: "application/pdf",
            });
            pdfFiles.push(file);
          }
          resolve({ status: true, msg: pdfFiles });
        } catch (error) {
          console.log("errorInsplitPdfIntoSinglePagesss", error);
          resolve({
            status: false,
            msg: "Unable to process selected file, check if it is not password protected",
          });
        }
      };
      fileReader.onerror = () => {
        resolve({
          status: false,
          msg: "Unable to process selected file, check if it is not password protected",
        });
      };
    } catch (error) {
      console.log("errorInsplitPdfIntoSinglePagesss", error);
      resolve({
        status: false,
        msg: "Unable to process selected file, check if it is not password protected",
      });
    }
  });
};

export function getMd5FromBase64(base64String) {
  // Decode the base64 string to a WordArray (CryptoJS format)
  const wordArray = cryptoJs.enc.Base64.parse(base64String);
  // Compute the MD5 hash
  const md5Hash = cryptoJs.MD5(wordArray).toString();
  return md5Hash;
}

export function FilterPermissionAccess(values) {
  let arr = [];
  values.forEach((ele) => {
    const filterval = exportersNavConfig.filter((item) => item.text == ele);
    arr.push(filterval);
  });
  return arr;
}


export const productTypes = [{ name: "Export LC Discounting" }, { name: 'Export LC Confirmation', }, { name: 'SBLC', },
{ name: 'Import LC Discounting', },
{ name: "Import LC Confirmation", }, { name: "PCFC", }, { name: "Buyer's Credit", }, { name: "Export Factoring", },
{ name: "Invoice Discounting", },
{ name: "Supply Chain", }, { name: 'Import Financing', }, { name: 'Usance Payment At Sight', },
{ name: 'PO Financing', }, { name: 'Reverse Factoring', },
{ name: 'Freight Finance', }, { name: 'Trade Credit Insurance', }]

function getBase64Size(base64String) {
  // Remove the data URI scheme (if present), such as "data:image/png;base64,"
  let base64Body = base64String?.split?.(',')?.[1] || base64String;
  // Calculate the length of the Base64 string in bytes
  let padding = (base64Body.slice(-2) === '==') ? 2 : (base64Body.slice(-1) === '=') ? 1 : 0;
  let byteLength = (base64Body.length * 3) / 4 - padding;
  return byteLength;
}

export const isFilePreviewAvailable = (base64String) => {
  try {
    const sizeInBytes = getBase64Size(base64String);
    const oneMB = 1 * 1024 * 1024; // 1 MB in bytes
    if (sizeInBytes > oneMB) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log("errorInisFilePreviewAvailable", error);

  }
}
