import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import SideBarV2 from "../partial/sideBarV2";
import HeaderV2 from "../partial/headerV2";
import { ToastContainer } from "react-toastify";
import NewTablev2 from "../../utils/newTablev2";
import Filter from "../InvoiceDiscounting/components/Filter";
import CommercialInvoice from "./CommercialInvoice";
import ProformaInvoice from "./ProformaInvoice";
import call from "../../service";
import moment from "moment";
import { Action } from "../myCounterPartComp/action";
import Pagination from "../InvoiceDiscounting/contract/components/pagination";
import { DocumentViewer } from "../../utils/documentViewer";
import { DocumentDownloader } from "../../utils/DocumentDownloader";
import { getDocDetails } from "../../utils/myFunctions";
import toastDisplay from "../../utils/toastNotification";
import TransactionDetails from "../contractManagement/transactionView";
import TimelineCard from "../contractManagement/cardForTimeline";
import TransactionTimeline from "../contractManagement/transactionTimeline";
import ChallanOrder from "./ChallanOrder";

export let dcTemplateTypes = [
  { name: 'Delivery Challan', src: '/assets/images/po_template.PNG' }
]

const DeliveryChallan = ({ userTokenDetails, navToggleState }) => {
  const queryParams = new URLSearchParams(window.location.search)
  let serarchParam = queryParams.get('search')

  const userTypeId = userTokenDetails.type_id ? userTokenDetails.type_id : null
  const userEmail = userTokenDetails.email ? userTokenDetails.email : null
  const userId = userTokenDetails.user_id ? userTokenDetails.user_id : null
  const userName = userTokenDetails.userName ? userTokenDetails.userName : null
  const subUserId = userTokenDetails.sub_user_id ? userTokenDetails.sub_user_id : null;
  const parentData = userTokenDetails.parent_data ? userTokenDetails.parent_data : null;

  const [showLoader, setshowLoader] = useState(false)
  const [filter, setFilter] = useState({ resultPerPage: 10, search: serarchParam ? serarchParam : '' })
  const [filterData, setFilterData] = useState({})
  const [count, setCount] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [templateSelectionForm, toggleTemplateSelectionForm] = useState({ show: false, data: { type: '' } })
  const [dbData, setDbData] = useState([])
  const [page, setPage] = useState(1)
  const [action, setAction] = useState({ show: false, index: null })
  const [viewDoc, toggleViewDoc] = useState({ show: false, doc: {} })
  const [dowdDoc, toggleDowdDoc] = useState({ show: false, doc: {} })
  const [selectedInvoiceData, setSelectedInvoiceData] = useState({})
  const [recentTemplates, setRecentTemplates] = useState([])
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [clickedDocForTD, setClickedDocForTD] = useState({})
  const [editDocument, setEditDocument] = useState({})
  const [showTimeline, setShowTimeline] = useState(false)

  useEffect(() => {
    call("POST", 'getRecentlyUsedEdocs', { userId, onlyDC: true }).then(res => {
      setRecentTemplates(res)
    })
    setFilterData({
      "Template Type": {
        "accordianId": 'invoiceType',
        type: "checkbox",
        data: dcTemplateTypes,
        labelName: "name"
      }
    })
  }, [])





  const [userPermissions, setUserPermissions] = useState(null);

  useEffect(() => {
    if (userTokenDetails.UserAccessPermission) {
      try {
        const parsedPermissions = JSON.parse(userTokenDetails.UserAccessPermission);
        setUserPermissions(parsedPermissions);
      } catch (error) {
        console.error("Failed to parse user permissions:", error);
      }
    }
  }, [userTokenDetails.UserAccessPermission]);

  const getSubPermissions = (componentPath) => {
    if (!userPermissions) return [];

    for (const role of userPermissions) {
      for (const perm of role.permissions) {
        if (perm.item === componentPath) {
          return perm.selected;
        }
      }
    }
    return [];
  };

  const subPermissions = getSubPermissions("deliveryChallan");


  // Boolean states for permissions
  const [booladd, setbooladd] = useState(false);
  const [boolview, setboolview] = useState(false);

  useEffect(() => {
    const hasAddPermission = subPermissions.includes("create");
    const hasViewPermission = subPermissions.includes("view");

    if (booladd !== hasAddPermission) {
      setbooladd(hasAddPermission);
    }
    if (boolview !== hasViewPermission) {
      setboolview(hasViewPermission);
    }
  }, [subPermissions, booladd, boolview]);


  useEffect(() => {
    setshowLoader(true)
    let objectAPI = {
      "userId": userId,
      "userEmail": userEmail,
      "userTypeId": userTypeId,
      currentPage: page,
      onlyDC: true,
      byDocName: ['Delivery Challan'],
      ...filter
    }
    for (let index = 0; index < Object.keys(filterData || {}).length; index++) {
      let filterName = Object.keys(filterData)[index]
      const element = filterData[filterName];
      if (element.isFilterActive) {
        if (element.type === "checkbox") {
          objectAPI[element.accordianId] = []
          element["data"].forEach((i) => {
            if (i.isChecked) {
              objectAPI[element.accordianId].push((element.accordianId === "invoiceTypee") ? i[element["labelName"]] : `'${i[element["labelName"]]}'`)
            }
          })
        }
      }
    }
    // call('POST', 'getEdocs', objectAPI).then(async (result) => {
    call('POST', 'getSalesPurchaseQuotation', objectAPI).then(async (result) => {
      console.log('running getEdocs api-->', result);
      setDbData(result.data);
      setCount(result.data.length);
      setshowLoader(false)
    }).catch((e) => {
      // console.log('error in getEdocs', e);
      setshowLoader(false)
    });
  }, [refresh, page, filterData])
  const handleRedirect = (item) => {
    setEditDocument({ ...item.details, docType: item.docType, idFromDB: item.id, sellerId: item.sellerId, buyerId: item.buyerId, itemStatus: item.status, transaction_timeline: item.transaction_timeline });
    setClickedDocForTD(item)
    setShowTransactionDetails(true)
  };
  const handleCreateNew = () => {


    if (booladd || userPermissions == null) {
      toggleTemplateSelectionForm({ show: true, data: { type: "" } })
    } else {
      console.log("toast info disp--?????")
      toastDisplay("Permission Denied, Ask Owner for permission.", "info");
    }
  }

  return (
    <>
      {showLoader && (<div className="loading-overlay"><span><img className="" src="assets/images/loader.gif" alt="description" /></span></div>)}
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />

      <DocumentViewer show={viewDoc.show} value={viewDoc.doc} dismiss={() => toggleViewDoc({ show: false, doc: {} })} />
      <DocumentDownloader show={dowdDoc.show} value={dowdDoc.doc} dismiss={() => toggleDowdDoc({ show: false, doc: {} })} />

      <div className="container-fluid">
        <div className="row">
          <SideBarV2 state="deliveryChallan" userTokenDetails={userTokenDetails} />
          <main role="main" className={" ml-sm-auto col-lg-10 " + (navToggleState.status ? " expanded-right" : "")} id="app-main-div">
            <HeaderV2
              title={templateSelectionForm.show ? `E Docs > Delivery Challan > Create Create Delivery Challan(${templateSelectionForm.data?.type})` : `E Docs > Delivery Challan`}
              userTokenDetails={userTokenDetails} />
            {showTransactionDetails || showTimeline ?
              <>
                {showTransactionDetails && !showTimeline && <TransactionDetails setShowTimeline={setShowTimeline} showTimelineBtn={false} handleGoBack={setShowTransactionDetails} editDocument={editDocument} />}
                {
                  showTimeline && !showTransactionDetails && <TransactionTimeline userId={userId} setShowTimeline={setShowTimeline} handleGoBack={setShowTimeline} editDocument={editDocument} />
                }
              </>
              :
              <div>
                {templateSelectionForm.data.type ? <>
                  {templateSelectionForm.data.type === 'Delivery Challan' ? <>
                    <a
                      onClick={() => {
                        toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                        setSelectedInvoiceData({})
                      }}
                      className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                    <ChallanOrder selectedInvoiceData={selectedInvoiceData} userTokenDetails={userTokenDetails} setrefresh={setRefresh} handleGoBack={() => {
                      toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                      setSelectedInvoiceData({})
                    }} /> </> :
                    null}
                </> : <>
                  {templateSelectionForm.show ?
                    <>
                      <a
                        onClick={() => {
                          toggleTemplateSelectionForm({ show: false, data: { type: "" } })
                          setSelectedInvoiceData({})
                        }}
                        className="cursor"><img className="cursor mb-3" src={"assets/images/back.png"} alt="Back" height={15} width={15} /></a>
                      <div className='card mt-1 px-2' >
                        <label className='font-size-16 font-wt-600 w-100 text-center pt-4' >Select Template</label>
                        <div className='d-flex gap-4 p-4 flex-wrap' >
                          {dcTemplateTypes.map((i, j) => {
                            return (
                              <div
                                onClick={() => {
                                  toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                                }}
                                className='w-20 cursor' >
                                <img className='contractTemplateImg2' style={{}} src={i.src} />
                                <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                              </div>
                            )
                          })}
                        </div>
                        {recentTemplates?.length ? <>
                          <hr />
                          <label className='font-size-16 font-wt-600 w-100 pt-4 px-4 m-0' >Recently Used Templates</label>
                          <div className='d-flex gap-4 p-4 flex-wrap' >
                            {dcTemplateTypes.map((i, j) => {
                              let isRecentlyUsed = recentTemplates.find(m => {
                                if (m.template === i.name) {
                                  return true
                                }
                              })?.["template"]
                              if (isRecentlyUsed) {
                                return (
                                  <div
                                    onClick={() => {
                                      toggleTemplateSelectionForm({ show: true, data: { type: i.name } })
                                    }}
                                    className='w-20 cursor' >
                                    <img className='contractTemplateImg2' style={{}} src={i.src} />
                                    <label className='font-size-14 font-wt-600 p-2 cursor' >{i.name}</label>
                                  </div>
                                )
                              }
                            })}
                          </div></> : null}
                      </div>
                    </> : <>
                      <div className='filter-div position-relative'>
                        <Filter isAdditionalButton
                          filterData={filterData} setFilterData={setFilterData} showFilterBtn={true}
                          showResultPerPage={true} count={count} filter={filter} setFilter={setFilter} refresh={refresh} setRefresh={setRefresh}>
                          <div className="d-flex gap-4">
                            <button className={`new-btn  py-2 px-2 text-white cursor`}
                              // onClick={() => {
                              //   toggleTemplateSelectionForm({ show: true, data: { type: "" } })
                              // }}
                              onClick={handleCreateNew}
                            >Create New</button>
                          </div>
                        </Filter>
                      </div>
                      <div>
                        <NewTablev2 columns={[
                          { subColumns: "Document No" },
                          { subColumns: "Document Type" },
                          { subColumns: "Creation Date", },
                          { subColumns: "Deal Owner", },
                          { subColumns: "Company" },
                          { subColumns: "Total Amount", },
                          { subColumns: "Delivery Date", },
                          { subColumns: "Origin Address", subColumnStyle: { width: '14%' } },
                          { subColumns: "Destination Address", subColumnStyle: { width: '13%' } },
                          { subColumns: "Actions", subColumnStyle: { width: '5%' } }
                        ]}>
                          {dbData.map((item, j) => {
                            return (
                              <tr>
                                {/* onClick={() => handleRedirect(item) }*/}
                                <td style={{ width: "10%" }}><label className='font-size-13 qwertyu font-wt-400 text-break' >{item.docId}  </label></td>
                                <td><label className='font-size-13 font-wt-400 text-break' >{item.docType || "-"}</label></td>
                                <td><label className='font-size-13 font-wt-400 text-break' >{moment(item.createdAt).format('YYYY-MM-DD')}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.clientContactName || "-"}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.shipToCompanyName || "-"}</label></td>
                                {/* <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.enquiryNo || "-"}</label></td> */}
                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details?.invTotalAmount ? item.details?.invTotalAmount + " " + item.details?.invCurrency : "NA"}</label></td>

                                <td><label className='font-size-13 font-wt-400 text-break' >{item.details.deliveryDate ? moment(item.details.deliveryDate).format('YYYY-MM-DD') : "-"}</label></td>
                                <td><label className="font-size-13 font-wt-400" >{item.details?.clientAddress || "-"}</label></td>
                                <td><label className="font-size-13 font-wt-400" >{item.details?.finalDestination || "-"}</label></td>
                                <td
                                  // style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
                                  className='position-relative'>
                                  <i className="fa fa-ellipsis-v cursor mt-2 ml-2"
                                    onClick={() => setAction({ show: true, index: j })}
                                    aria-hidden="true"></i>
                                  {action.show && action.index === j ? (
                                    <Action
                                      id={j}
                                      onDismiss={() => setAction({ show: false, index: j })}
                                      options={[
                                        {
                                          name: "View", onClick: async () => {
                                            handleRedirect(item)
                                          }
                                        },
                                        // {
                                        //   name: "Edit", onClick: async () => {
                                        //     let docDetails = await getDocDetails(item.docId)
                                        //     if (Object.keys(docDetails)?.length) {
                                        //       setSelectedInvoiceData(item)
                                        //       toggleTemplateSelectionForm({ show: true, data: { type: item.template } })
                                        //     }
                                        //     else {
                                        //       toastDisplay("No document found", "error")
                                        //     }
                                        //   }
                                        // },
                                        // {
                                        //   name: "Download", onClick: async () => {
                                        //     let docDetails = await getDocDetails(item.docId)
                                        //     if (Object.keys(docDetails)?.length) {
                                        //       toggleDowdDoc({ show: true, doc: docDetails })
                                        //     }
                                        //     else {
                                        //       toastDisplay("No document found", "error")
                                        //     }
                                        //   }
                                        // }
                                      ]} />
                                  ) : null}
                                </td>
                              </tr>
                            )
                          })}
                        </NewTablev2>
                        <Pagination perPage={filter.resultPerPage || 10} page={page} totalCount={count} onPageChange={(p) => setPage(p)} />
                      </div>
                    </>}
                </>}
              </div>
            }
          </main>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    navToggleState: state.navToggleState
  }
}

export default connect(
  mapStateToProps,
  null
)(DeliveryChallan)